import { Button, Form, Input, InputRef, Modal, Select } from 'antd';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useMasterUserRoleElementsStore } from '../../../../stores/masterData.store';
import { IMasterRoleDDState } from '../../../../models/interfaces';

type Tuser = {
  first_name: string;
  last_name: string;
  email: string;
  role: number;
};

interface Role {
  value: number;
  label: string;
  order_id: number;
}
interface Iuser {
  uid: number;
  email: string;
  first_name: string;
  last_name: string;
  account_id: number;
  role_id: number;
}

const UserModal: FC<{
  isOpen: boolean;
  close: React.Dispatch<React.SetStateAction<boolean>>;
  setUser: React.Dispatch<React.SetStateAction<Iuser | null>>;
  handleMount: React.Dispatch<React.SetStateAction<boolean>>;
  account_id: number;
  value: any;
  destroy: () => void;
  setVal: React.Dispatch<React.SetStateAction<any>>;
}> = ({
  isOpen,
  close,
  setUser,
  handleMount,
  account_id,
  value,
  setVal,
  destroy,
}) => {
  const firstNameRef = useRef<InputRef>(null),
    { roleDDElements } = useMasterUserRoleElementsStore(
      (state: IMasterRoleDDState) => state
    );
  const getRole = (value: string) => {
    let data = roleDDElements.find((item) => item.label === value);
    return data ? data.order_id : 1;
  };

  const [userInfo, setUserInfo] = useState<Tuser>({
      first_name: value?.name?.length ? value.name.split(' ')[0] : '',
      last_name: value?.name?.length ? value.name.split(' ')[1] : '',
      email: value?.email?.length ? value.email : '',
      role: value?.role_name ? getRole(value?.role_name) : 1,
    }),
    [form] = Form.useForm(),
    values = Form.useWatch([], form);

  useEffect(() => {
    setTimeout(() => {
      firstNameRef.current?.focus({
        cursor: 'end',
      });
    }, 300);
  }, []);

  const handleCancel = () => {
    setVal(null);
    setUserInfo({
      first_name: '',
      last_name: '',
      email: '',
      role: 1,
    });
    close(false);
  };

  const handleSave = () => {
    destroy();
    setUser({
      uid: value ? value.user_id : 0,
      email: values.email,
      first_name: values.first_name,
      last_name: values.last_name,
      account_id: account_id,
      role_id: values.role,
    });
    setVal(null);
    handleMount(true);
    close(false);
  };

  function removeRole(roles: any, valueToRemove: number): Role[] {
    return roles.filter((role: any) => role.value !== valueToRemove);
  }

  return (
    <Modal
      centered
      open={isOpen}
      onCancel={handleCancel}
      wrapClassName="genericAddingRecordModal"
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          disabled={!values?.email || !values?.first_name || !values?.last_name}
          key="submit"
          onClick={handleSave}
        >
          {value ? 'Save' : 'Create'}
        </Button>,
      ]}
      className="commonModal"
      width="770px"
    >
      <h3>{value ? 'Update User' : 'New User'}</h3>
      <Form
        form={form}
        labelCol={{ span: 24 }}
        autoComplete="off"
        requiredMark={false}
        initialValues={userInfo}
      >
        <Form.Item
          label="First Name"
          name="first_name"
          validateDebounce={100}
          validateFirst
          rules={[
            {
              required: true,
              message: 'First name is required',
            },
            {
              max: 50,
              message: 'First name cannot exceed 50 characters',
            },
          ]}
        >
          <Input ref={firstNameRef} placeholder="First Name" />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name="last_name"
          validateDebounce={100}
          validateFirst
          rules={[
            {
              required: true,
              message: 'Last name is required',
            },
            {
              max: 50,
              message: 'Last name cannot exceed 50 characters',
            },
          ]}
        >
          <Input placeholder="Last Name" />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          validateDebounce={100}
          validateFirst
          rules={[
            {
              required: true,
              message: 'Email name is required',
            },
            {
              type: 'email',
              message: 'Enter a valid email',
            },
          ]}
        >
          <Input placeholder="Email" disabled={userInfo.email.length > 0} />
        </Form.Item>
        <Form.Item label="Role" name="role" validateDebounce={100}>
          <Select
            options={removeRole(roleDDElements, 4)}
            value={userInfo.role}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UserModal;
