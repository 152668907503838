import { IEachDropdownElementsType } from '../models/interfaces';

export const allSelectionOnlyElement: IEachDropdownElementsType[] = [
  { label: 'Select All', value: -1, order_id: 1 },
];

export const itemTypeElement: IEachDropdownElementsType[] = [
  { label: 'Account', value: 'account', order_id: 1 },
  { label: 'Audience', value: 'audience', order_id: 2 },
  { label: 'Activation', value: 'activation', order_id: 3 },
  { label: 'Pixel', value: 'pixel', order_id: 4 },
  { label: 'Research', value: 'research', order_id: 5 },
];
