/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import Input from 'antd/es/input';
import { Button, Col, Row } from 'antd';
import { CommonElementLabel, PageHeader, Single } from '../../sharedComponent/';
import { LeftPanel } from '../';
import {
  IAudienceSetUpState,
  IFeatureSetDataCheckState,
  ILoaderState,
  ISaveAudienceActivationState,
  IUserInformationState,
} from '../../../models/interfaces';
import { useParams } from 'react-router-dom';

import { CheckCircleTwoTone, CopyOutlined } from '@ant-design/icons';
import {
  useFeatureSetDataCheckStore,
  useSaveAudienceActivationStore,
} from '../../../stores/saveAndUpdateData.store';
import { useNotificationMessage, usePostApiInterceptor, useRedirection } from '../../../hooks';
import { RoutesEnum } from '../../../models/enums/apiRoutes';
import { useLoaderStore, useUserInformationStore } from '../../../stores';
import { useAudienceSetUp } from '../../../stores/audienceSets.store';
import {
  ActivationTable,
  AudienceDestinationModal,
  AudienceEstimateTable,
} from './AudienceActivationComponent';
import { RbacSettings } from '../../../models/enums/rbacSetting';

type PixelBodyType = {
  end_date: string | null;
  start_date: string | null;
  limit: number;
  off_set: number;
  order_column: string | null;
  order_direction: string | null;
};

type TestimateBody = {
  estimated_id : number
  job_id: string;
  processed_ids: string;
  audience_id: number;
  size: number;
  score: number;
  population: number;
  audience_name: string;
  audience_type_id: number;
};

type TactivatesBody = {
  audience_id: number;
  estimate_id: number;
  partner_id: number;
  size: number | null;
  status_id: number;
};

const baseListingParam = {
  sortOrder: 'DESC',
  sortColumn: 'title',
  pageNumber: 1,
  rowsPerPage: 15,
  dateRange: null,
};

const AudienceActivationPage: FC = () => {
  document.title = 'Audience Activation - BranchLab';

  const { audId, actId } = useParams();

  const [selectedAudienceSize, setSelectedAudienceSize] = useState<string>(''),
    [selectedAudName, setSelectedAudName] = useState<string>(''),
    [selectedAudienceType, setSelectedAudienceType] = useState<number | null>(
      null
    ),
    [activateModal, setActivateModal] = useState(false),
    [estimateData, setEstimateData] = useState<any>([]),
    [isSaving, setIsSaving] = useState<boolean>(false),
    [showAudienceEstimate, setShowAudienceEstimate] = useState<boolean>(false);

  const [body, setBody] = useState<{
    audience_id: string;
    activation_id?: string;
  } | null>(null),
    [isMounted, setIsMounted] = useState<boolean>(false),
    [isMountedPixel, setIsMountedPixel] = useState<boolean>(false),
    [isEstimateMounted, setIsEstimateMounted] = useState<boolean>(false),
    [estimateSizeBody, setEstimateSizeBody] = useState<TestimateBody | null>(
      null
    ),
    [disableEstimate, setDisableEstimate] = useState<boolean>(false),
    [isActivationMounted, setIsActivationMounted] = useState<boolean>(false),
    [isError, setIsError] = useState<boolean>(false),
    [isCopied, setIsCopied] = useState<boolean>(false),
    [pixel_Id, setPixelId] = useState<string>('');

  const [saveBody, setSaveBody] = useState<string | null>(null),
    [isMountedSave, setIsMountedSave] = useState<boolean>(false),
    [pixelBody, setPixelBody] = useState<PixelBodyType | null>(null),
    [activationBody, setActivationBody] = useState<TactivatesBody | null>(null),
    [pixelFieldData, setPixelFieldData] = useState<any>(''),
    [activatedData, setActivatedData] = useState<any>([]),
    [pixelFieldId, setPixelFieldId] = useState<null | number>(null),
    [isCopiedImage, setIsCopiedImage] = useState<boolean>(false),
    [pixelData, setPixelData] = useState<any>([]),
    [partner, setPartner] = useState<string>(''),
    [estimated_Size, setEstimate_Size] = useState<string>(''),
    [messageObj, setMessageObj] = useState<any>({ isShowing: false });

  const {
    ifAllMandatoryFieldsPopulated,
    isSaveForActivationInitiated,
    setIsSaveForActivationInitiated,
    updateIfAllMandatoryFieldsPopulated,
    updatedAudienceActivationData,
    setUpdatedAudienceActivationData,
  } = useSaveAudienceActivationStore(
    (state: ISaveAudienceActivationState) => state
  ),
    { privilegeSetDDElements } = useUserInformationStore(
      (state: IUserInformationState) => state
    ),
    { updateLoaderState, setUpdateLoaderState, setShowPageLoaderState } =
      useLoaderStore((state: ILoaderState) => state),
    { ifUpdated } = useFeatureSetDataCheckStore(
      (state: IFeatureSetDataCheckState) => state
    ),
    { setAudiencePageNumber } = useAudienceSetUp(
      (state: IAudienceSetUpState) => state
    );

  const { context, destroyMessage } = useNotificationMessage(messageObj);
  const { redirectAudienceActivations } = useRedirection();

  const updateErrorMessage = (type: string, messageContent: string) => {
    setMessageObj({
      key: 'save',
      isShowing: true,
      type,
      messageContent,
      duration: 5,
    });
  };

  const generateEstimate = () => {
    DestroyMessage()
    if (selectedAudienceType === 1) {
      setEstimateSizeBody({
        audience_id: parseInt(audId || '0'),
        estimated_id: estimateData.length ? estimateData[0].estimate_id : 0,
        job_id: '1c0873fba008474badacc545e777961e',
        processed_ids: '1c0873fb-a008-474b-adac-c545e777961e',
        audience_type_id: 1,
        size: 200,
        score: 0,
        population: 500,
        audience_name: selectedAudName,
      });
    } else {
      setEstimateSizeBody({
        audience_id: parseInt(audId || '0'),
        estimated_id: estimateData.length ? estimateData[0].estimate_id : 0,
        job_id: '1c0873fba008474badacc545e777961e',
        processed_ids: '1c0873fb-a008-474b-adac-c545e777961e',
        audience_type_id: 2,
        size: 280000,
        score: 0,
        population: 0,
        audience_name: selectedAudName,
      });
    }
    setDisableEstimate(true)
    setIsEstimateMounted(true);
  };

  // useEffect(() => {
  //   return () => {
  //     setAudiencePageNumber(1);
  //   };
  // }, []);

  useEffect(() => {
    setAudiencePageNumber(3);
  }, []);

  useEffect(() => {
    return () => {
      setPixelFieldData([
        {
          label: null,
          value: null,
        },
      ]);
      setUpdatedAudienceActivationData(null);
      setPixelFieldId(null);
      setPixelData([]);
    };
  }, []);

  useEffect(() => {
    setPixelBody({
      end_date: null,
      start_date: null,
      limit: baseListingParam.rowsPerPage,
      off_set: (baseListingParam.pageNumber - 1) * baseListingParam.rowsPerPage,
      order_column: baseListingParam.sortColumn,
      order_direction: baseListingParam.sortOrder,
    });
    setIsMountedPixel(true);
  }, []);

  usePostApiInterceptor(
    isMountedPixel,
    pixelBody,
    RoutesEnum.PIXEL_LISTING_DATA,
    (data: any, error: any) => {
      if (error || !data || (data && !data.rows)) {
        setIsError(true);
        setUpdateLoaderState(false);
        return;
      }
      let pixelData = data.rows;
      pixelData.push({
        pixel_id: data.rows.length + 1,
        pixel_name: 'None (Default)',
        title: 'None (Default)',
      });
      setPixelData(pixelData);
      setIsMountedPixel(false);
      setIsMounted(true);
    }
  );

  usePostApiInterceptor(
    isEstimateMounted,
    estimateSizeBody,
    RoutesEnum.SAVE_ESTIMATE,
    (data: any, error: any) => {
      if (error || !data) {
        setDisableEstimate(false)
        updateErrorMessage('error', 'Error occurred while generating estimate');
        return;
      }
      setDisableEstimate(false)
      updateErrorMessage('success', 'Generating estimate successfully');
      setEstimateSizeBody(null);
      if (audId) {
        setBody(
          !actId
            ? { audience_id: audId }
            : { audience_id: audId, activation_id: actId }
        );
        setIsMounted(true);
      }
      setIsEstimateMounted(false);
    }
  );

  usePostApiInterceptor(
    isActivationMounted,
    activationBody,
    RoutesEnum.SAVE_ACTIVATES,
    (data: any, error: any) => {
      if (error || !data) {
        setIsError(true);
        updateErrorMessage('error', 'Error occurred while saving activation');
        return;
      }
      updateErrorMessage('success', 'Activation successful');
      setActivationBody(null);
      if (audId) {
        setBody(
          !actId
            ? { audience_id: audId }
            : { audience_id: audId, activation_id: actId }
        );
        setIsMounted(true);
      }
      setIsActivationMounted(false);
    }
  );

  usePostApiInterceptor(
    isMounted,
    body,
    RoutesEnum.GET_ACTIVATION,
    (data: any, error: any) => {
      setIsMounted(false);
      if (error || !data) {
        setIsError(true);
        setUpdateLoaderState(false);
        return;
      }

      if (data.estimated_info) {
        setShowAudienceEstimate(true);
        setEstimateData(data.estimated_info);
      }
      setSelectedAudName(data.audience_name);
      setSelectedAudienceType(data.audience_type);
      setPixelId(data?.pixel_info?.length ? data.pixel_info[0].pixel_id : '');
      setActivatedData(data.activation_list ?? []);
      setUpdateLoaderState(false);
    }
  );

  const handleActive = () => {
    setActivationBody({
      audience_id: estimateData[0].audience_id,
      estimate_id: estimateData[0].estimate_id,
      partner_id: parseInt(partner),
      size: estimated_Size.length
        ? parseInt(estimated_Size)
        : null,
      status_id: 1,
    });
    setIsActivationMounted(true);
    setActivateModal(false);
    setEstimate_Size('');
    setPartner('');
  };

  useEffect(() => {
    if (pixel_Id) {
      const filteredPixel = pixelData?.filter(
        (pix: any) => pix.pixel_id === pixel_Id
      );
      let temp = {
        label: filteredPixel[0]?.title ?? '',
        value: filteredPixel[0]?.title ?? '',
      };
      setPixelFieldData(temp);
      setPixelFieldId(filteredPixel[0]?.pixel_code);
    }
  }, [pixel_Id]);

  usePostApiInterceptor(
    isMountedSave,
    saveBody,
    RoutesEnum.SAVE_ACTIVATION,
    (data: any, error: any) => {
      setIsMountedSave(false);
      setIsSaving(false);
      setSaveBody(null);
      setShowPageLoaderState(false);
      updateErrorMessage('success', 'Activation successful');
      if (error || !data) {
        updateErrorMessage('error', 'Error occurred while saving activation');
        return;
      }
    }
  );

  const initiatedSave = () => {
    destroyMessage('save')
    setMessageObj({ isShowing: false })
    setShowPageLoaderState(true);
    const savingObj: any = {
      audience_id: audId,
      pixel_id: pixelFieldData === 'None (Default)' ? null : pixel_Id,
    };
    setIsSaving(true);
    setSaveBody(savingObj);
    setIsMountedSave(true);
  };

  const handleCancel = () => {
    redirectAudienceActivations();
  };

  useEffect(() => {
    if (isSaveForActivationInitiated) {
      const convertedToNumber = Number(selectedAudienceSize);
      const x =
        selectedAudienceSize &&
          !isNaN(convertedToNumber) &&
          convertedToNumber >= 50000 &&
          convertedToNumber <= 100000000
          ? 2
          : 1;

      updateIfAllMandatoryFieldsPopulated({
        audience_size: x,
      });
      if (x === 1) {
        setIsSaveForActivationInitiated(false);
      }
    }
  }, [isSaveForActivationInitiated]);

  useEffect(() => {
    const ifAnyError = Object.values(ifAllMandatoryFieldsPopulated).includes(1);
    if (!ifAnyError && isSaveForActivationInitiated) {
      initiatedSave();
    } else {
      setIsSaveForActivationInitiated(false);
    }
  }, [ifAllMandatoryFieldsPopulated]);

  useEffect(() => {
    if (audId) {
      isError && setIsError(false);
      setUpdateLoaderState(true);
      setBody(
        !actId
          ? { audience_id: audId }
          : { audience_id: audId, activation_id: actId }
      );
      // setIsMounted(true);
    }
  }, [audId, actId]);

  useEffect(() => {
    if (ifUpdated) {
      setSelectedAudienceSize(
        String(updatedAudienceActivationData?.audience_size ?? '')
      );
    }
  }, [ifUpdated]);

  const updatedRows = pixelData?.map((row: any) => {
    const { title, ...rest } = row;
    return {
      ...rest,
      label: title,
      value: `${title}`,
    };
  });

  useEffect(() => {
    if (isCopied || isCopiedImage) {
      setTimeout(() => {
        setIsCopied(false);
        setIsCopiedImage(false);
      }, 1000);
    }
  }, [isCopied, isCopiedImage]);

  const onChange = (value: any) => {
    if (value === 'None (Default)') {
      setPixelFieldData(`${value}`);
    } else {
      const filteredPixel = updatedRows.filter(
        (pix: any) => pix.value === value
      );
      setPixelFieldData(`${value}`);
      setPixelFieldId(filteredPixel[0]?.pixel_code);
      setPixelId(filteredPixel[0]?.pixel_id);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_PIXEL_URL}${pixelFieldId}`
    );
    setIsCopied(!isCopied);
  };

  const copyImage = () => {
    navigator.clipboard.writeText(
      `<img src=${process.env.REACT_APP_PIXEL_URL}${pixelFieldId} height='1px' width='1px' />`
    );
    setIsCopiedImage(!isCopiedImage);
  };

  const handleClear = () => {
    setPixelFieldData([
      {
        label: null,
        value: null,
      },
    ]);
    setUpdatedAudienceActivationData(null);
    setPixelFieldId(null);
  };

  const DestroyMessage = () => {
    destroyMessage('save')
    setMessageObj({ isShowing: false })
  }

  return (
    <div className="audienceSetupPage audienceActivation">
      <LeftPanel defaultSelectedKeys={['3']} />
      {context}
      {!updateLoaderState && (
        <>
          <div className="audienceCommonWrap">
            <PageHeader
              title={
                <>
                  Update:{' '}
                  <span className="lessOpacityText">{selectedAudName}</span>
                </>
              }
              componentSpecificClass="audienceActivationPageHeader"
            />

            <div className="audienceActivationTable mb-20">
              <AudienceEstimateTable
                selectedAudienceType={selectedAudienceType}
                isLoading={false}
                isActive={showAudienceEstimate}
                data={estimateData}
                disable={disableEstimate}
                generateEstimate={generateEstimate}
              />
              <ActivationTable
                selectedAudienceType={selectedAudienceType}
                isLoading={false}
                isActive={showAudienceEstimate}
                activateModal={setActivateModal}
                data={activatedData}
                destroy={DestroyMessage}
              />

              <AudienceDestinationModal
                modalToOpen={activateModal}
                setModalToOpen={setActivateModal}
                estimated_Size={estimated_Size}
                setEstimated_Size={setEstimate_Size}
                setPartner={setPartner}
                partner={partner}
                selectedAudienceType={selectedAudienceType}
                activate={handleActive}
                lastEstimate={estimateData.length ? estimateData[0].processed_at : null}
              />
              <h2 className="mt-20 measurementHeading">Measurement</h2>
              <Row>
                <Col span={12} style={{ marginBottom: '20px' }}>
                  <div className="informationText">
                    <CommonElementLabel label="Attach a measurement pixel to this activation." />
                  </div>
                  <Single
                    isDisabled={
                      !privilegeSetDDElements.includes(
                        RbacSettings.ACTIVATION_ADD
                      )
                    }
                    options={updatedRows ?? []}
                    placeholder="Choose a Pixel"
                    onClear={handleClear}
                    onChange={onChange}
                    allowClear={false}
                    props={{
                      value:
                        pixelFieldData.length === 0
                          ? "None (Default)"
                          : pixelFieldData,
                    }}
                    componentSpecificClasses={['measurementPixelDropdown']}
                  />
                </Col>
              </Row>
              {pixelFieldData.length !== 0 && pixelFieldData !== 'None (Default)' && (
                <div className="measuremntPixelSec pixelUrlSec">
                  <div className="informationText">
                    <CommonElementLabel label="Change the text above the tag URLs to this: “Copy and provide either the Raw URL or the Image Tag option below to the website owner or administrator. Only one is needed." />
                  </div>

                  <div>
                    <div className="mb-20">
                      <CommonElementLabel label="Raw URL" />
                      <Input
                        placeholder="Raw URL"
                        readOnly
                        value={`${process.env.REACT_APP_PIXEL_URL}${pixelFieldId}`}
                        suffix={
                          <div onClick={handleCopy}>
                            {isCopied ? (
                              <CheckCircleTwoTone twoToneColor="#52c41a" />
                            ) : (
                              <CopyOutlined
                                style={{ color: 'rgba(0,0,0,.45)' }}
                              />
                            )}
                          </div>
                        }
                      />
                    </div>
                    <div className="mb-20">
                      <CommonElementLabel label="Image" />
                      <Input
                        placeholder="Image"
                        readOnly
                        value={`<img src=${process.env.REACT_APP_PIXEL_URL}${pixelFieldId} height='1px' width='1px' />`}
                        suffix={
                          <div onClick={copyImage}>
                            {isCopiedImage ? (
                              <CheckCircleTwoTone twoToneColor="#52c41a" />
                            ) : (
                              <CopyOutlined
                                style={{ color: 'rgba(0,0,0,.45)' }}
                              />
                            )}
                          </div>
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="newFeatureSetBtn">
            <Button className="noBGBtn" size="large" onClick={handleCancel}>
              Cancel
            </Button>
            {privilegeSetDDElements.includes(RbacSettings.ACTIVATION_ADD) && (
              <Button
                size="large"
                // disabled={!estimateData.length || !activatedData.length}
                loading={isSaving}
                className="primaryBtn"
                onClick={initiatedSave}
              >
                Save
              </Button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default AudienceActivationPage;
