import { Button, Table, TableColumnsType } from 'antd';
import dayjs from 'dayjs';
import React, { FC } from 'react';
import CustomEmptyTableComponent from '../../../sharedComponent/Empty/CustomEmptyTableComponent';
import { useUserInformationStore } from '../../../../stores';
import { IUserInformationState } from '../../../../models/interfaces';
import { RbacSettings } from '../../../../models/enums/rbacSetting';

interface IDtcDataType {
  audience_filename: string;
  partner: string;
  date: Date;
  audience_size: number;
  passing_score: number;
  status: boolean;
}

interface IHcpDataType {
  audience_filename: string;
  partner: string;
  date: Date;
  audience_size: number;
  status: boolean;
}

const ActivationTable: FC<{
  selectedAudienceType: number | null;
  isLoading: boolean;
  data: [];
  isActive: boolean;
  destroy : () => void;
  activateModal: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ isLoading, data, selectedAudienceType, activateModal, isActive, destroy }) => {
  const { privilegeSetDDElements } = useUserInformationStore(
    (state: IUserInformationState) => state
  );

  function formatEstimatedSizes(size: number) {
    return size.toLocaleString();
  }

  const getStatusColumnValueJsx = (value: string) => {
    switch (value) {
      case 'Processing':
        return (
          <span
            className={`statusChip 1`}
            style={{ backgroundColor: '#FFF598', color: '#645A00' }}
          >
            {value}
          </span>
        );
    }
  };

  const dtcColumns: TableColumnsType<IDtcDataType> = [
    {
      title: 'Audience Filename',
      dataIndex: 'audience_name',
      key: 'Audience_name',
      width: '180px',
      showSorterTooltip: false,
    },
    {
      title: 'Partner',
      dataIndex: 'partner',
      key: 'partner',
      width: '130px',
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Date',
      dataIndex: 'created_on',
      key: 'date',
      render: (text) => dayjs(text).format('M/D/YYYY'),
      width: '130px',
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Audience Size',
      dataIndex: 'audience_size',
      key: 'Audience_size',
      width: '130px',
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Passing Score',
      dataIndex: 'score',
      key: 'passing_score',
      width: '130px',
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '130px',
      render: (text) => getStatusColumnValueJsx(text),
      ellipsis: true,
      showSorterTooltip: false,
    },
  ];

  const hcpColumns: TableColumnsType<IHcpDataType> = [
    {
      title: 'Audience Filename',
      dataIndex: 'audience_name',
      key: 'Audience_name',
      width: '180px',
      showSorterTooltip: false,
    },
    {
      title: 'Partner',
      dataIndex: 'partner',
      key: 'partner',
      width: '130px',
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Date',
      dataIndex: 'created_on',
      render: (text) => dayjs(text).format('M/D/YYYY'),
      key: 'date',
      width: '130px',
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Audience Size',
      dataIndex: 'audience_size',
      key: 'Audience_size',
      width: '130px',
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => getStatusColumnValueJsx(text),
      width: '130px',
      ellipsis: true,
      showSorterTooltip: false,
    },
  ];

  function manipulateDTCArray(dataArray: IDtcDataType[]) {
    return dataArray?.map((item: any) => {
      return {
        ...item,
        audience_size: formatEstimatedSizes(item.audience_size),
        score: formatEstimatedSizes(item.score),
      };
    });
  }

  function manipulateHCPArray(dataArray: IHcpDataType[]) {
    return dataArray?.map((item: any) => {
      return {
        ...item,
        audience_size: formatEstimatedSizes(item.audience_size),
      };
    });
  }

  return (
    <div className="activationTableWrap">
      <div className="sectionHeader audienceEstimate">
        <h2>Activations</h2>
        {privilegeSetDDElements.includes(RbacSettings.ACTIVATION_ADD) && (
          <Button
            className="primaryBtn"
            disabled={!isActive}
            onClick={() => {
              activateModal(true)
              destroy()
            }}
            size="large"
          >
            New Activation
          </Button>
        )}
      </div>
      {selectedAudienceType === 1 && (
        <Table
          columns={dtcColumns}
          dataSource={manipulateDTCArray(data) ?? []}
          className={`antTableElement audienceListTable${
            isLoading ? ' hideNoDataLabel' : ''
          }`}
          locale={{
            emptyText: <CustomEmptyTableComponent isEstimate={false} />,
          }}
          pagination={false}
          loading={isLoading}
          sortDirections={['ascend', 'descend', 'ascend']}
          scroll={data.length > 0 ? { x: 'max-content' } : undefined}
        />
      )}
      {selectedAudienceType === 2 && (
        <Table
          columns={hcpColumns}
          dataSource={manipulateHCPArray(data) ?? []}
          className={`antTableElement audienceListTable${
            isLoading ? ' hideNoDataLabel' : ''
          }`}
          pagination={false}
          locale={{
            emptyText: <CustomEmptyTableComponent isEstimate={false} />,
          }}
          loading={isLoading}
          sortDirections={['ascend', 'descend', 'ascend']}
          scroll={data.length > 0 ? { x: 'max-content' } : undefined}
        />
      )}
    </div>
  );
};

export default ActivationTable;
