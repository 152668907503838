import { Dayjs } from 'dayjs';
import CommonElementLabel from './CommonElementLabel/CommonElementLabel';
import CommonElementWithoutLabel from './CommonElementWithoutLabel/CommonElementWithoutLabel';

import {
  Single,
  Multi,
  MultiSingle,
  Tree,
  DateDropdown,
  TreeMulti,
  DebounceDropdown,
  DebounceDropdownSingleSelect,
  MultiCustomTaxonomy,
} from './Dropdowns';
import Breadcrumb from './BreadCrumb/BreadCrumb';
import DateRangePicker from './DateRangePicker/DateRangePicker';
import { AgeSlider } from './Slider';
import { UploadUserFiles } from './UploadUserFiles';
import { CheckboxList, MultipleSelectorCheckbox } from './CheckboxList';
import { InputField } from './InputField';
import PageHeader from './PageHeader/PageHeader';
import GlobalFilterModal from './GlobalFilterModal/GlobalFilterModal';
import { StatusDropdownMulti } from './StatusDropdown';
import {
  DeleteFeatureSet,
  DeleteTargetingElement,
  DeleteAddResearchElement,
} from './Delete';
import ModalComponent from './Modal/ModalComponent';

export {
  Single,
  Multi,
  MultiSingle,
  Tree,
  DateDropdown,
  DateRangePicker,
  TreeMulti,
  AgeSlider,
  UploadUserFiles,
  CommonElementLabel,
  CheckboxList,
  MultipleSelectorCheckbox,
  InputField,
  PageHeader,
  StatusDropdownMulti,
  DeleteFeatureSet,
  DeleteTargetingElement,
  ModalComponent,
  DebounceDropdown,
  DeleteAddResearchElement,
  CommonElementWithoutLabel,
  DebounceDropdownSingleSelect,
  GlobalFilterModal,
  Breadcrumb,
  MultiCustomTaxonomy,
};

export const formatDate = (date: Dayjs) => {
  return date.format('YYYY-MM-DD');
};
