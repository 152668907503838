import { FC, useEffect } from 'react';
import { Form, Input, Select } from 'antd';
import { useMasterUserRoleElementsStore } from '../../../../stores/masterData.store';
import { IMasterRoleDDState } from '../../../../models/interfaces';

const ProfileDetails: FC<{
  userData: any;
  saveUser: React.Dispatch<React.SetStateAction<any>>;
}> = ({ userData, saveUser }) => {
  const { roleDDElements } = useMasterUserRoleElementsStore(
      (state: IMasterRoleDDState) => state
    ),
    [form] = Form.useForm(),
    values = Form.useWatch([], form);

  useEffect(() => {
    saveUser((prev: any) => ({
      ...prev,
      first_name: values?.first_name,
      last_name: values?.last_name,
    }));
  }, [values]);

  return (
    <div className="profileDetailsForm">
      <Form
        form={form}
        // labelCol={{ span: 24 }}
        autoComplete="off"
        // className='profileDetailsForm'
        requiredMark={false}
        initialValues={userData}
      >
        <Form.Item
          label={<span className="elementLabel">First Name</span>}
          name="first_name"
          validateDebounce={100}
          validateFirst
          rules={[
            {
              max: 50,
              message: 'First name cannot exceed 50 characters',
            },
          ]}
        >
          <Input placeholder="First Name" value={userData.first_name} />
        </Form.Item>
        <Form.Item
          label={<span className="elementLabel">Last Name</span>}
          name="last_name"
          validateDebounce={100}
          validateFirst
          rules={[
            {
              max: 50,
              message: 'Last name cannot exceed 50 characters',
            },
          ]}
        >
          <Input placeholder="Last Name" />
        </Form.Item>
        <Form.Item
          label={<span className="elementLabel">Email</span>}
          name="email"
          validateDebounce={100}
          validateFirst
          rules={[
            {
              required: true,
              message: 'Email name is required',
            },
            {
              type: 'email',
              message: 'Enter a valid email',
            },
          ]}
        >
          <Input placeholder="Email" disabled />
        </Form.Item>
        <Form.Item label={<span className="elementLabel">Role</span>}
         name="role" 
         validateDebounce={100}>
          <Select options={roleDDElements} value={userData.role} disabled />
        </Form.Item>
      </Form>
    </div>
  );
};

export default ProfileDetails;
