import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import Cookies from 'js-cookie';
import { cognitoConfig } from '../awsConfig/awsConfig';
import { Amplify, Auth } from 'aws-amplify';

Amplify.configure(cognitoConfig);

class ApiService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: 'url' as string,
      headers: {
        'Content-Type': 'application/json',
        // Authorization: `Bearer authtoken`,
      },
    });
  }

  private async handleError<T>(
    config: AxiosRequestConfig,
    error: AxiosError
  ): Promise<T> {
    throw error;
  }

  getUserToken() {
    try {
      const token = Cookies.get('branch-lab');

      if (token) {
        const userInfo: any = JSON.parse(atob(token));

        return userInfo.user_token;
      }
      return undefined;
    } catch (error) {
      return undefined;
    }
  }

  private async request<T>(
    config: AxiosRequestConfig,
    isAccessTokenAvailable: boolean = true
  ): Promise<T> {
    try {
      const xApiKey = process.env.REACT_APP_API_KEY;

      if (isAccessTokenAvailable) {
        const session = await Auth.currentSession();
        const headers = {
          'Content-Type': 'application/json',
          'x-api-key': xApiKey,
          'user-token': this.getUserToken(),
          'access-token': session.getAccessToken().getJwtToken(),
        };

        const response: AxiosResponse<T> = await this.axiosInstance.request<T>({
          ...config,
          headers,
        });
        return response.data;
      } else {
        const headers = {
          'Content-Type': 'application/json',
          'x-api-key': xApiKey,
        };

        const response: AxiosResponse<T> = await this.axiosInstance.request<T>({
          ...config,
          headers,
        });
        return response.data;
      }
    } catch (error) {
      return this.handleError(config, error as AxiosError);
    }
  }

  public async get<T>(
    url: string,
    params?: any,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return this.request<T>({
      ...config,
      url,
      method: 'get',
      params,
    });
  }

  public async post<T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
    isAccessTokenAvailable?: boolean
  ): Promise<T> {
    return this.request<T>(
      {
        ...config,
        url,
        method: 'post',
        data: data ?? {},
      },
      isAccessTokenAvailable ?? false
    );
  }

  public async patch<T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return this.request<T>({
      ...config,
      url,
      method: 'patch',
      data,
    });
  }

  public async put<T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return this.request<T>({
      ...config,
      url,
      method: 'put',
      data,
    });
  }
}

const apiService = new ApiService();
export default apiService;
