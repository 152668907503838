import React, { Fragment, useEffect, useState } from 'react';
import { LeftPanel } from '../LeftNavPanel';
import { PageHeader } from '../../sharedComponent';
import {
  Organisation,
  OrganisationModal,
} from '../AccountSettings/AccountSettingsComponent';
import {
  useNotificationMessage,
  usePostApiInterceptor,
} from '../../../hooks';
import { RoutesEnum } from '../../../models/enums/apiRoutes';
import { ILoaderState, Iadvertiser, Iagency, Ibrand } from '../../../models/interfaces';
import { useLoaderStore } from '../../../stores';

type TorganisationBody = {
  organization_type: string | null;
  id: number;
  name: string;
};

const OrganisationSettings = () => {
  document.title = 'Organization - BranchLab';
  const { setIsMounted } = useLoaderStore((state: ILoaderState) => state);
  const 
    [isSettingMounted, setIsSettingMounted] = useState<boolean>(false),
    [brand, setBrand] = useState<Ibrand[]>([]),
    [agency, setAgency] = useState<Iagency[]>([]),
    [body, setBody] = useState<any>({}),
    [isLoading, setIsLoading] = useState<boolean>(false),
    [advertiser, setAdvertiser] = useState<Iadvertiser[]>([]),
    [organisationType, setOrganisationType] = useState<string | null>(null),
    [openOrganisation, setOpenOrganisation] = useState<boolean>(false),
    [messageObj, setMessageObj] = useState<any>({ isShowing: false }),
    [organisationId, setOrganisationId] = useState<number>(0),
    [orgainsationMounted, setOrgainsationMounted] = useState<boolean>(false),
    [orgainsationBody, setOrganisationBody] =
      useState<TorganisationBody | null>(null),
    [fieldVal, setFieldVal] = useState<string>('');

  const { context, destroyMessage } = useNotificationMessage(messageObj);

  useEffect(() => {
    setBody({});
    setIsLoading(true);
    setIsSettingMounted(true);
  }, []);

  const updateErrorMessage = (type: string, messageContent: string) => {
    setMessageObj({
      key: 'save',
      isShowing: true,
      type,
      messageContent,
      duration: 5,
    });
  };

  usePostApiInterceptor(
    isSettingMounted,
    body,
    RoutesEnum.GET_ACCOUNT_SETTINGS,
    (data: any, error: any) => {
      setIsSettingMounted(false);
      if (error || !data) {
        setIsLoading(false);
        return;
      }
      setAgency(data.agency_list ?? []);
      setAdvertiser(data.advertiser_list ?? []);
      setBrand(data.brand_list ?? []);
      setIsSettingMounted(false);
      setIsLoading(false);
    }
  );

  usePostApiInterceptor(
    orgainsationMounted,
    orgainsationBody,
    RoutesEnum.SAVE_ACCCOUNT_ORGANISATION,
    (data: any, error: any) => {
      setOrganisationBody(null);
      setOrgainsationMounted(false);
      if (data && !error) {
        setBody({});
        setIsSettingMounted(true);
        setIsMounted(true);
        updateErrorMessage('success', `${organisationType} is saved`);
      } else {
        updateErrorMessage(
          'error',
          data.msg
            ? `${data.msg}`
            : `Error occurred while saving ${organisationType}.`
        );
      }
    }
  );

  const handleMessage = () => {
    setMessageObj({
      key: 'save',
      isShowing: false,
      duration: 5,
    });
    destroyMessage('save');
  };

  const handleSaveOrganisation = (value: string) => {
    setOrganisationBody({
      organization_type: organisationType,
      id: organisationId,
      name: value,
    });
    setOrgainsationMounted(true);
  };

  return (
    <Fragment>
      {context}
      <LeftPanel defaultSelectedKeys={['7', '233']} />
      {!isLoading && (
        <div className="audienceCommonWrap">
          <PageHeader title="Organization" />
          <div>
            <Organisation
              brands={brand}
              agencies={agency}
              advertisers={advertiser}
              isLoading={isLoading}
              onOpen={() => setOpenOrganisation(true)}
              setType={setOrganisationType}
              setField={setFieldVal}
              setId={setOrganisationId}
              destroyMessage={handleMessage}
            />
          </div>
          {openOrganisation && (
            <OrganisationModal
              type={
                organisationType === 'agency'
                  ? 'agency'
                  : organisationType === 'brand'
                  ? 'brand'
                  : 'advertiser'
              }
              isOpen={openOrganisation}
              onClose={() => setOpenOrganisation(false)}
              onSave={handleSaveOrganisation}
              value={fieldVal}
              setVal={setFieldVal}
              setId={setOrganisationId}
            />
          )}
        </div>
      )}
    </Fragment>
  );
};

export default OrganisationSettings;
