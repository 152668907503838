import { Route } from 'react-router-dom';
import { AuthGuard } from '../guards';
import {
  ActiveAudienceListingPage,
  AudienceActivationPage,
  AudienceListingPage,
  AudienceSetupPage,
  PixelListingPage,
  PixelSetupPage,
  ResearchListingPage,
} from '../components/pages';
import AddResearch from '../components/pages/Research/AddResearch';
import ViewAudiencePage from '../components/pages/ViewAudience/ViewAudiencePage';
import { AddAccount } from '../components/pages/AddAccount';
import { AccountManagement } from '../components/pages/AccountManagement';
import { AccountSettings } from '../components/pages/AccountSettings';
import { ProfilePage } from '../components/pages/Profile';
import { UserSettings } from '../components/pages/UserSettings';
import { OrganisationSettings } from '../components/pages/OrganisationSettings';
import { AuditSettings } from '../components/pages/AuditSettings';

const AuthRoutes = [
  <Route
    key="Audience Listing"
    path="/"
    element={<AuthGuard component={<AudienceListingPage />} />}
  />,
  <Route
    key="Create Audience Listing"
    path="/create-audience"
    element={<AuthGuard component={<AudienceSetupPage />} />}
  />,
  <Route
    key="Update Audience Listing"
    path="/update-audience/:id"
    element={<AuthGuard component={<AudienceSetupPage />} />}
  />,
  <Route
    key="View Audience"
    path="/view-audience/:id"
    element={<AuthGuard component={<ViewAudiencePage />} />}
  />,
  <Route
    key="Audience Activation"
    path="/activations"
    element={<AuthGuard component={<ActiveAudienceListingPage />} />}
  />,
  <Route
    key="Activate Audiences"
    path="/audience-activation/:audId"
    element={<AuthGuard component={<AudienceActivationPage />} />}
  />,
  <Route
    key="Pixel Listing"
    path="/pixel-listing"
    element={<AuthGuard component={<PixelListingPage />} />}
  />,
  <Route
    key="Create Pixel Listing"
    path="/create-Pixel"
    element={<AuthGuard component={<PixelSetupPage />} />}
  />,
  <Route
    key="Update Pixel Listing"
    path="/update-pixel/:id"
    element={<AuthGuard component={<PixelSetupPage />} />}
  />,
  <Route
    key="Research"
    path="/research"
    element={<AuthGuard component={<ResearchListingPage />} />}
  />,
  <Route
    key="Create Research Listing"
    path="/add-research"
    element={<AuthGuard component={<AddResearch />} />}
  />,
  <Route
    key="Edit Research Listing"
    path="/update-research/:id"
    element={<AuthGuard component={<AddResearch />} />}
  />,
  <Route
    key="Add Account"
    path="/add-account"
    element={<AuthGuard component={<AddAccount />} />}
  />,
  <Route
    key="Account Listing"
    path="/accounts"
    element={<AuthGuard component={<AccountManagement />} />}
  />,
  <Route
    key="Update Account"
    path="/add-account/:id"
    element={<AuthGuard component={<AddAccount />} />}
  />,
  <Route
    key="Account Settings"
    path="/account-settings"
    element={<AuthGuard component={<AccountSettings />} />}
  />,
  <Route
    key="Settings-Account"
    path="/settings/account"
    element={<AuthGuard component={<AccountSettings />} />}
  />,
  <Route
    key="Settings-User"
    path="/settings/user"
    element={<AuthGuard component={<UserSettings />} />}
  />,
  <Route
    key="Settings-Audit"
    path="/settings/audit"
    element={<AuthGuard component={<AuditSettings />} />}
  />,
  <Route
    key="Settings-Organisation"
    path="/settings/organisation"
    element={<AuthGuard component={<OrganisationSettings />} />}
  />,
  <Route
    key="Profile"
    path="/profile"
    element={<AuthGuard component={<ProfilePage />} />}
  />,
];

export default AuthRoutes;
