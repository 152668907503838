import { FC } from 'react';
import { useRedirection } from '../../../hooks';

const LeftPanelPixelMenu: FC<{}> = () => {
  const { redirectPixelListing } = useRedirection();

  return (
    <ul>
      <li onClick={() => redirectPixelListing()}>Conversion Pixels</li>
    </ul>
  );
};
export default LeftPanelPixelMenu;
