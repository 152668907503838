import { useParams } from 'react-router-dom';
import { Button, Input } from 'antd';
import {
  CommonElementLabel,
  ModalComponent,
  PageHeader,
} from '../../../sharedComponent';
import { Fragment, useEffect, useState } from 'react';
import {
  usePostApiInterceptor,
  useRedirection,
  useNotificationMessage,
} from '../../../../hooks';
import { RoutesEnum } from '../../../../models/enums/apiRoutes';
import {
  useCheckIfDataModifiedStore,
  useLoaderStore,
  useUserInformationStore,
} from '../../../../stores';
import {
  ICheckIfDataModifiedState,
  IMasterAdvertiserDDState,
  IMasterAgencyDDState,
  IMasterBrandDDState,
  IPixelDataCheckState,
  IPixelSetDataStore,
  IUserInformationState,
} from '../../../../models/interfaces';
import {
  usePixelDataCheckStore,
  usePixelDataHandlingStore,
} from '../../../../stores/saveAndUpdateData.store';
import { CheckCircleTwoTone, CopyOutlined } from '@ant-design/icons';
import FeatureSetup from './FeatureSetup';
import {
  useMasterAdvertiserElmentStore,
  useMasterAgencyElmentStore,
  useMasterBrandElmentStore,
} from '../../../../stores/masterData.store';

const PixelSetupFieldWrap = () => {
  const { id } = useParams();
  const { redirectPixelListing } = useRedirection();
  const { userInformation } = useUserInformationStore(
      (state: IUserInformationState) => state
    ),
    { ifDataModified, setIfDataModified } = useCheckIfDataModifiedStore(
      (state: ICheckIfDataModifiedState) => state
    ),
    { updatedDataSet } = usePixelDataHandlingStore(
      (state: IPixelSetDataStore) => state
    ),
    { ifUpdated, setIfUpdated } = usePixelDataCheckStore(
      (state: IPixelDataCheckState) => state
    ),
    { agencyDDElements } = useMasterAgencyElmentStore(
      (state: IMasterAgencyDDState) => state
    ),
    { brandDDElements } = useMasterBrandElmentStore(
      (state: IMasterBrandDDState) => state
    ),
    { advertiserDDElements } = useMasterAdvertiserElmentStore(
      (state: IMasterAdvertiserDDState) => state
    );
  const { setLoaderState, updateLoaderState } = useLoaderStore();
  const [title, setTitle] = useState<string>(''),
    [body, setBody] = useState<any>(''),
    [domain, setDomain] = useState<string>(''),
    [isMounted, setIsMounted] = useState<boolean>(false),
    [agency,  setAgency] = useState<null | string>(null),
    [brand, setBrand] = useState<null | string>(null),
    [advertiser, setAdvertiser] = useState<null | string>(null),
    [modalToOpenForCancel, setModalToOpenForCancel] = useState<boolean>(false),
    [isDataSaving, setIsDataSaving] = useState<boolean>(false),
    [pixel_Id, setPixel_Id] = useState<null | number>(null),
    [pixel_Code, setPixel_Code] = useState<string | null>(null),
    [isCopied, setIsCopied] = useState<boolean>(false),
    [isCopiedImage, setIsCopiedImage] = useState<boolean>(false),
    [messageObj, setMessageObj] = useState<any>({ isShowing: false }),
    [isInvalidUrl, setIsInvalidUrl] = useState<boolean>(false);

  const { context, destroyMessage } = useNotificationMessage(messageObj);

  const handleCancel = () => {
    ifDataModified ? setModalToOpenForCancel(true) : redirectPixelListing();
  };

  useEffect(() => {
    body && setIsMounted(true);
  }, [body]);

  usePostApiInterceptor(
    isMounted,
    body,
    RoutesEnum.SAVE_PIXEL_DATA,
    (data: any, error: any) => {
      setLoaderState(false);
      setBody('');
      setIsMounted(false);
      if (data && !error) {
        if (data.pixel_code.length) setPixel_Code(data.pixel_code);
        setIsDataSaving(false);
        updateErrorMessage('success', 'Pixel is saved');
        resetAllLocalState();
      } else {
        updateErrorMessage('error', 'Error occurred while saving Pixel.');
        setIsDataSaving(false);
      }
    }
  );

  const handleSave = () => {
    let validated = isValidUrl(domain);
    if (validated) {
      setIsDataSaving(true);
      const pixelData = {
        user_id: userInformation.user_id,
        title,
        pixel_domain: domain,
        pixel_id: pixel_Id,
        agency_id : agency,
        brand_id : brand,
        advertiser_id : advertiser
      };
      setLoaderState(true);
      setIfUpdated(false);
      destroyMessage('save');
      setMessageObj({ isShowing: false });
      setBody(pixelData);
    } else {
      setIsInvalidUrl(true);
    }
  };

  useEffect(() => {
    setIsInvalidUrl(false);
  }, [domain]);

  const resetAllLocalState = () => {
    setModalToOpenForCancel(false);
    setIfDataModified(false);
  };

  useEffect(() => {
    if (id && ifUpdated && !updateLoaderState) {
      const { title, pixel_domain, pixel_id, pixel_code, agency, brand, advertiser } = updatedDataSet;
      setPixel_Id(pixel_id);
      setTitle(title);
      setDomain(pixel_domain);
      setPixel_Code(pixel_code);
      setAgency(agency)
      setBrand(brand)
      setAdvertiser(advertiser)
    }
  }, [ifUpdated, updateLoaderState, updatedDataSet, id]);

  const handleDelete = () => {
    resetAllLocalState();
    redirectPixelListing();
  };

  const updateErrorMessage = (type: string, messageContent: string) => {
    setMessageObj({
      key: 'save',
      isShowing: true,
      type,
      messageContent,
      duration: 5,
    });
  };

  useEffect(() => {
    if (isCopied || isCopiedImage) {
      setTimeout(() => {
        setIsCopied(false);
        setIsCopiedImage(false);
      }, 1000);
    }
  }, [isCopied, isCopiedImage]);

  const handleCopy = () => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_PIXEL_URL}${pixel_Code}`
    );
    setIsCopied(!isCopied);
  };

  const copyImage = () => {
    navigator.clipboard.writeText(
      `<img src=${process.env.REACT_APP_PIXEL_URL}${pixel_Code} height='1px' width='1px' />`
    );
    setIsCopiedImage(!isCopiedImage);
  };

  const urlRegex = new RegExp(/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*\.com$/i);

  function isValidUrl(url: string): boolean {
    return urlRegex.test(url);
  }

  return (
    <Fragment>
      <div className="audienceCommonWrap">
        {context}
        <PageHeader title={(id ? 'Update' : 'Create') + ' Pixel'} />
        {!updateLoaderState && (
          <FeatureSetup
            defaultTitle={title}
            setTitle={setTitle}
            defaultDomain={domain}
            setDomain={setDomain}
            valid={isInvalidUrl}
            agencyDDElements={agencyDDElements}
            brandDDElements={brandDDElements}
            advertiserDDElements={advertiserDDElements}
            agency={agency}
            setAgency={setAgency}
            brand={brand}
            setBrand={setBrand}
            advertiser={advertiser}
            setAdvertiser={setAdvertiser}
          />
        )}
        {(id || pixel_Code) && !updateLoaderState && (
          <div className="pixelUrlSec">
            <div className="informationText">
              <CommonElementLabel label="Copy the options below and send it to the owner of the website it is to be placed on:" />
            </div>

            <div>
              <div className="mb-20">
                <CommonElementLabel label="Raw URL" />
                <Input
                  placeholder="Raw URL"
                  readOnly
                  value={`${process.env.REACT_APP_PIXEL_URL}${pixel_Code}`}
                  suffix={
                    <div onClick={handleCopy} className="copyAction">
                      {isCopied ? (
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                      ) : (
                        <CopyOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                      )}
                    </div>
                  }
                />
              </div>
              <div className="mb-20">
                <CommonElementLabel label="Image" />
                <Input
                  placeholder="Image"
                  readOnly
                  value={`<img src=${process.env.REACT_APP_PIXEL_URL}${pixel_Code} height='1px' width='1px' />`}
                  suffix={
                    <div onClick={copyImage} className="copyAction">
                      {isCopiedImage ? (
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                      ) : (
                        <CopyOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                      )}
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {!updateLoaderState && (
        <div className="newFeatureSetBtn">
          <ModalComponent
            modalHeader={'Cancel ' + (id ? 'Update' : 'Create') + ' Pixel'}
            modalToOpen={modalToOpenForCancel}
            setModalToOpen={setModalToOpenForCancel}
            deleteSetParams={{
              name: 'Cancel ' + (id ? 'Update' : 'Create') + ' Pixel',
              handelOk: handleDelete,
              message:
                'You’re about to lose any unsaved changes. Do you want to continue?',
              okText: 'Yes',
              cancelText: 'No',
            }}
          />
          <Button className="noBGBtn" size="large" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            loading={isDataSaving}
            disabled={!title || !domain || !agency || !brand || !advertiser}
            size="large"
            className="primaryBtn"
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
      )}
    </Fragment>
  );
};

export default PixelSetupFieldWrap;
