import React, { useEffect, useState } from 'react';
import { LeftPanel } from '../LeftNavPanel';
import { PageHeader } from '../../sharedComponent';
import {
  UserModal,
  UserTable,
} from '../AccountSettings/AccountSettingsComponent';
import { RbacSettings } from '../../../models/enums/rbacSetting';
import { useUserInformationStore } from '../../../stores';
import { IUserInformationState } from '../../../models/interfaces';
import {
  useNotificationMessage,
  usePostApiInterceptor,
} from '../../../hooks';
import { RoutesEnum } from '../../../models/enums/apiRoutes';

type Tuser = {
  account_id: number;
  user_id: number;
  name: string;
  email: string;
  role_name: string;
  is_active: boolean;
};

interface Iuser {
  uid: number;
  email: string;
  first_name: string;
  last_name: string;
  account_id: number;
  role_id: number;
}

const UserSettings = () => {
  document.title = 'User Management - BranchLab';
  const [isLoading, setIsLoading] = useState<boolean>(false),
    [isSettingMounted, setIsSettingMounted] = useState<boolean>(false),
    [openUserModal, setOpenUserModal] = useState<boolean>(false),
    [userList, setUserList] = useState<Tuser[]>([]),
    [body, setBody] = useState<any>({}),
    [userBody, setUserBody] = useState<Iuser | null>(null),
    [userObject, setUserObject] = useState<any>(null),
    [userMounted, setUserMounted] = useState<boolean>(false),
    [settings, setSettings] = useState<any>(null),
    [messageObj, setMessageObj] = useState<any>({ isShowing: false });

  const { privilegeSetDDElements } = useUserInformationStore(
    (state: IUserInformationState) => state
  );

  const handleActive = () => {
    setBody({});
    setIsSettingMounted(true);
  };

  useEffect(() => {
    setBody({});
    setIsLoading(true);
    setIsSettingMounted(true);
  }, []);

  const { context, destroyMessage } = useNotificationMessage(messageObj);

  const updateErrorMessage = (type: string, messageContent: string) => {
    setMessageObj({
      key: 'save',
      isShowing: true,
      type,
      messageContent,
      duration: 5,
    });
  };

  usePostApiInterceptor(
    isSettingMounted,
    body,
    RoutesEnum.GET_ACCOUNT_SETTINGS,
    (data: any, error: any) => {
      setIsSettingMounted(false);
      if (error || !data) {
        setIsLoading(false);
        return;
      }
      setSettings(data);
      setUserList(data.user_list ?? []);
      setIsSettingMounted(false);
      setIsLoading(false);
    }
  );

  const handleMessage = () => {
    setMessageObj({
      key: 'save',
      isShowing: false,
      duration: 5,
    });
    destroyMessage('save');
  };

  usePostApiInterceptor(
    userMounted,
    userBody,
    RoutesEnum.SAVE_USER,
    (data: any, error: any) => {
      if (data && !error) {
        updateErrorMessage('success', `User ${userBody?.email} has been created`);
        setBody({});
        setIsSettingMounted(true);
      } else {
        updateErrorMessage('error', 'An error has occurred when creating user.');
      }
      setUserBody(null);
      setUserMounted(false);
    }
  );

  return (
    <div>
      {context}
      <LeftPanel defaultSelectedKeys={['7', '232']} />
      {!isLoading && (
        <div className="audienceCommonWrap">
          <PageHeader title="Users Management" />
          <div>
            <div className="aboveTableAction newUserCTA">
              {privilegeSetDDElements.includes(
                RbacSettings.USER_MANAGEMENT_ADD
              ) && (
                <button
                  className="primaryBtn"
                  onClick={() => setOpenUserModal(true)}
                >
                  New User
                </button>
              )}
            </div>
            <UserTable
              isLoading={isLoading}
              data={userList}
              setUser={setUserObject}
              setOpen={setOpenUserModal}
              handleActive={handleActive}
              destroy={handleMessage}
              updateErrorMessage={updateErrorMessage}
            />
          </div>
          {openUserModal && (
            <UserModal
              isOpen={openUserModal}
              close={setOpenUserModal}
              setUser={setUserBody}
              account_id={settings?.account_info?.account_id ?? 1}
              handleMount={setUserMounted}
              value={userObject}
              setVal={setUserObject}
              destroy={handleMessage}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default UserSettings;
