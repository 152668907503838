import { Button, Table, TableColumnsType } from 'antd';
import dayjs from 'dayjs';
import React, { FC } from 'react';
import CustomEmptyTableComponent from '../../../sharedComponent/Empty/CustomEmptyTableComponent';
import { useUserInformationStore } from '../../../../stores';
import { IUserInformationState } from '../../../../models/interfaces';
import { RbacSettings } from '../../../../models/enums/rbacSetting';
import { SyncOutlined } from '@ant-design/icons';

interface DTCdataType {
  date: string;
  audience_size: number;
  patient_population: number;
  passing_score: number;
}

interface HCPdataType {
  date: string;
  audience_size: number;
}

const AudienceEstimateTable: FC<{
  selectedAudienceType: number | null;
  isLoading: boolean;
  data: any;
  disable: boolean;
  isActive: boolean;
  generateEstimate: () => void;
}> = ({
  isLoading,
  data,
  disable,
  selectedAudienceType,
  generateEstimate,
  isActive,
}) => {
  const { privilegeSetDDElements } = useUserInformationStore(
    (state: IUserInformationState) => state
  );

  const dtcColumns: TableColumnsType<DTCdataType> = [
    {
      title: 'Date',
      dataIndex: 'processed_at',
      key: 'processed_at',
      render: (text) => dayjs(text).format('M/D/YYYY'),
      width: '180px',
      showSorterTooltip: false,
    },
    {
      title: 'Audience Size',
      dataIndex: 'size',
      key: 'size',
      width: '130px',
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Patient Population',
      dataIndex: 'population',
      key: 'population',
      width: '130px',
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Passing Score',
      dataIndex: 'score',
      key: 'score',
      width: '130px',
      ellipsis: true,
      showSorterTooltip: false,
    },
  ];

  const hcpColumns: TableColumnsType<HCPdataType> = [
    {
      title: 'Date',
      dataIndex: 'processed_at',
      key: 'Date',
      width: '180px',
      showSorterTooltip: false,
      render: (text) => dayjs(text).format('M/D/YYYY'),
    },
    {
      title: 'Audience Size',
      dataIndex: 'size',
      key: 'size',
      width: '130px',
      ellipsis: true,
      showSorterTooltip: false,
    },
  ];

  function formatEstimatedSizes(size: number) {
    return size.toLocaleString();
  }

  function manipulateDTCArray(dataArray: DTCdataType[]) {
    return dataArray?.map((item: any) => {
      return {
        ...item,
        size: formatEstimatedSizes(item.size),
        population: formatEstimatedSizes(item.population),
        score: formatEstimatedSizes(item.score),
      };
    });
  }

  function manipulateHCPArray(dataArray: HCPdataType[]) {
    return dataArray?.map((item: any) => {
      return {
        ...item,
        size: formatEstimatedSizes(item.size),
      };
    });
  }

  function getDaysFromDate(dateString: string): number {
    const inputDate = new Date(dateString);

    if (isNaN(inputDate.getTime())) {
      throw new Error('Invalid date string provided');
    }

    const currentDate = new Date();

    const differenceInMilliseconds = Math.abs(
      currentDate.getTime() - inputDate.getTime()
    );

    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const differenceInDays = Math.floor(
      differenceInMilliseconds / millisecondsPerDay
    );

    return differenceInDays;
  }

  return (
    <>
      <div className="sectionHeader audienceEstimate">
        <h2>Audience Estimates</h2>
        {privilegeSetDDElements.includes(RbacSettings.ACTIVATION_ADD) &&
          data.length === 0 && (
            <Button
              className="primaryBtn"
              disabled={isActive || disable}
              onClick={generateEstimate}
              size="large"
            >
              Generate Estimate
            </Button>
          )}
        {privilegeSetDDElements.includes(RbacSettings.ACTIVATION_ADD) &&
          data.length > 0 && (
            <Button
              className="primaryBtn generateEstimateBtn"
              disabled={getDaysFromDate(data[0].processed_at) < 45 || disable}
              onClick={generateEstimate}
              size="large"
            >
              <SyncOutlined />
              <p>Regenerate</p>
            </Button>
          )}
      </div>
      {disable && (
        <p className="errorText">
          This is an error message. These will be send back from the estimate
          service and should be shown only when the error flag is thrown
        </p>
      )}
      {selectedAudienceType === 1 && (
        <Table
          columns={dtcColumns}
          dataSource={manipulateDTCArray(data) ?? []}
          className={`antTableElement audienceListTable${
            isLoading ? ' hideNoDataLabel' : ''
          }`}
          locale={{
            emptyText: <CustomEmptyTableComponent isEstimate />,
          }}
          loading={isLoading}
          pagination={false}
          sortDirections={['ascend', 'descend', 'ascend']}
          scroll={data.length > 0 ? { x: 'max-content' } : undefined}
        />
      )}
      {selectedAudienceType === 2 && (
        <Table
          columns={hcpColumns}
          dataSource={manipulateHCPArray(data) ?? []}
          locale={{
            emptyText: <CustomEmptyTableComponent isEstimate />,
          }}
          className={`antTableElement audienceListTable${
            isLoading ? ' hideNoDataLabel' : ''
          }`}
          pagination={false}
          loading={isLoading}
          sortDirections={['ascend', 'descend', 'ascend']}
          scroll={data.length > 0 ? { x: 'max-content' } : undefined}
        />
      )}
    </>
  );
};

export default AudienceEstimateTable;
