/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import apiService from '../services/Api.service';

const usePostApiInterceptor = (
  isMounted: boolean,
  body: any,
  apiEndpoint: string,
  callback?: Function,
  isAccessTokenAvailable: boolean = true
) => {
  const [data, setData] = useState<any>(null),
    [isLoading, setIsLoading] = useState<boolean>(true),
    [isError, setIsError] = useState<boolean>(false),
    [dataCount, setDataCount] = useState<number>(0);

  const postData = (signal: AbortSignal) => {
    setIsLoading(true);
    setIsError(false);
    try {
      apiService
        .post<any>(
          process.env.REACT_APP_BASE_URL + apiEndpoint,
          body,
          {
            signal,
          },
          isAccessTokenAvailable
        )
        .then(
          (res) => {
            const { data, msg_id } = res;
            if (msg_id > 0) {
              setData(data);
              setDataCount(data?.data_count ?? 0);
            } else {
              setData(null);
              setDataCount(0);
            }

            if (callback) callback(data ?? null, null);
            setIsLoading(false);
          },
          (err) => {
            if (signal.aborted) {
              console.info('Request was cancelled.');
            } else {
              console.error(err);
              setIsError(true);
            }
            if (callback) callback(err?.response?.data, err);
            setIsLoading(false);
          }
        );
      console.info('Request Initiated !! ', apiEndpoint);
    } catch (error: any) {
      console.error(error);
      setIsError(true);
      if (callback) callback({ data: error?.message }, error?.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isMounted) {
      const controller = new AbortController();
      const signal = controller.signal;
      postData(signal);

      return () => {
        controller.abort();
      };
    }
  }, [body, apiEndpoint, isMounted]);

  return { data, isLoading, isError, dataCount };
};

export default usePostApiInterceptor;
