export enum RbacSettings {
    AUDIENCE_VIEW = 'bl-1',
    AUDIENCE_ADD = 'bl-2',
    AUDIENCE_EDIT = 'bl-3',
    AUDIENCE_DELETE = 'bl-4',

    ACTIVATION_VIEW = 'bl-5',
    ACTIVATION_ADD = 'bl-6',
    ACTIVATION_EDIT = 'bl-7',
    ACTIVATION_DELETE = 'bl-8',

    RESEARCH_VIEW = 'bl-9',
    RESEARCH_ADD = 'bl-10',
    RESEARCH_EDIT = 'bl-11',
    RESEARCH_DELETE = 'bl-12',

    PIXEL_VIEW = 'bl-13',
    PIXEL_ADD = 'bl-14',
    PIXEL_EDIT = 'bl-15',
    PIXEL_DELETE = 'bl-16',

    ACCOUNT_SETTINGS_VIEW = 'bl-17',
    ACCOUNT_SETTINGS_ADD = 'bl-18',
    ACCOUNT_SETTINGS_EDIT = 'bl-19',
    ACCOUNT_SETTINGS_DELETE = 'bl-20',

    ACCOUNT_MANAGEMENT_VIEW = 'bl-21',
    ACCOUNT_MANAGEMENT_ADD = 'bl-22',
    ACCOUNT_MANAGEMENT_EDIT = 'bl-23',
    ACCOUNT_MANAGEMENT_DELETE = 'bl-24',

    USER_MANAGEMENT = 'bl-25',
    USER_MANAGEMENT_ADD = 'bl-26',
    USER_MANAGEMENT_EDIT = 'bl-27',
    USER_MANAGEMENT_DELETE = 'bl-28',
}  