import { useEffect, useState } from 'react';
import { usePostApiInterceptor } from '../../../hooks';
import { LeftPanel } from '../LeftNavPanel';
import { PixelSetupFieldWrap } from './PixelSetupComponents';
import { RoutesEnum } from '../../../models/enums/apiRoutes';
import { useParams } from 'react-router-dom';
import { ILoaderState, IPixelDataCheckState, IPixelSetDataStore } from '../../../models/interfaces';
import { usePixelDataCheckStore, usePixelDataHandlingStore } from '../../../stores/saveAndUpdateData.store';
import { useLoaderStore } from '../../../stores';
import { ErrorPage } from '../Error';

const PixelSetupPage = () => {
  const { id } = useParams();

  document.title = (id ? 'Update Pixel ' : 'Create Pixel ') + '- BranchLab';

  const [body, setBody] = useState<{ pixel_id: number } | null>(null),
  [isMounted, setIsMounted] = useState<boolean>(false),
  [isError, setIsError] = useState<boolean>(false);

  const { setUpdateLoaderState } = useLoaderStore(
    (state: ILoaderState) => state
  ),{ setIfUpdated } = usePixelDataCheckStore(
    (state: IPixelDataCheckState) => state
  ),
  { setUpdatedDataSet } = usePixelDataHandlingStore(
    (state: IPixelSetDataStore) => state
  );

  usePostApiInterceptor(
    isMounted,
    body,
    RoutesEnum.GET_PIXEL_DATA,
    (data: any, error: any) => {
      setIsMounted(false);
      if (error || !data || (data && !data.pixel_info)) {
        setIsError(true);
        setUpdateLoaderState(false);
        return;
      }
      setUpdatedDataSet(data.pixel_info);
      setUpdateLoaderState(false);
      setIfUpdated(true);
    }
  );
  
  useEffect(() => {
    if (id) {
      isError && setIsError(false);
      setUpdateLoaderState(true);
      setBody({ pixel_id: parseInt(id) });
      setIsMounted(true);
    }else{
      setUpdatedDataSet({});
      setIfUpdated(false)
    }
  }, [id]);

  return (
    <div className="audienceSetupPage">
      <LeftPanel defaultSelectedKeys={['5']} />
      {!isError ? <PixelSetupFieldWrap /> : <ErrorPage />}
    </div>
  );
};

export default PixelSetupPage;
