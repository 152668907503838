import React, { Dispatch, SetStateAction, useState } from 'react';
import { InputField } from '../../../sharedComponent';
interface IResearchTitle {
  title: any;
  defaultTitleValue: string;
  setTitle: Dispatch<SetStateAction<string>>;
}

const ResearchTitle: React.FC<IResearchTitle> = ({
  setTitle,
  title,
}) => {
  return (
    <InputField
        label="Title"
        defaultValue={title}
        onChange={setTitle}
        inputPlaceholder="Title"
        isMandatory
      />
  );
};

export default ResearchTitle;
