export enum RoutesEnum {
  // User information
  USER_INFORMATION = 'get-user-info',
  EMAIL_AVAILABILITY_CHECK = 'check-user-email',

  // Audience Listing
  AUDIENCE_LISTING_DATA = 'get-audience-list',

  // Pixel Listing
  PIXEL_LISTING_DATA = 'get-pixel-list',
  SAVE_PIXEL_DATA = 'save-pixel',
  GET_PIXEL_DATA = 'get-pixel',
  DELETE_PIXEL_DATA = 'delete-pixel',

  // Audience Setting
  GET_MASTER_DATA = 'get-master-data',
  GET_FEATURE_SET_SUMMARY = 'get-feature-summery',
  GET_FEATURE_SET_DATA = 'get-audience-info',
  SAVE_FEATURE_SET_DATA = 'save-audience',
  DELETE_FEATURE_SET_DATA = 'delete-audience',
  DUPLICATE_AUDIENCE = 'duplicate-audience',
  GET_PRESIGNED_URL = 'get-presigned-url',
  GET_ES_RESULT = 'get-elasticsearch-result',

  // Audience Activation
  GET_ALL_ACTIVATIONS = 'get-activation-list',
  GET_ACTIVATION = 'get-activation-info',
  SAVE_ACTIVATION = 'save-activation',
  DUPLICATE_ACTIVATION = 'clone-activation',
  SAVE_ESTIMATE = 'save-estimate',
  SAVE_ACTIVATES = 'save-activated-audiences',

  //Research List
  GET_RESEARCH_LIST = 'get-researche-list',
  GET_RESEARCH_INFO = 'get-research-info',
  SAVE_RESEARCH = 'save-research',
  DELETE_RESEARCH = 'delete-research',

  // Account
  GET_ACCOUNT_SETTINGS = 'get-account-settings',
  SAVE_ACCOUNT = 'save-account',
  GET_ALL_ACCOUNTS = 'get-account-list',
  GET_ACCOUNT_INFO = 'get-account-info',
  SAVE_ACCCOUNT_ORGANISATION = 'save-account-settings-organization',

  // User
  SAVE_USER = 'save-user',
  DEACTIVATE_USER = 'deactivate-user',
  RESET_PASSWORD = 'reset-password',

  // User Onboarding
  CHECK_USER_EMAIL_INFO = 'check-user-email-info',
  UPDATE_USER_EMAIL_INFO = 'update-user-email-info',

  // Audit
  AUDIT_LISTING =  'get-audit-list',
}
