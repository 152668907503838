import { FC, Fragment, useEffect, useState } from 'react';
import { PageHeader } from '../../../sharedComponent';
import {
  IResearchListingParam,
  ILoaderState,
} from '../../../../models/interfaces';
import { useLoaderStore } from '../../../../stores';
import { ResearchListingTable } from './';
import { RoutesEnum } from '../../../../models/enums/apiRoutes';
import { usePostApiInterceptor, useRedirection } from '../../../../hooks';
import GlobalFilterModal from '../../../sharedComponent/GlobalFilterModal/GlobalFilterModal';
import { Button, ConfigProvider } from 'antd';
import { FilterMessage } from '../../../sharedComponent/FilterMessage';
import { filter } from '../../../../assets/images';

type researchBodyType = {
  report_id: string | null;
  end_date: string | null;
  start_date: string | null;
  order_column: string | null;
  order_direction: string | null;
  limit: number;
  off_set: number;
  title: string | null;
  brand_id: string | null;
  agency_id: string | null;
  advertiser_id: string | null;
  filter_option: number | null;
  timeframe: string | null;
};

const baseListingParam = {
  report_id: null,
  sortOrder: 'DESC',
  sortColumn: 'created_on',
  pageNumber: 1,
  rowsPerPage: 15,
  dateRange: null,
  title: null,
  filter_option: 1,
  timeframe: null,
  agency: null,
  brand: null,
  advertiser: null,
};

const convertFiltersToNumberArray = (filters: any): IResearchListingParam => {
  const result: Partial<IResearchListingParam> = {
    sortOrder: baseListingParam.sortOrder,
    sortColumn: baseListingParam.sortColumn,
    pageNumber: baseListingParam.pageNumber,
    rowsPerPage: baseListingParam.rowsPerPage,
  };

  // Handle global filters
  if (filters.global) {
    if (filters.global.agency)
      result.agency = convertToNumberArray(filters.global.agency);
    if (filters.global.advertiser)
      result.advertiser = convertToNumberArray(filters.global.advertiser);
    if (filters.global.brand)
      result.brand = convertToNumberArray(filters.global.brand);
    if (filters.global.dateRange) result.dateRange = filters.global.dateRange;
    if (filters.global.timeframe) result.timeframe = filters.global.timeframe;
  }
  // Handle activation filters
  if (filters.research) {
    if (filters.research.title) result.title = filters.research.title;
    if (filters.research.filter_option)
      result.filter_option = filters.research.filter_option;
    if (filters.research.report_type)
      result.report_id = convertToNumberArray(filters.research.report_type);
  }

  return result as IResearchListingParam;
};

const convertToNumberArray = (value: string | null): number[] | null => {
  if (!value) return null;
  return value
    .split(',')
    .map(Number)
    .filter((n) => !isNaN(n));
};

const ResearchListingWrap: FC = () => {
  const filters = JSON.parse(localStorage.getItem('Filters') || '{}');
  const [researchListingParam, setResearchListingParam] =
    useState<IResearchListingParam>(
      Object.keys(filters).length > 0
        ? convertFiltersToNumberArray(filters)
        : baseListingParam
    );

  const [body, setBody] = useState<researchBodyType | null>(null),
    [isMounted, setIsMounted] = useState<boolean>(false),
    [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false);

  const { data, isLoading } = usePostApiInterceptor(
      isMounted,
      body,
      RoutesEnum.GET_RESEARCH_LIST,
      () => {
        setIsMounted(false);
      }
    ),
    { redirectAddResearch } = useRedirection();
  const { loaderState } = useLoaderStore((state: ILoaderState) => state);

  useEffect(() => {
    setIsMounted(true);
  }, [body]);

  useEffect(() => {
    const newParams: researchBodyType = {
      end_date: researchListingParam.dateRange?.length
        ? researchListingParam.dateRange[1]
        : null,
      start_date: researchListingParam.dateRange?.length
        ? researchListingParam.dateRange[0]
        : null,
      report_id: researchListingParam.report_id?.length
        ? researchListingParam.report_id.toString()
        : null,
      title: researchListingParam.title,
      filter_option: researchListingParam.filter_option,
      limit: researchListingParam.rowsPerPage,
      timeframe: researchListingParam.timeframe,
      off_set:
        (researchListingParam.pageNumber - 1) *
        researchListingParam.rowsPerPage,
      order_column: researchListingParam.sortColumn,
      order_direction: researchListingParam.sortOrder,
      brand_id: researchListingParam.brand?.length
        ? researchListingParam.brand.toString()
        : null,
      agency_id: researchListingParam.agency?.length
        ? researchListingParam.agency.toString()
        : null,
      advertiser_id: researchListingParam.advertiser?.length
        ? researchListingParam.advertiser.toString()
        : null,
    };

    setBody(newParams);
  }, [researchListingParam]);

  const applyFilters = (data: any) => {
    let filter: researchBodyType = {
      agency_id: data.agency?.length ? data.agency.toString() : null,
      advertiser_id: data.advertiser?.length
        ? data.advertiser.toString()
        : null,
      brand_id: data.brand?.length ? data.brand.toString() : null,
      end_date: data.dateRange?.length ? data.dateRange[1] : null,
      start_date: data.dateRange?.length ? data.dateRange[0] : null,
      report_id: data.report_id?.length ? data.report_id.toString() : null,
      timeframe: data.timeframe,
      title: data.title,
      filter_option: data.filter_option,
      limit: researchListingParam.rowsPerPage,
      off_set:
        (researchListingParam.pageNumber - 1) *
        researchListingParam.rowsPerPage,
      order_column: researchListingParam.sortColumn,
      order_direction: researchListingParam.sortOrder,
    };
    let filterStorage = {
      ...JSON.parse(localStorage.getItem('Filters') || '{}'),
      global: {
        agency: filter.agency_id,
        advertiser: filter.advertiser_id,
        brand: filter.brand_id,
        dateRange:
          filter.start_date && filter.end_date
            ? [filter.start_date, filter.end_date]
            : null,
        timeframe: filter.timeframe,
      },
      research: {
        title: filter.title,
        filter_option: filter.filter_option,
        report_type: filter.report_id,
      },
    };
    setResearchListingParam({
      agency: data.agency?.length ? data.agency : null,
      brand: data.brand?.length ? data.brand : null,
      advertiser: data.advertiser?.length ? data.advertiser : null,
      title: data.title,
      filter_option: data.filter_option,
      rowsPerPage: baseListingParam.rowsPerPage,
      timeframe: data.timeframe ?? null,
      pageNumber: baseListingParam.pageNumber,
      sortColumn: baseListingParam.sortColumn,
      sortOrder: baseListingParam.sortOrder,
      report_id: data.report_id?.length ? data.report_id.toString() : null,
      dateRange: data.dateRange?.length
        ? [data.dateRange[0], data.dateRange[1]]
        : null,
    });
    localStorage.setItem('Filters', JSON.stringify(filterStorage));
    setBody(filter);
    setIsFilterModalOpen(false);
  };

  const handleCancel = () => {
    setIsFilterModalOpen(false);
  };

  return (
    <div className="audienceCommonWrap">
      <PageHeader title="Research" />
      <div className="audienceListingPageContent">
        {!loaderState && (
          <Fragment>
            <div className="filterWrap">
              <div className='filterBtnAndText'>
              <ConfigProvider wave={{ disabled: true }}>
              <Button onClick={() => setIsFilterModalOpen(true)}>
              <img src={filter} alt="" />
              </Button>
              </ConfigProvider>
              {(researchListingParam.agency ||
                researchListingParam.advertiser ||
                researchListingParam.brand ||
                researchListingParam.timeframe ||
                researchListingParam.dateRange ||
                researchListingParam.title ||
                researchListingParam.report_id) && (
                <FilterMessage type="research" data={researchListingParam} />
              )}
              </div>

              <GlobalFilterModal
                isOpen={isFilterModalOpen}
                filterData={researchListingParam}
                type="research"
                handleOk={applyFilters}
                handleCancel={handleCancel}
              />
              <button
                className="primaryBtn addAudienceBtn"
                onClick={() => redirectAddResearch()}
              >
                New Research
              </button>
            </div>

            <ResearchListingTable
              researchData={data}
              isLoading={isLoading}
              paginationAndSortingData={researchListingParam}
              setPaginationAndSortingData={setResearchListingParam}
            />
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default ResearchListingWrap;
