import { Button, Form, Input } from 'antd';
import { logoLogin } from '../../../assets/images';
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import {
  useAuth,
  usePostApiInterceptor,
  useNotificationMessage,
} from '../../../hooks';
import {
  CheckOutlined,
  CloseOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from '@ant-design/icons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { cognitoConfig } from '../../../awsConfig/awsConfig';
import { Amplify, Auth } from 'aws-amplify';
import { RoutesEnum } from '../../../models/enums/apiRoutes';

Amplify.configure(cognitoConfig);

interface SubmitButtonProps {
  label?: string;
  isLoading: boolean;
  isDisabled: boolean;
}

interface IParamData {
  email: string;
  type: 1 | 2;
  mailId: number;
}

interface IUpdateUserBody {
  email: string;
  is_mail_active: boolean;
  mail_id: number;
}

const baseUserInfo: IParamData = {
  email: '',
  type: 1,
  mailId: 0,
};

const SubmitButton: FC<PropsWithChildren<SubmitButtonProps>> = ({
  label = 'submit',
  isLoading,
  isDisabled,
}) => {
  return (
    <Button
      type="primary"
      htmlType="submit"
      disabled={isLoading || isDisabled}
      className={`primaryBtn loginBtn${
        isLoading || isDisabled ? ' loginButtonDisable' : ''
      }`}
      loading={isLoading}
    >
      {label}
    </Button>
  );
};

const MailExpiredPage = () => {
  return <div className="expirationEmailBody">Link has expired</div>;
};

const VerifyUser = () => {
  document.title = 'User Verification - BranchLab';
  const navigate = useNavigate(),
    { logout } = useAuth();

  const [searchParams] = useSearchParams();

  const [messageObj, setMessageObj] = useState<any>({ isShowing: false }),
    [isLoadingVerify, setIsLoadingVerify] = useState<boolean>(false),
    [isDisabled, setIsDisabled] = useState<boolean>(true),
    [userInfo, setUserInfo] = useState<IParamData>(baseUserInfo),
    [isMountedCheckUser, setIsMountedCheckUser] = useState<boolean>(false),
    [isMountedUpdateUser, setIsMountedUpdateUser] = useState<boolean>(false),
    [isEmailExpired, setIsEmailExpired] = useState<boolean>(false),
    [isInitiated, setIsInitiated] = useState<boolean>(true),
    [updateBody, setUpdateBody] = useState<IUpdateUserBody | null>(null);

  const { context, destroyMessage } = useNotificationMessage(messageObj);

  const EmailRegex = new RegExp(/^[a-zA-Z0-9]+$/);

  const updateErrorMessage = (type: string, messageContent: string) => {
    setMessageObj({
      key: 'save',
      isShowing: true,
      type,
      messageContent,
      duration: 5,
    });
  };

  usePostApiInterceptor(
    isMountedCheckUser,
    {
      email: userInfo.email,
      email_type: userInfo.type,
      mail_id: userInfo.mailId,
    },
    RoutesEnum.CHECK_USER_EMAIL_INFO,
    (data: any, error: any) => {
      setIsMountedCheckUser(false);
      if (!error && data?.is_available) {
        setIsDisabled(false);
      } else {
        setIsEmailExpired(true);
      }
    },
    false
  );

  usePostApiInterceptor(
    isMountedUpdateUser,
    updateBody,
    RoutesEnum.UPDATE_USER_EMAIL_INFO,
    (data: any, error: any) => {
      setIsMountedUpdateUser(false);
      setIsLoadingVerify(false);
      if (!error && data?.email === userInfo.email) {
        updateErrorMessage(
          'success',
          `${
            userInfo.type === 1
              ? 'Password generated successful'
              : 'Password reset successful'
          }`
        );
        logout();
      } else {
        handleError();
      }
    },
    false
  );

  const [form] = Form.useForm(),
    values = Form.useWatch([], form);

  const handleDecrypt = (em: string) => {
    try {
      const decryptedData = atob(em),
        ui = JSON.parse(decryptedData);
      if (!(ui['type'] && ui['email'] && ui['mailId'])) {
        handleError();
      }

      setUserInfo(ui);
    } catch (error) {
      console.error(error);
      handleError();
    }
  };

  const handleError = () => {
    navigate('/error');
    setUserInfo(baseUserInfo);
  };

  useEffect(() => {
    const encryptedParams = searchParams?.get('param');
    if (encryptedParams && isInitiated) {
      setIsDisabled(true);
      handleDecrypt(encryptedParams);
    }
  }, [searchParams]);

  useEffect(() => {
    if (
      JSON.stringify(userInfo) !== JSON.stringify(baseUserInfo) &&
      isInitiated
    ) {
      setIsInitiated(false);
      setIsMountedCheckUser(true);
    }
  }, [userInfo]);

  const verifyUser = async () => {
    destroyMessage('save');
    setMessageObj({
      key: 'save',
      isShowing: false,
      duration: 5,
    });
    setIsLoadingVerify(true);
    if (userInfo.type === 1) {
      await signUp(userInfo.email, values.new_password);
      const user = await authenticateUser();
      if (user) {
        await Auth.signOut();
      }

      setUpdateBody({
        email: userInfo.email,
        is_mail_active: false,
        mail_id: userInfo.mailId,
      });
      setIsMountedUpdateUser(true);
    } else {
      await resetPassword(
        userInfo.email,
        values.old_password,
        values.new_password
      );
    }
  };

  const renderEyeIcon = (visible: boolean) =>
    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />;

  const signUp = async (username: string, password: string) => {
    try {
      await Auth.signUp({
        username,
        password,
        attributes: {
          email: username,
        },
      });
      console.info('Password generated successful');
    } catch (error) {
      updateErrorMessage('error', 'Error while generation password');
      console.error('Error while generation password:', error);
    }
  };

  const resetPassword = async (
    username: string,
    oldPassword: string,
    newPassword: string
  ) => {
    try {
      let user = await authenticateUser();
      if (!user) {
        const signedInUser = await Auth.signIn({
          username,
          password: oldPassword,
        });
        if (signedInUser) {
          user = await authenticateUser();
        }
      }

      if (user) {
        await Auth.changePassword(user, oldPassword, newPassword);
        console.info('Password reset successful');
        await Auth.signOut();

        setUpdateBody({
          email: userInfo.email,
          is_mail_active: false,
          mail_id: userInfo.mailId,
        });
        setIsMountedUpdateUser(true);
      } else {
        updateErrorMessage(
          'error',
          'Error occurred while resetting password: User information is not available'
        );
        console.error(
          'Error occurred while resetting password: User information is not available'
        );
        setIsLoadingVerify(false);
      }
    } catch (error) {
      updateErrorMessage('error', 'Incorrect old password');
      console.error('Error occurred while resetting password:', error);
      setIsLoadingVerify(false);
    }
  };

  const authenticateUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      console.info('User is authenticated');
      return user;
    } catch (error) {
      console.error('User is not authenticated');
      return null;
    }
  };
  const minLengthRegex = /^.{6,}$/;
  const maxLengthRegex = /^.{1,127}$/;
  const differentFromEmail = (email: string) => (password: string) =>
    !password.includes(email);
  const mixedCaseAndNumberRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/;
  const specialCharacterRegex = /(?=.*\W)/;

  return (
    <div className="loginWrap">
      <div className="loginContentWrap">
        {isEmailExpired ? (
          <MailExpiredPage />
        ) : (
          <div className="loginContent">
            {context}
            <h1>
              <img src={logoLogin} alt="" />
            </h1>
            {userInfo.type === 2 ? (
              <h2>Reset Password</h2>
            ) : (
              <h2>Generate Password</h2>
            )}
            <Form
              form={form}
              labelCol={{ span: 24 }}
              name="bl_login"
              autoComplete="off"
              requiredMark={false}
              onFinish={verifyUser}
            >
              {userInfo.type === 2 && (
                <Form.Item
                  label="Old Password"
                  name="old_password"
                  validateDebounce={1000}
                  validateFirst
                  rules={[
                    {
                      required: true,
                      message: 'Password is required',
                    },
                  ]}
                >
                  <Input.Password
                    type="password"
                    placeholder="Old Password"
                    iconRender={renderEyeIcon}
                    disabled={isLoadingVerify || isDisabled}
                    className="loginPageField"
                    autoFocus
                  />
                </Form.Item>
              )}

              <Form.Item
                label="New Password"
                name="new_password"
                validateDebounce={1000}
                validateFirst
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                  {
                    min: 8,
                    message: '',
                  },
                  {
                    max: 128,
                    message: '',
                  },
                  {
                    validator(_, value) {
                      const email = form.getFieldValue('email'); // Assuming you have an email field
                      if (value && email && value.includes(email)) {
                        return Promise.reject(new Error(''));
                      }
                      return Promise.resolve();
                    },
                  },
                  {
                    validator(_, value) {
                      const mixedCaseRegex =
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/;
                      if (value && !mixedCaseRegex.test(value)) {
                        return Promise.reject(new Error(''));
                      }
                      return Promise.resolve();
                    },
                  },
                  {
                    validator(_, value) {
                      const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/; // Adjust as needed for valid special characters
                      if (value && !specialCharRegex.test(value)) {
                        return Promise.reject(new Error(''));
                      }
                      return Promise.resolve();
                    },
                  },
                  () => ({
                    validator(_, value) {
                      if (!EmailRegex.test(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error('Password doesnot match the condition')
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  type="password"
                  placeholder="New Password"
                  iconRender={renderEyeIcon}
                  disabled={isLoadingVerify}
                  className="loginPageField"
                  autoFocus
                />
              </Form.Item>

              <div className="rules">
                <p>Password requirements:</p>
                <ul>
                  <li>
                    {values?.new_password &&
                    minLengthRegex.test(values?.new_password) ? (
                      <CheckOutlined className="tick" />
                    ) : (
                      <CloseOutlined className="cross" />
                    )}{' '}
                    must be a least 6 characters
                  </li>
                  <li>
                    {values?.new_password &&
                    maxLengthRegex.test(values?.new_password) ? (
                      <CheckOutlined className="tick" />
                    ) : (
                      <CloseOutlined className="cross" />
                    )}{' '}
                    must be fewer than 128 characters
                  </li>
                  <li>
                    {values?.new_password &&
                    differentFromEmail(values?.new_password) ? (
                      <CheckOutlined className="tick" />
                    ) : (
                      <CloseOutlined className="cross" />
                    )}{' '}
                    must be different from email address
                  </li>
                  <li>
                    {mixedCaseAndNumberRegex.test(values?.new_password) ? (
                      <CheckOutlined className="tick" />
                    ) : (
                      <CloseOutlined className="cross" />
                    )}{' '}
                    must include letters in mixed case and numbers
                  </li>
                  <li>
                    {specialCharacterRegex.test(values?.new_password) ? (
                      <CheckOutlined className="tick" />
                    ) : (
                      <CloseOutlined className="cross" />
                    )}{' '}
                    must include a character that is not a letter or number
                  </li>
                </ul>
              </div>

              <Form.Item>
                <SubmitButton
                  label="Save"
                  isLoading={isLoadingVerify}
                  isDisabled={isDisabled}
                />
              </Form.Item>
            </Form>
          </div>
        )}
      </div>
    </div>
  );
};

export default VerifyUser;
