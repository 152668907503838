import { FC, Fragment, useEffect, useState } from 'react';
import { PageHeader } from '../../../sharedComponent';
import {
  IActiveAudienceListingParam,
  ILoaderState,
} from '../../../../models/interfaces';
import { useLoaderStore } from '../../../../stores';
import { ActiveAudienceListingTable } from './';
import { RoutesEnum } from '../../../../models/enums/apiRoutes';
import GlobalFilterModal from '../../../sharedComponent/GlobalFilterModal/GlobalFilterModal';
import { Button, ConfigProvider } from 'antd';
import { FilterMessage } from '../../../sharedComponent/FilterMessage';
import { filter } from '../../../../assets/images';
import { usePostApiInterceptor } from '../../../../hooks';

type activationAudBodyType = {
  end_date: string | null;
  start_date: string | null;
  limit: number;
  off_set: number;
  title: string | null;
  order_column: string | null;
  order_direction: string | null;
  partner: number | null;
  brand_id: string | null;
  agency_id: string | null;
  advertiser_id: string | null;
  filter_option: number | null;
  timeframe: string | null;
};

const baseListingParam = {
  sortOrder: 'DESC',
  sortColumn: 'created_on',
  pageNumber: 1,
  rowsPerPage: 15,
  dateRange: null,
  status: null,
  partner: null,
  title: '',
  agency: null,
  brand: null,
  advertiser: null,
  audienceType: null,
  timeframe: null,
  filter_option: 1,
};

const convertFiltersToNumberArray = (
  filters: any
): IActiveAudienceListingParam => {
  const result: Partial<IActiveAudienceListingParam> = {
    sortOrder: baseListingParam.sortOrder,
    sortColumn: baseListingParam.sortColumn,
    pageNumber: baseListingParam.pageNumber,
    rowsPerPage: baseListingParam.rowsPerPage,
  };

  // Handle global filters
  if (filters.global) {
    if (filters.global.agency)
      result.agency = convertToNumberArray(filters.global.agency);
    if (filters.global.advertiser)
      result.advertiser = convertToNumberArray(filters.global.advertiser);
    if (filters.global.brand)
      result.brand = convertToNumberArray(filters.global.brand);
    if (filters.global.dateRange) result.dateRange = filters.global.dateRange;
    if (filters.global.timeframe) result.timeframe = filters.global.timeframe;
  }

  // Handle activation filters
  if (filters.activation) {
    if (filters.activation.title) result.title = filters.activation.title;
    if (filters.activation.filter_option)
      result.filter_option = filters.activation.filter_option;
    if (filters.activation.partner)
      result.partner = filters.activation.partner.toString();
  }

  return result as IActiveAudienceListingParam;
};

// Helper function to convert string to number array
const convertToNumberArray = (value: string | null): number[] | null => {
  if (!value) return null;
  return value
    .split(',')
    .map(Number)
    .filter((n) => !isNaN(n));
};
  
const ActiveAudienceListingWrap: FC = () => {
  const filters = JSON.parse(localStorage.getItem('Filters') || '{}');
  const [activationAudListingParam, setActivationAudListingParam] =
    useState<IActiveAudienceListingParam>(
      Object.keys(filters).length > 0
        ? convertFiltersToNumberArray(filters)
        : baseListingParam
    );

  const [body, setBody] = useState<activationAudBodyType | null>(null),
    [isMounted, setIsMounted] = useState<boolean>(false);

  const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false);

  const { data, isLoading } = usePostApiInterceptor(
    isMounted,
    body,
    RoutesEnum.GET_ALL_ACTIVATIONS,
    () => {
      setIsMounted(false);
    }
  );

  const { loaderState } = useLoaderStore((state: ILoaderState) => state);

  useEffect(() => {
    setIsMounted(true);
  }, [body]);

  useEffect(() => {
    const newParams: activationAudBodyType = {
      agency_id: activationAudListingParam.agency?.length
        ? activationAudListingParam.agency.toString()
        : null,
      advertiser_id: activationAudListingParam.advertiser?.length
        ? activationAudListingParam.advertiser.toString()
        : null,
      brand_id: activationAudListingParam.brand?.length
        ? activationAudListingParam.brand.toString()
        : null,
      timeframe: activationAudListingParam.timeframe,
      end_date: activationAudListingParam.dateRange?.length
        ? activationAudListingParam.dateRange[1]
        : null,
      start_date: activationAudListingParam.dateRange?.length
        ? activationAudListingParam.dateRange[0]
        : null,
      title: activationAudListingParam.title,
      filter_option: activationAudListingParam.filter_option,
      limit: activationAudListingParam.rowsPerPage,
      off_set:
        (activationAudListingParam.pageNumber - 1) *
        activationAudListingParam.rowsPerPage,
      order_column: activationAudListingParam.sortColumn,
      order_direction: activationAudListingParam.sortOrder,
      partner: activationAudListingParam.partner,
    };

    setBody(newParams);
  }, [activationAudListingParam]);

  const applyFilters = (data: any) => {
    let filterData = {
      end_date: data.dateRange?.length ? data.dateRange[1] : null,
      start_date: data.dateRange?.length ? data.dateRange[0] : null,
      limit: activationAudListingParam.rowsPerPage,
      off_set:
        (activationAudListingParam.pageNumber - 1) *
        activationAudListingParam.rowsPerPage,
      title: data.title,
      order_column: activationAudListingParam.sortColumn,
      order_direction: activationAudListingParam.sortOrder,
      partner: data.partner?.length ? data.partner?.toString() : null,
      brand_id: data.brand?.length ? data.brand.toString() : null,
      agency_id: data.agency?.length ? data.agency.toString() : null,
      advertiser_id: data.advertiser?.length
        ? data.advertiser.toString()
        : null,
      audience_type: data.audienceType?.length
        ? data.audienceType.toString()
        : null,
      filter_option: data.filter_option,
      timeframe: data.timeframe,
    };
    let filterStorage = {
      ...JSON.parse(localStorage.getItem('Filters') || '{}'),
      global: {
        agency: filterData.agency_id,
        advertiser: filterData.advertiser_id,
        brand: filterData.brand_id,
        dateRange:
          filterData.start_date && filterData.end_date
            ? [filterData.start_date, filterData.end_date]
            : null,
        timeframe: filterData.timeframe,
      },
      activation: {
        title: filterData.title,
        filter_option: filterData.filter_option,
        partner: filterData.partner,
      },
    };
    setActivationAudListingParam({
      audienceType: data.audienceType?.length ? data.audienceType : null,
      agency: data.agency?.length ? data.agency : null,
      brand: data.brand?.length ? data.brand : null,
      advertiser: data.advertiser?.length ? data.advertiser : null,
      title: data.title,
      filter_option: data.filter_option,
      rowsPerPage: baseListingParam.rowsPerPage,
      timeframe: data.timeframe ?? null,
      pageNumber: baseListingParam.pageNumber,
      sortColumn: baseListingParam.sortColumn,
      sortOrder: baseListingParam.sortOrder,
      partner: data.partner?.length ? data.partner?.toString() : null,
      dateRange: data.dateRange?.length
        ? [data.dateRange[0], data.dateRange[1]]
        : null,
    });
    setBody(filterData);
    localStorage.setItem('Filters', JSON.stringify(filterStorage));
    setIsFilterModalOpen(false);
  };

  const handleCancel = () => {
    setIsFilterModalOpen(false);
  };

  return (
    <div className="audienceCommonWrap activeAudienceCommonWrap">
      <PageHeader title="Activations" />
      <div className="audienceListingPageContent">
        {!loaderState && (
          <Fragment>
            <div className="filterWrap noRightSideCTA">
            <ConfigProvider wave={{ disabled: true }}>
              <Button onClick={() => setIsFilterModalOpen(true)}>
              <img src={filter} alt="" />
              </Button>
            </ConfigProvider>
              {(activationAudListingParam.agency ||
                activationAudListingParam.advertiser ||
                activationAudListingParam.brand ||
                activationAudListingParam.audienceType ||
                activationAudListingParam.timeframe ||
                activationAudListingParam.dateRange ||
                activationAudListingParam.title ||
                activationAudListingParam.partner) && (
                <FilterMessage
                  type="activation"
                  data={activationAudListingParam}
                />
              )}

              <GlobalFilterModal
                isOpen={isFilterModalOpen}
                filterData={activationAudListingParam}
                type="activation"
                handleOk={applyFilters}
                handleCancel={handleCancel}
              />
            </div>
            <ActiveAudienceListingTable
              activationData={data}
              isLoading={isLoading}
              paginationAndSortingData={activationAudListingParam}
              setPaginationAndSortingData={setActivationAudListingParam}
            />
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default ActiveAudienceListingWrap;
