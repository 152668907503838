import { useEffect, useState } from 'react';
import { IAccountListingParam } from '../../../../models/interfaces';
import { PageHeader } from '../../../sharedComponent';
import { usePostApiInterceptor, useRedirection } from '../../../../hooks';
import { RoutesEnum } from '../../../../models/enums/apiRoutes';
import AccountListingTable from './AccountListingTable';

const baseListingParam = {
  sortOrder: 'DESC',
  sortColumn: 'created_on',
  pageNumber: 1,
  rowsPerPage: 15,
  timeframe: null,
  dateRange: null,
  agency: null,
  brand: null,
  advertiser: null,
  title: '',
  filter_option: 1,
};

type AccountBodyType = {
  end_date: string | null;
  start_date: string | null;
  limit: number;
  off_set: number;
  order_column: string | null;
  order_direction: string | null;
  title: string | null;
  timeframe: string | null;
  brand_id: string | null;
  agency_id: string | null;
  advertiser_id: string | null;
  filter_option: number | null;
};

const AccountWrap = () => {

  const [accountListingParam, setAccountListingParam] =
      useState<IAccountListingParam>(baseListingParam),
    [accountBody, setAccountBody] = useState<AccountBodyType | null>(null),
    [isMounted, setIsMounted] = useState(false);

  const { data, isLoading } = usePostApiInterceptor(
      isMounted,
      accountBody,
      RoutesEnum.GET_ALL_ACCOUNTS,
      () => {
        setIsMounted(false);
      }
    ),
    { redirectAccountSetup } = useRedirection();

  useEffect(() => {
    setIsMounted(true);
  }, [setAccountBody]);

  useEffect(() => {
    setAccountBody({
      agency_id: accountListingParam.agency?.length
        ? accountListingParam.agency.toString()
        : null,
      advertiser_id: accountListingParam.advertiser?.length
        ? accountListingParam.advertiser.toString()
        : null,
      brand_id: accountListingParam.brand?.length
        ? accountListingParam.brand.toString()
        : null,
      end_date: accountListingParam.dateRange?.length
        ? accountListingParam.dateRange[1]
        : null,
      timeframe: accountListingParam.timeframe,
      start_date: accountListingParam.dateRange?.length
        ? accountListingParam.dateRange[0]
        : null,
      title: accountListingParam.title,
      filter_option: accountListingParam.filter_option,
      limit: accountListingParam.rowsPerPage,
      off_set:
        (accountListingParam.pageNumber - 1) * accountListingParam.rowsPerPage,
      order_column: accountListingParam.sortColumn,
      order_direction: accountListingParam.sortOrder,
    });
  }, [accountListingParam]);

  return (
    <div className="audienceCommonWrap">
      <PageHeader title="Accounts" />
      <div className="accountListingContent">
        <div className="aboveTableAction newUserCTA">
          <button
            className="primaryBtn addAudienceBtn"
            onClick={() => redirectAccountSetup()}
          >
            Add Account
          </button>
        </div>
        <AccountListingTable
          accounts={data}
          isLoading={isLoading}
          paginationAndSortingData={accountListingParam}
          setPaginationAndSortingData={setAccountListingParam}
        />
      </div>
    </div>
  );
};

export default AccountWrap;
