import React, { FC, useState } from 'react';
import { helpSettings, logOut, profile } from '../../../assets/images';
import { useAuth } from '../../../hooks';
import { Tooltip } from 'antd';
import { cognitoConfig } from '../../../awsConfig/awsConfig';
import { Amplify, Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { useUserInformationStore } from '../../../stores';
import { IUserInformationState } from '../../../models/interfaces';

Amplify.configure(cognitoConfig);

const LeftPanelProfileMenu: FC = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const { userInformation } = useUserInformationStore(
    (state: IUserInformationState) => state
  );

  const handleLogout = async () => {
    await Auth.signOut();
    logout();
  };

  return (
    <React.Fragment>
      {isVisible && (
        <ul className="leftPanelProfileMenu">
          <li onClick={() => navigate('/profile')}>
            <img src={profile} alt="" />
            <span>Profile</span>
          </li>
          <li
            onClick={() =>
              window.open(
                'https://branchlab.atlassian.net/wiki/spaces/platform/',
                '_blank'
              )
            }
          >
            <img src={helpSettings} alt="" />
            <span>Help</span>
          </li>
          <li onClick={handleLogout}>
            <img src={logOut} alt="" />
            <span>Logout</span>
          </li>
        </ul>
      )}
      {!userInformation.profile_pic?.length || !userInformation.profile_pic ? (
        <Tooltip
          title={`${userInformation.first_name} ${userInformation.last_name}`}
        >
          <span
            className="userSettingsPopup"
            onClick={() => setIsVisible((prev) => !prev)}
          >
            {userInformation.first_name?.split('')[0]}
            {userInformation.last_name?.split('')[0]}
          </span>
        </Tooltip>
      ) : (
        <Tooltip
          title={`${userInformation.first_name} ${userInformation.last_name}`}
        >
          <span
            className="userSettingsPopup"
            onClick={() => setIsVisible((prev) => !prev)}
          >
            <img
              src={`${process.env.REACT_APP_PROFILE_IMAGE_URL}/${process.env.REACT_APP_S3_FOLDER_NAME_DP}/${userInformation.profile_pic}`}
              alt={userInformation.first_name || ''}
            />
          </span>
        </Tooltip>
      )}
    </React.Fragment>
  );
};

export default LeftPanelProfileMenu;
