import { UserOutlined } from '@ant-design/icons';
import { Avatar, Button, GetProp, UploadFile, UploadProps } from 'antd';
import { Upload } from 'antd';
import React, { FC, useState } from 'react';
import { IPresignedURLRequest } from '../../../../models/interfaces';
import apiService from '../../../../services/Api.service';
import axios from 'axios';
import { RoutesEnum } from '../../../../models/enums/apiRoutes';

type UploadReqType = { url?: string; body?: any; isError: boolean };
type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const ProfileAvatar: FC<{
  userInfo: any;
  saveUrl: React.Dispatch<React.SetStateAction<string>>;
  saveUser: React.Dispatch<React.SetStateAction<any>>;
}> = ({ userInfo, saveUser, saveUrl }) => {
  const [displayState, setDisplayState] = useState<any>([]),
    [isLoading, setLoading] = useState(false);

  let uuid = new Date().valueOf();

  const getPresignedURLs = (requests: IPresignedURLRequest[]) => {
    const promises = requests.map((el: IPresignedURLRequest) =>
      apiService.post<any>(process.env.REACT_APP_BASE_URL + el.url, el.body, {}, true)
    );
    Promise.all(promises)
      .then(
        (res) => {
          let tempUploadReq: UploadReqType[] = [];
          res.forEach((r, i) => {
            if (r) {
              const formData = new FormData();
              for (const key in r.presigned_url.fields) {
                formData.append(`${key}`, r.presigned_url.fields[key]);
              }
              formData.append('file', requests[0].file as FileType);

              tempUploadReq = [
                {
                  url: r.presigned_url.url,
                  body: formData,
                  isError: false,
                },
              ];
              saveUrl(r.presigned_url.fields.key.split('user-image/')[1]);
              let user = { ...userInfo };
              user.profile_pic = r.presigned_url.fields.key;
            } else {
              console.error('Status is not 200');
              tempUploadReq = [...tempUploadReq, { isError: true }];
            }
          });
          uploadAllFiles(tempUploadReq);
        },
        (err) => {
          console.error(err.message);
          // setShowBlankLoaderState(false);
        }
      )
      .catch((error: any) => {
        console.error(error.message);
        // setShowBlankLoaderState(false);
      });
  };
  
  const uploadAllFiles = (requests: UploadReqType[]) => {
    const promises = requests.map((el: UploadReqType) => {
      if (!el.isError && el.url && el.body) {
        return axios.post(el.url, el.body, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      } else {
        return null;
      }
    });

    Promise.all(promises)
      .then((res) => {
        setLoading(false);
        console.log('uploading', res);
      })
      .catch((error: any) => {
        console.error('error', error.message);
        // setShowBlankLoaderState(false);
      });
  };

  const onDelete = () => {
    setDisplayState([]);
    let user = { ...userInfo };
    user.profile_pic = null;
    saveUser(user);
  };
  
  return (
    <div className="profileAvatarContentWrap">
      {!userInfo.profile_pic && displayState.length > 0 && (
        <img src={URL.createObjectURL(displayState[0].file)} alt="userimage" />
      )}
      {!userInfo.profile_pic && !displayState.length && (
        <Avatar size={128} icon={<UserOutlined />} />
      )}
      {userInfo.profile_pic && !displayState.length && (
        <img
          src={`${process.env.REACT_APP_PROFILE_IMAGE_URL}/${process.env.REACT_APP_S3_FOLDER_NAME_DP}/${userInfo.profile_pic}`}
          style={{
            width: '100px',
            height: '100px',
            borderRadius: '50%',
          }}
          alt={userInfo.first_name}
        />
      )}
      <p>
        {userInfo.first_name} {userInfo.last_name}
      </p>

      {userInfo.profile_pic || (displayState.length && !isLoading) ? (
        <div className="profileAvatarBtnSec">
          <Upload
            accept=".jpeg,.png,.jpg"
            showUploadList={false}
            beforeUpload={(file) => {
              setDisplayState([]);
              let user = { ...userInfo };
              user.profile_pic = null;
              saveUser(user);
              const timeStamp = new Date().valueOf(),
                fileNameArray = file.name.split('.'),
                newDisplayState = [
                  {
                    key: 1,
                    uuid: uuid,
                    name: `${fileNameArray[0]}_${timeStamp}.${fileNameArray[1]}`,
                    displayName: file.name,
                    file: file,
                  },
                ];
              setDisplayState(newDisplayState);
              let UploadBody = [
                {
                  key: newDisplayState[0].key,
                  url: RoutesEnum.GET_PRESIGNED_URL,
                  body: JSON.stringify({
                    bucket: process.env.REACT_APP_S3_BUCKET_NAME,
                    key: `${process.env.REACT_APP_S3_FOLDER_NAME_DP}/${newDisplayState[0].name}`,
                    expiration: 6000,
                  }),
                  file: newDisplayState[0].file as UploadFile,
                },
              ];
              setLoading(true);
              getPresignedURLs(UploadBody);
              return false;
            }}
          >
            <Button loading={isLoading} className="primaryBtn">
              {isLoading ? ' Uploading' : 'Change Avatar'}
            </Button>
          </Upload>
          <button className="secondaryBtn backBtn" onClick={onDelete}>
            Delete Avatar
          </button>
        </div>
      ) : (
        <div className="profilePicBtnPrimary">
          <Upload
            accept=".jpeg,.png,.jpg"
            showUploadList={false}
            beforeUpload={(file) => {
              const timeStamp = new Date().valueOf(),
                fileNameArray = file.name.split('.'),
                newDisplayState = [
                  {
                    key: 1,
                    uuid: uuid,
                    name: `${fileNameArray[0]}_${timeStamp}.${fileNameArray[1]}`,
                    displayName: file.name,
                    file: file,
                  },
                ];
              setDisplayState(newDisplayState);
              let UploadBody = [
                {
                  key: newDisplayState[0].key,
                  url: RoutesEnum.GET_PRESIGNED_URL,
                  body: JSON.stringify({
                    bucket: process.env.REACT_APP_S3_BUCKET_NAME,
                    key: `${process.env.REACT_APP_S3_FOLDER_NAME_DP}/${newDisplayState[0].name}`,
                    expiration: 6000,
                  }),
                  file: newDisplayState[0].file as UploadFile,
                },
              ];
              setLoading(true);
              getPresignedURLs(UploadBody);
              return false;
            }}
          >
            <Button loading={isLoading} className="primaryBtn">
              {isLoading ? ' Uploading' : 'Upload Avatar'}
            </Button>
          </Upload>
        </div>
      )}
    </div>
  );
};

export default ProfileAvatar;
