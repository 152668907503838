import { create } from 'zustand';
import { ILoaderState } from '../models/interfaces';

export const useLoaderStore = create<ILoaderState>((set) => ({
  loaderState: false,
  setLoaderState: (value: boolean) => set({ loaderState: value }),

  updateLoaderState: false,
  setUpdateLoaderState: (value: boolean) => set({ updateLoaderState: value }),

  showPageLoaderState: false,
  setShowPageLoaderState: (value: boolean) =>
    set({ showPageLoaderState: value }),

  showBlankLoaderState: false,
  setShowBlankLoaderState: (value: boolean) =>
    set({ showBlankLoaderState: value }),

  isMounted : false,
  setIsMounted : (value:  boolean) => set({ isMounted: value }),
}));
