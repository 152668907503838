/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  FC,
  Fragment,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Empty, Select, Spin, Tag, Tooltip } from 'antd';
import { RoutesEnum } from '../../../models/enums/apiRoutes';
import apiService from '../../../services/Api.service';
import {
  DebounceSelectProps,
  IDebounceDropdownElements,
  IEnableSaveState,
  IEsParams,
} from '../../../models/interfaces';
import { useCheckSavingEnabilityStore } from '../../../stores';
import { WarningOutlined } from '@ant-design/icons';

const debounce = <T extends (...args: any[]) => any>(
  func: T,
  delay: number
) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: Parameters<T>) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(this, args), delay);
  };
};

function DebounceSelect<
  ValueType extends {
    key?: string;
    label: React.ReactNode;
    value: string | number;
  } = any
>({
  fetchOptions,
  esKeyParam,
  debounceTimeout = 1000,
  ...props
}: Readonly<DebounceSelectProps<ValueType>>) {
  const [fetching, setFetching] = useState(false),
    [options, setOptions] = useState<ValueType[]>([]),
    [usableOptions, setUsableOptions] = useState<ValueType[]>([]);

  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  useEffect(() => {
    const temp = options.filter(
      (el: any) => !props.value?.find((elem) => elem.value === el.value)
    );

    setUsableOptions(temp);
  }, [props.value, options]);

  return (
    <div className="eachCommonElementWrapper debounceDropdownWrapper">
      <Select
        labelInValue
        // maxTagCount={5}
        filterOption={false}
        onSearch={debounceFetcher}
        notFoundContent={fetching ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        {...props}
        className="multiSelectDropdown disabled-debounce"
        options={usableOptions}
        maxTagPlaceholder={renderTooltip}
        optionRender={({ data }) => renderOptions(data, esKeyParam)}
        tagRender={(data) => renderTag(data, esKeyParam)}
      />
    </div>
  );
}

const DebounceDropdown: FC<{
  placeholder: string;
  esParams: IEsParams;
  onChange: React.Dispatch<React.SetStateAction<IDebounceDropdownElements[]>>;
  defaultValue?: IDebounceDropdownElements[];
  targetingSetNumber?: number;
  featureSetNumber?: number;
  isDisabled?: boolean;
  ifShowValue? : boolean
}> = ({
  placeholder,
  esParams,
  onChange,
  defaultValue = [],
  targetingSetNumber,
  featureSetNumber,
  isDisabled = false,
  ifShowValue = true
}) => {
  const [value, setValue] = useState<IDebounceDropdownElements[]>([]),
    [ifShowError, setIfShowError] = useState<boolean>(false);

  const { isFeatureSetsFilled, updateIisFeatureSetsFilled } =
    useCheckSavingEnabilityStore((state: IEnableSaveState) => state);

  const {
    esIndex,
    esKeyParam,
    esValueParam,
    esMainLabelParam,
    esSubLabelParam1,
    esSubLabelParam2,
    esSearchParam,
  } = esParams;

  const getDropdownOptions = async (
    searchParam: string
  ): Promise<IDebounceDropdownElements[]> => {
    const payload = {
      index: esIndex,
      query: {
        multi_match: {
          query: searchParam,
          fields: esSearchParam,
          type: 'phrase_prefix',
        },
      },
      size : 100,
      sort:
        esIndex === 'icd_10_index'
          ? [
              {
                'id.keyword': {
                  order: 'asc',
                },
              },
              /**{
                'description.keyword': {
                  order: 'asc',
                },
              },**/
            ]
          : null,
    };

    return apiService
      .post<any>(
        process.env.REACT_APP_BASE_URL + RoutesEnum.GET_ES_RESULT,
        payload,
        {},
        true
      )
      .then((res) => {
        if (res) {
          const parsedBody = res,
            uniqueElem = parsedBody.filter(
              (value: any, index: number, self: any) =>
                index ===
                self.findIndex((t: any) => (t[esKeyParam]).toLowerCase() === (value[esKeyParam]).toLowerCase())
            ),
            tempData = uniqueElem.map((el: any) => ({
              value: formatLabel(el, esValueParam, [], [], '~'),
              label: formatLabel(
                el,
                esMainLabelParam,
                esSubLabelParam1,
                esSubLabelParam2,
                ' '
              ),
              ...el,
            }));
          return tempData;
        } else {
          return [];
        }
      })
      .catch((error) => []);
  };

  const onFocus = () => {
    ifShowError &&
      updateIisFeatureSetsFilled(featureSetNumber, targetingSetNumber, 0);
  };

  useEffect(() => {
    if (defaultValue.length > 0) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (
      featureSetNumber &&
      targetingSetNumber &&
      isFeatureSetsFilled[`${featureSetNumber}`][`${targetingSetNumber}`] === 1
    ) {
      setIfShowError(true);
      return;
    }
    setIfShowError(false);
  }, [isFeatureSetsFilled]);

  // useEffect(() => {
  //   if (isDisabled) {
  //     setValue([]);
  //     onChange([]);
  //   }
  // }, [isDisabled]);

  return (
    <DebounceSelect
      mode="multiple"
      placeholder={placeholder}
      value={value}
      fetchOptions={getDropdownOptions}
      onChange={(newValue, option) => {
        let tempValue = [...value];
        if (newValue.length > tempValue.length) {
          const addedOpt = getAddedOrDeletedOpt(option, tempValue);

          tempValue = [...tempValue, addedOpt];
        } else if (newValue.length < tempValue.length) {
          const deletedOpt = getAddedOrDeletedOpt(tempValue, newValue);

          tempValue = tempValue.filter((el) => el.value !== deletedOpt.value);
        }
        setValue(tempValue);
        onChange(tempValue);
      }}
      defaultValue={defaultValue}
      popupClassName="dmaSelectionDDOptionWrap"
      esKeyParam={ifShowValue ? esKeyParam : ""}
      onFocus={onFocus}
      status={ifShowError ? 'error' : ''}
      suffixIcon={
        ifShowError ? <WarningOutlined className="validationErrorIcon" /> : ''
      }
      disabled={isDisabled}
    />
  );
};

export default DebounceDropdown;

const getAddedOrDeletedOpt = (c1: any, c2: any) => {
  const map = new Map(),
    updatedC1 = c1.filter((el: any) => Object.keys(el)?.length > 0);

  c2.forEach((el: any) => map.set(el.value, el));

  return updatedC1.find((el: any) => !map.has(el.value));
};

const formatLabel = (
  data: any,
  mp: string[],
  sp1: string[],
  sp2: string[],
  op: string
) => {
  const mainLabelArray: string[] = [],
    subLabelArray1: string[] = [],
    subLabelArray2: string[] = [];

  mp.forEach((el) => {
    const d = data[el];
    if (d) {
      mainLabelArray.push(d);
    }
  });

  if (sp1?.length) {
    sp1.forEach((el) => {
      const d = data[el];
      if (d) {
        subLabelArray1.push(d);
      }
    });
  }

  if (sp2?.length) {
    sp2.forEach((el) => {
      const d = data[el];
      if (d) {
        subLabelArray2.push(d);
      }
    });
  }

  const mainLabel = mainLabelArray.join(op),
    subLabel1 = subLabelArray1?.length ? '~' + subLabelArray1.join(' ') : '',
    subLabel2 = subLabelArray2?.length
      ? '~(' + subLabelArray2.join(' ') + ')'
      : '';

  return mainLabel + subLabel1 + subLabel2;
};

const renderOptions = (data: any, key: string) => {
  const { label } = data,
    labelArr = label?.split('~');

  return (
    <div title={label?.replaceAll('~', ' ')}>
      <div
        // style={{ paddingLeft: `${(data.level - 1) * 15}px` }}
        className="debounceDDOptionElemWrap"
      >
        {data[key] ? <div>{data[key]}:</div> : <></>}
        <div>&nbsp;{labelArr[0]}</div>
      </div>
    </div>
  );
};

const renderTag: any = (data: any, key: string) => {
  const { label, value, closable, onClose, isMaxTag } = data,
    showingLabel =
      (key ? value?.split('~')[0] + ': ' : '') + String(label)?.split('~')[0];
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return isMaxTag ? (
    <Tag
      className="debounceDDTagWrapper"
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
    >
      {label}
    </Tag>
  ) : (
    <Tooltip
      // title={<TooltipHtml ov={[data]} />}
      overlayStyle={{ maxWidth: '70%' }}
    >
      <Tag
        className="debounceDDTagWrapper"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
      >
        {showingLabel}
      </Tag>
    </Tooltip>
  );
};

const renderTooltip = (omittedValues: any) => (
  <Tooltip
    title={<TooltipHtml ov={omittedValues} />}
    overlayStyle={{ maxWidth: '70%' }}
  >
    <span className="multiSelectTooltip">
      + {omittedValues?.length ?? 0} ...
    </span>
  </Tooltip>
);

const TooltipHtml: FC<{ ov: any }> = ({ ov }) => {
  return (
    <Fragment>
      {ov.map((el: any) => {
        const id = el.value?.split('~')[0];

        return (
          <div key={el.value} className="debounceDDOptionElemWrap">
            <div>{id}:</div>
            <div>{el?.label?.replaceAll('~', ' ')}</div>
          </div>
        );
      })}
    </Fragment>
  );
};
