import { create } from 'zustand';
import {
  IUserInformationState,
  UserInformationType,
} from '../models/interfaces';

export const useUserInformationStore = create<IUserInformationState>((set) => ({
  userInformation: {
    user_id: null,
    email: null,
    timestamp: null,
    account_id: null,
    last_name: null,
    first_name: null,
    profile_pic: null,
    role: null,
    token: null,
    privilege_set : null
  },
  setUserInformation: (value: UserInformationType) =>
    set({ userInformation: value }),

  userIsLoggedIn: false,
  setUserIsLoggedIn: (value: boolean) => set({ userIsLoggedIn: value }),

  privilegeSetDDElements: [],
  setPrivilegeSetDDElements: (value: string[]) =>
    set({ privilegeSetDDElements: value }),
}));
