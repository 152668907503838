import { Dispatch, FC, SetStateAction } from 'react';
import Modal from 'antd/es/modal/Modal';
import {
  IDeleteParams,
  IMasterTargetingDDState,
} from '../../../models/interfaces';
import { useMasterTargetingElementsStore } from '../../../stores';

const ModalComponent: FC<{
  modalHeader: string;
  modalToOpen: boolean;
  setModalToOpen: Dispatch<SetStateAction<boolean>>;
  deleteSetParams?: IDeleteParams;
  extra?: boolean;
  type?: true;
}> = ({
  modalHeader,
  modalToOpen,
  setModalToOpen,
  deleteSetParams,
  extra,
  type,
}) => {
  const { targetingDDElementsForFs, updateTargetingDDElementsForFs } =
    useMasterTargetingElementsStore((state: IMasterTargetingDDState) => state);

  const removeTargetingElement = () => {
    if (deleteSetParams?.handleDelete) {
      deleteSetParams.handleDelete(
        [deleteSetParams.setId, deleteSetParams.fieldName],
        deleteSetParams.index
      );

      if (
        targetingDDElementsForFs?.length &&
        deleteSetParams?.targetingArr?.length
      ) {
        updateTargetingDDElementsForFs(deleteSetParams.targetingArr[0], false);
      }
    }

    if (deleteSetParams?.handelOk) {
      deleteSetParams.handelOk();
    }

    setModalToOpen(false);
  };

  const cancelModal = () => {
    if (deleteSetParams?.handleCancel) {
      deleteSetParams.handleCancel();
    }

    setModalToOpen(false);
  };

  return (
    <Modal
      centered
      open={modalToOpen}
      onOk={removeTargetingElement}
      onCancel={cancelModal}
      okText={deleteSetParams?.okText ? deleteSetParams.okText : 'Delete'}
      cancelText={
        deleteSetParams?.cancelText ? deleteSetParams.cancelText : 'Cancel'
      }
      className="alertSpecificClass"
      width={'330px'}
    >
      <div className="modalGenericContent">
        {/* <img src={modalDeleteIcon} alt="" /> */}
        <div className="modalGenericTextContent">
          <p>{modalHeader}</p>
          {deleteSetParams?.message ? (
            <p>{deleteSetParams.message}</p>
          ) : (
            <p>{`Are you sure you want to ${extra ? `` : 'delete'} ${
              deleteSetParams?.name
            }?`}</p>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ModalComponent;
