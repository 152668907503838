/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, Fragment, useEffect, useState } from 'react';
import { ModalComponent } from '../../../../components/sharedComponent';
import {
  useCheckDependentTargetingSelectedStore,
  useCheckIfDataModifiedStore,
  useCheckSavingEnabilityStore,
  useFeatureSetDataCheckStore,
  useFeatureSetDataHandlingStore,
  useLoaderStore,
  useMasterAdditionalOptionElementsStore,
  useMasterDmaElementsStore,
  useMasterInsurancePlanProviderElementsStore,
  useMasterInsurancePlanTypeElementsStore,
  useMasterOperatorElementsStore,
  useMasterProviderSpecialtyElementsStore,
  useMasterProviderTaxonomyElementStore,
  useMasterStateElementsStore,
  useMasterTargetingElementsStore,
} from '../../../../stores';
import {
  IAudienceSaveMethods,
  IAudienceState,
  ICheckIfDataModifiedState,
  ICustomNPIList,
  IDependentTargetingSelectedState,
  IEachDropdownElementsType,
  IEnableSaveState,
  IFeatureSetBaseData,
  IFeatureSetDataCheckState,
  IFeatureSetDataState,
  IFeatureSetElement,
  IFeatureSetParamDatum,
  ILoaderState,
  IMasterAdditionalOptionDDState,
  IMasterDmaDDState,
  IMasterInsurancePlanProviderDDState,
  IMasterInsurancePlanTypeDDState,
  IMasterOperatorDDState,
  IMasterProviderSpecialtyDDState,
  IMasterProviderTaxonomyDDState,
  IMasterStateDDState,
  IMasterTargetingDDState,
  IPresignedURLRequest,
} from '../../../../models/interfaces';
import { featureSetList } from './featureSetList';
import {
  Button,
  Dropdown,
  GetProp,
  MenuProps,
  Select,
  UploadFile,
  UploadProps,
} from 'antd';
import { allSelectionOnlyElement } from '../../../../demoData/baseData';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { RoutesEnum } from '../../../../models/enums/apiRoutes';
import {
  useAudienceSaveMethods,
  useAudienceSets,
} from '../../../../stores/audienceSets.store';
import apiService from '../../../../services/Api.service';
import axios from 'axios';
import { dragDrop, more } from '../../../../assets/images';

const baseValues: IFeatureSetBaseData = {
  selectedProviderSpecialty: [], // All
  customNPIList: [],
  genericDrug: [],
  selectedHospitals: [], // All
  selectedDiagnosis: [], // All
  selectedDrugs: [], // All
  selectedProcedures: [], // All
  selectedInsurancePlanType: null,
  selectedInsuranceProviders: [], // All
  selectedAdditionalOpts: [],
  additionalInfo: '',
  selectedProviderTaxonomy: [],
};

const items: MenuProps['items'] = [
  {
    key: '1',
    label: 'Delete',
  },
  // {
  //   key: '2',
  //   label: 'Duplicate',
  // },
];

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];
type UploadReqType = { url?: string; body?: any; isError: boolean };

const DefinedFeatureSet: FC<{
  featureSetNumber: number;
  featureSetOrderNumber: number;
  featureSetCount: number;
  dragHandleProps?: DraggableProvidedDragHandleProps | null | undefined;
  selectedAudienceType: number | null;
  disabled?: boolean;
  generateMessage: () => void;
  setDeletingSetId?: React.Dispatch<React.SetStateAction<number[] | null>>;
}> = ({
  featureSetNumber,
  featureSetOrderNumber,
  featureSetCount,
  dragHandleProps,
  generateMessage,
  disabled = false,
  selectedAudienceType,
  setDeletingSetId,
}) => {
  const { targetingDDElementsForFs, updateTargetingDDElementsForFs } =
      useMasterTargetingElementsStore(
        (state: IMasterTargetingDDState) => state
      ),
    { updateIisFeatureSetsFilled } = useCheckSavingEnabilityStore(
      (state: IEnableSaveState) => state
    ),
    { stateDDElements } = useMasterStateElementsStore(
      (state: IMasterStateDDState) => state
    ),
    { additionalOptionDDElements } = useMasterAdditionalOptionElementsStore(
      (state: IMasterAdditionalOptionDDState) => state
    ),
    { operatorDDElements } = useMasterOperatorElementsStore(
      (state: IMasterOperatorDDState) => state
    ),
    { insurancePlanTypeDDElements } = useMasterInsurancePlanTypeElementsStore(
      (state: IMasterInsurancePlanTypeDDState) => state
    ),
    { insurancePlanProviderDDElements } =
      useMasterInsurancePlanProviderElementsStore(
        (state: IMasterInsurancePlanProviderDDState) => state
      ),
    { ifUpdated } = useFeatureSetDataCheckStore(
      (state: IFeatureSetDataCheckState) => state
    ),
    { setIfDataModified } = useCheckIfDataModifiedStore(
      (state: ICheckIfDataModifiedState) => state
    ),
    { isEnableSaveForEachSet, updateIsEnableSaveForEachSet } =
      useCheckSavingEnabilityStore((state: IEnableSaveState) => state),
    { updateSavingDataSet, updatedDataSet } = useFeatureSetDataHandlingStore(
      (state: IFeatureSetDataState) => state
    ),
    { updateDmaDDElements } = useMasterDmaElementsStore(
      (state: IMasterDmaDDState) => state
    ),
    { updateIsDependentTargetingSelectedForEachSet } =
      useCheckDependentTargetingSelectedStore(
        (state: IDependentTargetingSelectedState) => state
      ),
    { isSaveInitiated, setIsSaveInitiated } = useAudienceSaveMethods(
      (state: IAudienceSaveMethods) => state
    ),
    {
      setDeletedIds,
      deletedIds,
      setIsDuplicated,
      setCopyFeatureSetData,
      copyFeatureSetData,
      setTempFeatureSetData,
      tempFeatureSet,
      setTempAddTargetting,
      tempAddTargetting,
      updateGlobalFeature,
      setIsCancelled,
      globalFeatureTargetting,
      isCancelled,
      isBacked,
      firstFeatureNumber,
      setFeatureSetDataForMessagePrev,
      featureSetDataForMessage,
      setFeatureSetDataForMessage,
      setGlobalFeatureTargetting,
    } = useAudienceSets((state: IAudienceState) => state),
    { providerSpecialtyDDElements } = useMasterProviderSpecialtyElementsStore(
      (state: IMasterProviderSpecialtyDDState) => state
    ),
    { setUpdateLoaderState, setShowBlankLoaderState } = useLoaderStore(
      (state: ILoaderState) => state
    ),
    { providerTaxonomyDDElements } = useMasterProviderTaxonomyElementStore(
      (state: IMasterProviderTaxonomyDDState) => state
    );

  const [addedTargeting, setAddedTargeting] = useState<number[]>([]),
    [featureSetValues, setFeatureSetValues] = useState<IFeatureSetBaseData>(
      tempFeatureSet ?? baseValues
    ),
    [featureSetBody, setFeatureSetBody] = useState<IFeatureSetElement[]>([]),
    [uuid, setUuid] = useState<any>(new Date().valueOf()),
    [customNPIList, setCustomNPIList] = useState<ICustomNPIList[]>(
      baseValues.customNPIList
    ),
    [ifUpdatedCompleted, setIfUpdatedCompleted] = useState<0 | 1 | 2>(0),
    [modalToOpen, setModalToOpen] = useState(false);

  const getHelper = () => [
    {
      key: 'selectedProviderSpecialty',
      savingId: 6,
      updatingId: 6,
      msgParams: {
        md: providerSpecialtyDDElements,
        tol: 2,
      },
    },
    {
      key: 'customNPIList',
      savingId: 7,
      updatingId: 7,
      msgParams: {
        tol: 0,
      },
    },
    {
      key: 'selectedDiagnosis',
      savingId: 9,
      updatingId: 9,
      msgParams: {
        tol: 0,
      },
    },
    {
      key: 'selectedDrugs',
      savingId: 10,
      updatingId: 10,
      msgParams: {
        tol: 0,
      },
    },
    {
      key: 'selectedProcedures',
      savingId: 11,
      updatingId: 11,
      msgParams: {
        tol: 0,
      },
    },
    {
      key: 'selectedInsurancePlanType',
      savingId: 12,
      updatingId: 12,
      msgParams: {
        md: insurancePlanTypeDDElements,
        tol: 1,
      },
    },
    {
      key: 'selectedInsuranceProviders',
      savingId: 16,
      updatingId: 12,
      msgParams: {
        md: insurancePlanProviderDDElements,
        tol: 2,
      },
    },
    {
      key: 'selectedAdditionalOpts',
      savingId: 13,
      updatingId: 13,
      msgParams: {
        md: additionalOptionDDElements,
        tol: 2,
      },
    },
    {
      key: 'additionalInfo',
      savingId: 14,
      updatingId: 14,
      msgParams: {
        tol: 0,
      },
    },
  ];

  useEffect(() => {
    return () => {
      setFeatureSetBody([]);
      setFeatureSetValues(baseValues);
      setAddedTargeting([]);
    };
  }, []);

  useEffect(() => {
    if (globalFeatureTargetting && featureSetNumber) {
      setAddedTargeting(globalFeatureTargetting[featureSetNumber].targeting);
      setFeatureSetValues(
        globalFeatureTargetting[featureSetNumber].featureSetValues
      );
      setSelectedOperator(globalFeatureTargetting[featureSetNumber].operator);
      setGlobalFeatureTargetting(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isBacked) {
      updateGlobalFeature(
        featureSetNumber,
        addedTargeting,
        featureSetValues,
        selectedOperator
      );
    }
  }, [isBacked]);

  useEffect(() => {
    if (isCancelled) {
      setFeatureSetBody([]);
      setAddedTargeting([]);
      setFeatureSetValues(baseValues);
      setIsCancelled(false);
    }
  }, [isCancelled]);

  const [selectedOperator, setSelectedOperator] = useState<number | string>(
    operatorDDElements?.find((el: IEachDropdownElementsType) => el?.isDefault)
      ?.value ?? 5
  );

  const handleTargetableOptions = (value: any) => {
    setAddedTargeting((prev) => [...prev, value]);
    updateTargetingDDElementsForFs(value, true);
  };

  const handleOperator = (e: React.MouseEvent<HTMLButtonElement>) => {
    const target = e.target as HTMLButtonElement;
    const id = target.id;
    setSelectedOperator(parseInt(id));
  };

  const checkSaveEnabled = () => {
    switch (true) {
      case !addedTargeting?.length:
        return 0;
      case addedTargeting.includes(6) &&
        !featureSetValues.selectedProviderSpecialty?.length:
      case addedTargeting.includes(7) && !customNPIList?.length:
      case addedTargeting.includes(8) &&
        !featureSetValues.selectedHospitals?.length:
      case addedTargeting.includes(9) &&
        !featureSetValues.selectedDiagnosis?.length:
      case addedTargeting.includes(10) &&
        !featureSetValues.selectedDrugs?.length:
      case addedTargeting.includes(11) &&
        !featureSetValues.selectedProcedures?.length:
      case addedTargeting.includes(18) && !featureSetValues.genericDrug.length:
      case addedTargeting.includes(20) &&
        !featureSetValues?.selectedProviderTaxonomy?.length:
      case addedTargeting.includes(12) &&
        (!featureSetValues.selectedInsurancePlanType ||
          (featureSetValues.selectedInsurancePlanType &&
            featureSetValues.selectedInsurancePlanType !== -1 &&
            !featureSetValues.selectedInsuranceProviders?.length)):
        return 1;
      default:
        return 2;
    }
  };

  useEffect(() => {
    updateIsEnableSaveForEachSet(featureSetNumber, checkSaveEnabled());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedTargeting, featureSetValues]);

  const handleFeatureSetUpdates = (key: string, data: any) => {
    if (data) {
      if (Array.isArray(data)) {
        setFeatureSetValues((prev: any) => ({
          ...prev,
          [key]: [...data],
        }));
      } else {
        setFeatureSetValues((prev: any) => ({
          ...prev,
          [key]: data,
        }));
      }
    }
  };

  useEffect(() => {
    updateDmaDDElements(featureSetNumber, [], stateDDElements);
  }, []);

  useEffect(() => {
    performValidations();
  }, []);

  const validationInsuranceProvider = () => {
    if (
      addedTargeting.includes(12) &&
      featureSetValues.selectedInsurancePlanType &&
      featureSetValues.selectedInsurancePlanType !== -1 &&
      featureSetValues.selectedInsuranceProviders
    ) {
      updateIisFeatureSetsFilled(
        featureSetNumber,
        16,
        !featureSetValues.selectedInsuranceProviders?.length ? 1 : 2
      );
      if (!featureSetValues.selectedInsuranceProviders?.length) {
        setIsSaveInitiated(false);
      }
      return;
    }

    updateIisFeatureSetsFilled(featureSetNumber, 16, 0);
  };

  const performValidations = () => {
    validationForArray(6, featureSetValues.selectedProviderSpecialty);
    validationForArray(7, customNPIList);
    validationForArray(8, featureSetValues.selectedHospitals);
    validationForArray(9, featureSetValues.selectedDiagnosis);
    validationForArray(10, featureSetValues.selectedDrugs);
    validationForArray(11, featureSetValues.selectedProcedures);
    validationForNonArray(12, featureSetValues.selectedInsurancePlanType);
    validationInsuranceProvider();
    validationForArray(18, featureSetValues.genericDrug);
    validationForArray(20, featureSetValues.selectedProviderTaxonomy);
  };

  const validationForNonArray = (tId: number, data: any) => {
    if (addedTargeting.includes(tId)) {
      updateIisFeatureSetsFilled(featureSetNumber, tId, !data ? 1 : 2);
      if (!data) {
        setIsSaveInitiated(false);
      }
      return;
    }
    updateIisFeatureSetsFilled(featureSetNumber, tId, 0);
  };

  const validationForArray = (tId: number, data: any) => {
    if (addedTargeting.includes(tId)) {
      updateIisFeatureSetsFilled(featureSetNumber, tId, !data?.length ? 1 : 2);
      if (!data.length) {
        setIsSaveInitiated(false);
      }
      return;
    }
    updateIisFeatureSetsFilled(featureSetNumber, tId, 0);
  };

  const handleSetDelete = (setIds: any[]) => {
    const newTargetingElemArray = addedTargeting.filter(
      (el) => !(setIds[0] === (el as number))
    );
    if (addedTargeting.find((el) => setIds[0] === (el as number))) {
      setDeletedIds(setIds[0]);
    }
    handleFeatureSetUpdates(setIds[1], null);
    setAddedTargeting(newTargetingElemArray);
    setFeatureSetValues(baseValues);

    let tempFeatureMsg: any = { ...featureSetDataForMessage };
    delete tempFeatureMsg[setIds[1]];
    setFeatureSetDataForMessage(tempFeatureMsg);

    updateTargetingDDElementsForFs(setIds[0], false);
  };

  const getParams = () => {
    const params: IFeatureSetParamDatum[] = [
      {
        value: 6,
        values: [featureSetValues.selectedProviderSpecialty],
        names: ['selectedProviderSpecialty'],
        handleFunctions: [],
        base: [baseValues.selectedProviderSpecialty],
        param: {
          setId: 6,
          featureSetNumber,
          label: 'Provider Speciality',
          isMandatory: false,
          options: providerSpecialtyDDElements,
          placeholder: 'Select Provider Specialites',
          isSearchable: true,
          onChange: (data: any) =>
            handleFeatureSetUpdates('selectedProviderSpecialty', data),
          handleSetDelete,
          otherProperties: {
            defaultValue: featureSetValues.selectedProviderSpecialty,
          },
          isDisabled: disabled,
        },
      },
      {
        value: 7,
        values: [featureSetValues.customNPIList],
        names: ['customNPIList'],
        handleFunctions: [],
        base: [baseValues.customNPIList],
        param: {
          setId: 7,
          featureSetNumber,
          label: 'Custom NPI List',
          isMandatory: false,
          uuid: uuid,
          customNPIList: customNPIList,
          setCustomNPIList: setCustomNPIList,
          handleSetDelete,
          isDisabled: disabled,
        },
      },
      {
        value: 8,
        values: [featureSetValues.selectedHospitals],
        names: ['selectedHospitals'],
        handleFunctions: [],
        base: [baseValues.selectedHospitals],
        param: {
          setId: 8,
          featureSetNumber,
          label: 'Hospital',
          isMandatory: false,
          options: allSelectionOnlyElement,
          placeholder: 'Select Hospitals',
          isSearchable: true,
          onChange: (data: any) =>
            handleFeatureSetUpdates('selectedHospitals', data),
          handleSetDelete,
          isDisabled: disabled,
          otherProperties: { defaultValue: featureSetValues.selectedHospitals },
        },
      },
      {
        value: 9,
        values: [featureSetValues.selectedDiagnosis],
        names: ['selectedDiagnosis'],
        handleFunctions: [],
        base: [baseValues.selectedDiagnosis],
        param: {
          setId: 9,
          featureSetNumber,
          label: 'Diagnosis',
          isMandatory: false,
          placeholder: 'Select Diagnosis',
          esParams: JSON.parse(
            process.env?.REACT_APP_ES_DIAGNOSIS_PARAMS ?? '{}'
          ),
          value: featureSetValues.selectedDiagnosis,
          onChange: (data: any) =>
            handleFeatureSetUpdates('selectedDiagnosis', data),
          handleSetDelete,
          isDisabled: disabled,
        },
      },
      {
        value: 10,
        values: [featureSetValues.selectedDrugs],
        names: ['selectedDrugs'],
        handleFunctions: [],
        base: [baseValues.selectedDrugs],
        param: {
          setId: 10,
          featureSetNumber,
          label: 'Drug',
          isMandatory: false,
          placeholder: 'Select Drugs',
          esParams: JSON.parse(process.env?.REACT_APP_ES_DRUG_PARAMS ?? '{}'),
          value: featureSetValues.selectedDrugs,
          onChange: (data: any) =>
            handleFeatureSetUpdates('selectedDrugs', data),
          handleSetDelete,
          isDisabled: disabled,
        },
      },
      {
        value: 11,
        values: [featureSetValues.selectedProcedures],
        names: ['selectedProcedures'],
        handleFunctions: [],
        base: [baseValues.selectedProcedures],
        param: {
          setId: 11,
          featureSetNumber,
          label: 'Procedure',
          isMandatory: false,
          options: allSelectionOnlyElement,
          placeholder: 'Select Procedures',
          esParams: JSON.parse(
            process.env?.REACT_APP_ES_PROCEDURE_PARAMS ?? '{}'
          ),
          value: featureSetValues.selectedProcedures,
          onChange: (data: any) =>
            handleFeatureSetUpdates('selectedProcedures', data),
          handleSetDelete,
          isDisabled: disabled,
        },
      },
      {
        value: 12,
        values: [
          featureSetValues.selectedInsurancePlanType,
          featureSetValues.selectedInsuranceProviders,
        ],
        names: ['selectedInsurancePlanType', 'selectedInsuranceProviders'],
        handleFunctions: [],
        base: [
          baseValues.selectedInsurancePlanType,
          baseValues.selectedInsuranceProviders,
        ],
        param: {
          setId: 12,
          featureSetNumber,
          selectedPlanType: featureSetValues.selectedInsurancePlanType,
          selectedProviders: featureSetValues.selectedInsuranceProviders,
          setSelectedPlanType: (data: any) =>
            handleFeatureSetUpdates('selectedInsurancePlanType', data),
          setSelectedProviders: (data: any) =>
            handleFeatureSetUpdates('selectedInsuranceProviders', data),
          handleSetDelete,
          isDisabled: disabled,
        },
      },
      {
        value: 13,
        values: [featureSetValues.selectedAdditionalOpts],
        names: ['selectedAdditionalOpts'],
        handleFunctions: [],
        base: [baseValues.selectedAdditionalOpts],
        param: {
          setId: 13,
          featureSetNumber,
          label: 'Additional Options',
          isMandatory: false,
          options: additionalOptionDDElements,
          placeholder: 'Select Additional Options',
          isSearchable: true,
          onChange: (data: any) =>
            handleFeatureSetUpdates('selectedAdditionalOpts', data),
          handleSetDelete,
          otherProperties: {
            defaultValue: featureSetValues.selectedAdditionalOpts,
          },
          isDisabled: disabled,
        },
      },
      {
        value: 14,
        values: [featureSetValues.additionalInfo],
        names: ['additionalInfo'],
        handleFunctions: [],
        base: [baseValues.additionalInfo],
        param: {
          setId: 14,
          featureSetNumber,
          label: 'Additional Information',
          fieldType: 'textarea',
          inputPlaceholder: 'Additional Information',
          additionalInfo: featureSetValues.additionalInfo,
          onChange: (data: any) =>
            handleFeatureSetUpdates('additionalInfo', data),
          handleSetDelete,
          props: { autoSize: { minRows: 1, maxRows: 1 } },
          isDisabled: disabled,
        },
      },
      {
        value: 18,
        values: [featureSetValues.genericDrug],
        names: ['genericDrug'],
        handleFunctions: [],
        base: [baseValues.genericDrug],
        param: {
          setId: 18,
          featureSetNumber,
          label: 'Generic Drug',
          isMandatory: false,
          placeholder: 'Select Drugs',
          esParams: JSON.parse(
            process.env?.REACT_APP_ES_DRUG_GENERIC_PARAMS ?? '{}'
          ),
          isSearchable: true,
          value: featureSetValues.genericDrug,
          onChange: (data: any) => {
            handleFeatureSetUpdates('genericDrug', data);
          },
          handleSetDelete,
          isDisabled: disabled,
        },
      },
      {
        value: 20,
        values: [featureSetValues.selectedProviderTaxonomy],
        names: ['selectedProviderTaxonomy'],
        handleFunctions: [],
        base: [baseValues.selectedProviderTaxonomy],
        param: {
          setId: 20,
          featureSetNumber,
          label: 'Provider Taxonomy',
          isMandatory: false,
          options: providerTaxonomyDDElements,
          placeholder: 'Select Provider Taxonomy',
          isSearchable: true,
          onChange: (data: any) =>
            handleFeatureSetUpdates('selectedProviderTaxonomy', data),
          handleSetDelete,
          otherProperties: {
            defaultValue: featureSetValues.selectedProviderTaxonomy,
          },
          isDisabled: disabled,
        },
      },
    ];
    return params;
  };

  useEffect(() => {
    if (addedTargeting.includes(6)) {
      setFeatureSetDataForMessage({
        ...featureSetDataForMessage,
        selectedProviderSpecialty: formatDataToCreateMessage({
          data: featureSetValues.selectedProviderSpecialty,
          md: providerSpecialtyDDElements,
          tol: 0,
        }),
      });
    }
  }, [featureSetValues.selectedProviderSpecialty]);

  useEffect(() => {
    if (addedTargeting.includes(20)) {
      setFeatureSetDataForMessage({
        ...featureSetDataForMessage,
        selectedProviderTaxonomy: formatDataToCreateMessage({
          data: featureSetValues.selectedProviderTaxonomy,
          md: providerTaxonomyDDElements,
          tol: 0,
        }),
      });
    }
  }, [featureSetValues.selectedProviderTaxonomy]);

  useEffect(() => {
    if (addedTargeting.includes(7)) {
      const usableMessageData = featureSetValues.customNPIList.map(
        (el) => el.displayName
      );
      setFeatureSetDataForMessage({
        ...featureSetDataForMessage,
        customNPIList: formatDataToCreateMessage({
          data: usableMessageData,
          tol: 0,
        }),
      });
    }
  }, [featureSetValues.customNPIList]);

  useEffect(() => {
    if (addedTargeting.includes(6)) {
      const usableDiagnosisForMessage = featureSetValues.selectedDiagnosis.map(
        (el) => el.label
      );
      setFeatureSetDataForMessage({
        ...featureSetDataForMessage,
        selectedDiagnosis: formatDataToCreateMessage({
          data: usableDiagnosisForMessage,
          tol: 0,
        }),
      });
    }
  }, [featureSetValues.selectedDiagnosis]);

  useEffect(() => {
    if (addedTargeting.includes(10)) {
      const usableDrugForMessage = featureSetValues.selectedDrugs.map((el) =>
        el?.label?.replaceAll('~', ' ')
      );

      setFeatureSetDataForMessage({
        ...featureSetDataForMessage,
        selectedDrugs: formatDataToCreateMessage({
          data: usableDrugForMessage,
          tol: 0,
        }),
      });
    }
  }, [featureSetValues.selectedDrugs]);

  useEffect(() => {
    if (addedTargeting.includes(11)) {
      const usableProcedureForMessage = featureSetValues.selectedProcedures.map(
        (el) => el.label
      );
      setFeatureSetDataForMessage({
        ...featureSetDataForMessage,
        selectedProcedures: formatDataToCreateMessage({
          data: usableProcedureForMessage,
          tol: 0,
        }),
      });
    }
  }, [featureSetValues.selectedProcedures]);

  useEffect(() => {
    if (addedTargeting.includes(12)) {
      setFeatureSetDataForMessage({
        ...featureSetDataForMessage,
        selectedInsurancePlanType: formatDataToCreateMessage({
          data: featureSetValues.selectedInsurancePlanType,
          md: insurancePlanTypeDDElements,
          tol: 1,
        }),
        selectedInsuranceProviders: formatDataToCreateMessage({
          data: featureSetValues.selectedInsuranceProviders,
          md: insurancePlanProviderDDElements,
          tol: 2,
        }),
      });
    }
  }, [
    featureSetValues.selectedInsurancePlanType,
    featureSetValues.selectedInsuranceProviders,
  ]);

  useEffect(() => {
    if (addedTargeting.includes(13)) {
      setFeatureSetDataForMessage({
        ...featureSetDataForMessage,
        selectedAdditionalOpts: formatDataToCreateMessage({
          data: featureSetValues.selectedAdditionalOpts,
          md: additionalOptionDDElements,
          tol: 2,
        }),
      });
    }
  }, [featureSetValues.selectedAdditionalOpts]);

  useEffect(() => {
    if (addedTargeting?.length) {
      const updatedElem: IFeatureSetElement[] = [],
        params = getParams();

      addedTargeting.forEach((el: number | string, index: number) => {
        updatedElem.push({
          setId: el as number,
          orderId: index,
          element: featureSetList
            .find((elem) => elem.value === (el as number))
            ?.component(
              params.find((elem) => elem.value === (el as number))?.param
            ),
        });
      });

      setFeatureSetBody(updatedElem);
    }
  }, [addedTargeting]);

  // Custom NPI List
  useEffect(() => {
    if (
      (ifUpdatedCompleted === 0 || (ifUpdated && ifUpdatedCompleted === 2)) &&
      addedTargeting.includes(7)
    ) {
      setIfDataModified(true);
      const usableData: ICustomNPIList[] = customNPIList.map((el) => ({
        key: el.key,
        displayName: el.displayName,
        name: el.name,
        uuid: el.uuid,
      }));

      setFeatureSetValues({ ...featureSetValues, customNPIList: usableData });
    }
  }, [customNPIList]);

  useEffect(() => {
    if (ifUpdated && addedTargeting && featureSetValues) {
      setIfDataModified(true);
    }
  }, [addedTargeting]);

  //Operator
  // useEffect(() => {
  //   if (
  //     (ifUpdatedCompleted === 0 || (ifUpdated && ifUpdatedCompleted === 2)) &&
  //     featureSetCount <= featureSetOrderNumber &&
  //     Object.keys(featureSetData).includes('selectedOperator')
  //   ) {
  //     delete featureSetData.selectedOperator;
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [featureSetCount, selectedOperator]);

  const getFormattedData = () => {
    const tempData: any = {
        ...featureSetValues,
      },
      allUpdatingFeatureSetIds = updatedDataSet?.feature_sets?.map(
        (el: any) => el.feature_set_id
      );
    let allUpdatingTargetingIds: any = {};
    updatedDataSet?.feature_sets?.forEach((el: any) => {
      if (el.feature_set_id === featureSetNumber) {
        el.targeting.forEach(
          (elem: any) =>
            (allUpdatingTargetingIds = {
              ...allUpdatingTargetingIds,
              [elem.targeting_id]: elem.targeting_set_id,
            })
        );
      }
    });

    let newFeatureSetData: any = {
        feature_set_id: allUpdatingFeatureSetIds?.includes(featureSetNumber)
          ? featureSetNumber
          : 0,
        feature_set_rank: featureSetOrderNumber,
        condition_id:
          featureSetOrderNumber !== firstFeatureNumber ? selectedOperator : 0,
        is_global: false,
      },
      targeting = [],
      duals: any = {
        selectedInsuranceProviders: 16,
      };
    for (const key in featureSetValues) {
      if (
        !(
          !tempData[key] ||
          (Array.isArray(tempData[key]) && tempData[key].length < 1)
        )
      ) {
        const params = getParams(),
          targetingId = params.find((el: IFeatureSetParamDatum) =>
            el.names.includes(key as any)
          )?.value,
          usableTargetingId = Object.keys(duals).includes(key)
            ? duals[key]
            : targetingId,
          usableTargetingSetId = Object.keys(allUpdatingTargetingIds).includes(
            `${usableTargetingId}`
          )
            ? allUpdatingTargetingIds[`${usableTargetingId}`]
            : 0;

        targeting.push({
          targeting_set_id: usableTargetingSetId,
          targeting_id: usableTargetingId,
          targeting_value: tempData[key],
          targeting_set_rank: addedTargeting.indexOf(targetingId as number) + 1,
        });
      }
    }

    if (isSaveInitiated && Object.keys(newFeatureSetData)?.length) {
      updateSavingDataSet(featureSetNumber, {
        ...newFeatureSetData,
        targeting,
      });
    }
  };

  useEffect(() => {
    if (isSaveInitiated) {
      const isEnableValues = Object.values(isEnableSaveForEachSet);
      performValidations();
      if (isEnableValues.includes(1)) {
        setIsSaveInitiated(false);
        return;
      }
      if (addedTargeting?.length) {
        const uploadableNPIs = customNPIList.filter(
          (el: ICustomNPIList) => el.file
        );

        if (uploadableNPIs?.length) {
          const tempPresignedUrlReq: any = uploadableNPIs.map(
            (el: ICustomNPIList) => ({
              key: el.key,
              url: RoutesEnum.GET_PRESIGNED_URL,
              body: {
                bucket: process.env.REACT_APP_S3_BUCKET_NAME,
                key: `${process.env.REACT_APP_S3_FOLDER_NAME}/${el.uuid}/${el.name}`,
                expiration: 6000,
              },
              file: el.file as UploadFile,
            })
          );
          getPresignedURLs(tempPresignedUrlReq);
          return;
        }
        // generateMessage()
        getFormattedData();
      } else {
        updateSavingDataSet(featureSetNumber, {});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaveInitiated]);

  const uploadAllFiles = (requests: UploadReqType[]) => {
    const promises = requests.map((el: UploadReqType) => {
      if (!el.isError && el.url && el.body) {
        return axios.post(el.url, el.body, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      } else {
        return null;
      }
    });

    Promise.all(promises)
      .then((res) => console.log('uploading'))
      .catch((error: any) => {
        console.error(error.message);
        setShowBlankLoaderState(false);
      });
  };

  const getPresignedURLs = (requests: IPresignedURLRequest[]) => {
    const promises = requests.map((el: IPresignedURLRequest) =>
      apiService.post<any>(process.env.REACT_APP_BASE_URL + el.url, el.body, {} , true)
    );

    Promise.all(promises)
      .then(
        (res) => {
          let tempUploadReq: UploadReqType[] = [];
          res.forEach((r, i) => {
            if (r) {
              const formData = new FormData();

              for (const key in r.presigned_url.fields) {
                formData.append(`${key}`, r.presigned_url.fields[key]);
              }
              formData.append('file', customNPIList[i].file as FileType);

              tempUploadReq = [
                ...tempUploadReq,
                {
                  url: r.presigned_url.url,
                  body: formData,
                  isError: false,
                },
              ];
            } else {
              console.error('Status is not 200');
              tempUploadReq = [...tempUploadReq, { isError: true }];
            }
          });
          uploadAllFiles(tempUploadReq);
        },
        (err) => {
          console.error(err.message);
          setShowBlankLoaderState(false);
        }
      )
      .catch((error: any) => {
        console.error(error.message);
        setShowBlankLoaderState(false);
      });
  };

  useEffect(() => {
    if (deletedIds?.length) {
      setDeletedIds(null);
      const params = getParams(),
        selectedParam: IFeatureSetParamDatum[] = params.filter((el: any) =>
          deletedIds?.includes(el.value)
        ),
        // tempFeatureSetData = { ...featureSetData },
        dependentTargets = [6, 7, 8];

      selectedParam?.length &&
        selectedParam.forEach((eachParam: IFeatureSetParamDatum) => {
          if (dependentTargets.includes(eachParam.value)) {
            updateIsDependentTargetingSelectedForEachSet(
              featureSetNumber,
              false
            );
          }

          eachParam.handleFunctions.forEach((el: any, index: number) => {
            el(eachParam.base[index]);
          });
        });
    }

    if (addedTargeting?.length) {
      const params = getParams(),
        updatedElem: IFeatureSetElement[] = [],
        dependentTargets = [6, 7, 8];

      updateIsDependentTargetingSelectedForEachSet(
        featureSetNumber,
        Boolean(
          addedTargeting.find((el) => dependentTargets.includes(el as number))
        )
      );

      addedTargeting.forEach((el: number | string, index: number) => {
        updatedElem.push({
          setId: el as number,
          orderId: index,
          element: featureSetList
            .find((elem) => elem.value === (el as number))
            ?.component(
              params.find((elem) => elem.value === (el as number))?.param
            ),
        });
      });
      setFeatureSetBody(updatedElem);
    } else {
      setFeatureSetBody([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedTargeting]);

  useEffect(() => {
    if (copyFeatureSetData && tempFeatureSet) {
      setAddedTargeting(tempAddTargetting);
      setTempAddTargetting([]);
      setCopyFeatureSetData([]);
      setTempFeatureSetData({});
    }
  }, [featureSetNumber]);

  const onClick: MenuProps['onClick'] = ({ key }) => {
    if (key === '1') {
      setModalToOpen(true);
    } else if (key === '2') {
      setIsDuplicated(true);
      let tempdata = featureSetValues;
      if (addedTargeting?.length) {
        setTempAddTargetting(addedTargeting);
        setCopyFeatureSetData(featureSetBody);
        setTempFeatureSetData(tempdata);
      }
    }
  };

  const getMessageDataWhileUpdate = (id: number, data: any) => {
    switch (id) {
      case 7: {
        const tempData = data.map((el: any) => el.displayName);
        return tempData;
      }
      case 9:
      case 10: {
        const tempData = data.map((el: any) => el.label);
        return tempData;
      }
      default:
        return data;
    }
  };

  useEffect(() => {
    const allUpdatingFeatureSetIds = updatedDataSet?.feature_sets?.map(
      (el: any) => el.feature_set_id
    );

    if (
      ifUpdated &&
      !ifUpdatedCompleted &&
      updatedDataSet?.feature_sets?.length &&
      allUpdatingFeatureSetIds?.includes(featureSetNumber) &&
      !globalFeatureTargetting
    ) {
      setIfUpdatedCompleted(1);
      const featureSet = updatedDataSet.feature_sets.find(
        (el: any) => el.feature_set_id === featureSetNumber
      );

      if (featureSet) {
        const params = getParams(),
          duals: any = { 17: 1, 16: 12 },
          targetingSet = featureSet.targeting.sort(
            (a: any, b: any) => a.targeting_id - b.targeting_id
          );

        let newTargetingSet: any = [];
        let tempFeatureSetDataForMessage = {};

        targetingSet.forEach((el: any) => {
          const keys = Object.keys(duals);

          if (keys.includes(el.targeting_id.toString())) {
            const index: number = newTargetingSet.findIndex(
              (elem: any) => elem.targeting_id === duals[`${el.targeting_id}`]
            );

            newTargetingSet[index] = {
              ...newTargetingSet[index],
              targeting_value: [
                ...newTargetingSet[index].targeting_value,
                JSON.parse(el.targeting_value),
              ],
              uniqueIds: [...newTargetingSet[index].uniqueIds, el.targeting_id],
            };
          } else {
            newTargetingSet.push({
              ...el,
              targeting_value:
                el.targeting_id !== 14
                  ? [JSON.parse(el.targeting_value)]
                  : el.targeting_value,
              uniqueIds: [el.targeting_id],
            });
          }
        });

        const usableTargetingSet = newTargetingSet.sort(
          (a: any, b: any) => a.targeting_set_rank - b.targeting_set_rank
        );

        usableTargetingSet.forEach((el: any) => {
          if (el.targeting_id === 7) {
            setUuid(el.targeting_value[0][0].uuid);
          }
          const param = params.find(
            (param: IFeatureSetParamDatum) => param.value === el.targeting_id
          );
          if (param) {
            const helper = getHelper(),
              temp: any = helper.find((h: any) =>
                el.uniqueIds.includes(h.savingId)
              )?.msgParams ?? { tol: 0 };
            const onChange = param?.param?.onChange;
            if (onChange !== undefined) {
              tempFeatureSetDataForMessage = {
                ...tempFeatureSetDataForMessage,
                [param.names[0]]: formatDataToCreateMessage({
                  data: getMessageDataWhileUpdate(
                    el.targeting_id,
                    el.targeting_value[0]
                  ),
                  ...temp,
                }),
              };
              onChange(el.targeting_value[0]);
            } else {
              if (el.uniqueIds[0] === 12) {
                tempFeatureSetDataForMessage = {
                  ...tempFeatureSetDataForMessage,
                  [param.names[0]]: formatDataToCreateMessage({
                    data: getMessageDataWhileUpdate(
                      el.targeting_id,
                      el.targeting_value[0]
                    ),
                    ...temp,
                  }),
                };
                tempFeatureSetDataForMessage = {
                  ...tempFeatureSetDataForMessage,
                  [param.names[1]]: formatDataToCreateMessage({
                    data: getMessageDataWhileUpdate(
                      el.targeting_id,
                      el.targeting_value[1]
                    ),
                    ...temp,
                  }),
                };
                param.param?.setSelectedPlanType(el.targeting_value[0]);
                param.param?.setSelectedProviders(el.targeting_value[1]);
              } else if (el.uniqueIds[0] === 7) {
                tempFeatureSetDataForMessage = {
                  ...tempFeatureSetDataForMessage,
                  [param.names[0]]: formatDataToCreateMessage({
                    data: getMessageDataWhileUpdate(
                      el.targeting_id,
                      el.targeting_value
                    ),
                    ...temp,
                  }),
                };
                param.param?.setCustomNPIList(...el.targeting_value);
              }
            }
          }
        });
        const operatorIds = operatorDDElements.map((el) => el.value);
        setSelectedOperator(
          operatorIds.includes(featureSet.condition_id)
            ? featureSet.condition_id
            : operatorIds[0]
        );
        setFeatureSetDataForMessagePrev(tempFeatureSetDataForMessage);
        setFeatureSetDataForMessage(tempFeatureSetDataForMessage);
        setAddedTargeting(usableTargetingSet.map((el: any) => el.targeting_id));
        setTimeout(() => {
          setUpdateLoaderState(false);
          setIfUpdatedCompleted(2);
        }, 200);
      }
    }

    if (!ifUpdated) {
      setIfUpdatedCompleted(0);
    }
  }, []);

  return (
    <Fragment>
      {modalToOpen && (
        <ModalComponent
          modalHeader="Delete FeatureSet"
          modalToOpen={modalToOpen}
          setModalToOpen={setModalToOpen}
          deleteSetParams={{
            setId: featureSetNumber,
            name: `FeatureSet ${featureSetOrderNumber}`,
            targetingArr: addedTargeting,
            handleDelete: setDeletingSetId,
          }}
        />
      )}
      {featureSetOrderNumber > 1 && (
        <div className="featureSetHandleWrap">
          <button
            className={`noStyleDropdown ${
              selectedOperator.toString() === '5'
                ? 'activeFeatureSetHandleOption'
                : ''
            }`}
            disabled={disabled}
            id="5"
            onClick={handleOperator}
          >
            And
          </button>
          <button
            className={`noStyleDropdown ${
              selectedOperator.toString() === '6'
                ? 'activeFeatureSetHandleOption'
                : ''
            }`}
            disabled={disabled}
            id="6"
            onClick={handleOperator}
          >
            Or
          </button>
          <button
            className={`noStyleDropdown ${
              selectedOperator.toString() === '8'
                ? 'activeFeatureSetHandleOption'
                : ''
            }`}
            disabled={disabled}
            id="8"
            onClick={handleOperator}
          >
            And Not
          </button>
        </div>
      )}
      <div className="featureSetWrapElement">
        <div className="featureSetHeadingWrap">
          <div>
            {!disabled && <img src={dragDrop} {...dragHandleProps} alt="" />}
            FeatureSet {featureSetOrderNumber}
          </div>

          {!disabled && (
            <Dropdown
              menu={{ items, onClick }}
              placement="bottomLeft"
              className="noStyleDropdown"
              trigger={['click']}
            >
              <Button>
                <img src={more} alt="" />
              </Button>
            </Dropdown>
          )}
        </div>
        <div>
          {addedTargeting.length > 0 && (
            <div>
              {featureSetBody.map((el: IFeatureSetElement) => (
                <Fragment key={`${el.orderId}_${el.setId}`}>
                  {el.element}
                </Fragment>
              ))}
            </div>
          )}
          {!disabled && addedTargeting.length < 1 && (
            <Select
              className="customAntSelect"
              options={targetingDDElementsForFs.filter(
                (el) => !el.disabled && !el.shouldHide
              )}
              placeholder="Select Targetable Feature"
              onChange={(data: number) => handleTargetableOptions(data)}
              value={null}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};

const formatDataToCreateMessage = (params: {
  data: any;
  md?: any;
  tol: 0 | 1 | 2 | 3 | 4;
  parentId?: any;
  ck?: string;
}) => {
  const { data, md, tol, parentId, ck } = params;

  switch (tol) {
    // Single select
    case 1: {
      return (
        md.find((el: IEachDropdownElementsType) => el.value === data)?.label ??
        ''
      );
    }
    // Multi Select
    case 2: {
      return md
        .filter((el: IEachDropdownElementsType) => data.includes(el.value))
        ?.map((el: IEachDropdownElementsType) => el.label);
    }
    // Dual selector
    case 3: {
      try {
        const typeOfSelectedParent = Array.isArray(parentId);
        if (
          ((typeOfSelectedParent && parentId.length) ||
            (!typeOfSelectedParent && parentId)) &&
          ck
        ) {
          const childMasterDataNested = md
              .filter((el: any) =>
                !typeOfSelectedParent
                  ? el.value === parentId
                  : parentId.includes(el.value)
              )
              .map((el: any) => el[ck]),
            childMasterData = childMasterDataNested.concat(
              ...childMasterDataNested
            );

          return childMasterData
            .filter((el: IEachDropdownElementsType) => data.includes(el.value))
            ?.map((el: IEachDropdownElementsType) => el.label);
        }
        return null;
      } catch (error) {
        return null;
      }
    }
    case 4: {
      if (data?.length) {
        return 'All';
      }
      return null;
    }
    default: {
      return data;
    }
  }
};

export default DefinedFeatureSet;
