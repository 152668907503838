import { FC, Fragment, useState } from 'react';
import { Table, TableProps, type TableColumnsType } from 'antd';
import dayjs from 'dayjs';
import { NavLink } from 'react-router-dom';
import {
  usePostApiInterceptor,
  useNotificationMessage,
} from '../../../../hooks';
import {
  ILoaderState,
  IPixelListingParam,
} from '../../../../models/interfaces';
import { SortOrder } from 'antd/es/table/interface';
import { useLoaderStore } from '../../../../stores';
import { RoutesEnum } from '../../../../models/enums/apiRoutes';

type OnChange = NonNullable<TableProps<DataType>['onChange']>;

interface DataType {
  key: number;
  title: string;
  pixel_domain: string;
  created_on: string;
  pixel_id: number;
}

const PixelListTable: FC<{
  pixelData: any;
  isLoading: boolean;
  paginationAndSortingData: IPixelListingParam;
  setPaginationAndSortingData: React.Dispatch<
    React.SetStateAction<IPixelListingParam>
  >;
}> = ({
  pixelData,
  isLoading,
  paginationAndSortingData,
  setPaginationAndSortingData,
}) => {
  const { setShowPageLoaderState } = useLoaderStore(
    (state: ILoaderState) => state
  );

  const [body, setBody] = useState<any>(''),
    [isMounted, setIsMounted] = useState<boolean>(false),
    [messageObj, setMessageObj] = useState<any>({ isShowing: false });

  const { context } = useNotificationMessage(messageObj);

  const columns: TableColumnsType<DataType> = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => (
        <NavLink to={'/update-pixel/' + record.key} title={text}>
          {text}
        </NavLink>
      ),
      width: 'auto',
      sorter: true,
      showSorterTooltip: false,
      ellipsis: true,
    },
    {
      title: 'Pixel ID',
      dataIndex: 'pixel_code',
      key: 'pixel_code',
      width: 'auto',
      sorter: true,
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Advertiser',
      dataIndex: 'advertiser',
      key: 'advertiser',
      width: 'auto',
      sorter: true,
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Agency',
      dataIndex: 'agency',
      key: 'agency',
      width: 'auto',
      sorter: true,
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Brand',
      dataIndex: 'brand',
      key: 'auto',
      width: '130px',
      sorter: true,
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Domain',
      dataIndex: 'pixel_domain',
      key: 'pixel_domain',
      width: 'auto',
      sorter: true,
      showSorterTooltip: false,
      ellipsis: true,
    },
    {
      title: 'Date',
      dataIndex: 'created_on',
      key: 'created_on',
      render: (text) => dayjs(text).format('M/D/YYYY'),
      width: '150px',
      sorter: true,
      defaultSortOrder: 'descend',
      showSorterTooltip: false,
    },
  ];

  const handleChange: OnChange = (pagination, filter, sorter: any) => {
    const { sortOrder, sortColumn, pageNumber, rowsPerPage } =
      paginationAndSortingData;

    setPaginationAndSortingData({
      ...paginationAndSortingData,
      sortOrder:
        sorter?.order === undefined ? sortOrder : getSortOrder(sorter.order),
      sortColumn: sorter?.columnKey ?? sortColumn,
      pageNumber: pagination?.current ?? pageNumber,
      rowsPerPage: pagination?.pageSize ?? rowsPerPage,
    });
  };

  const getSortOrder = (order: SortOrder) => {
    if (order === 'ascend') return 'ASC';
    if (order === 'descend') return 'DESC';
    return null;
  };

  usePostApiInterceptor(
    isMounted,
    body,
    RoutesEnum.DELETE_PIXEL_DATA,
    (data: any, error: any) => {
      setIsMounted(false);
      setShowPageLoaderState(false);
      setBody('');
      if (!error) {
        if (pixelData?.rows.length === 1) {
          setPaginationAndSortingData({
            ...paginationAndSortingData,
            pageNumber:
              paginationAndSortingData.pageNumber === 1
                ? 1
                : paginationAndSortingData.pageNumber - 1,
          });
        } else {
          setPaginationAndSortingData({ ...paginationAndSortingData });
        }
      } else {
        updateErrorMessage(data);
        setShowPageLoaderState(false);
      }
    }
  );

  const updateErrorMessage = (message: string) => {
    setMessageObj({
      key: 'delete',
      isShowing: true,
      type: 'error',
      messageContent: message,
    });
  };

  return (
    <Fragment>
      {context}
      <Table
        columns={columns}
        dataSource={pixelData?.rows ?? []}
        loading={isLoading}
        onChange={handleChange}
        pagination={{
          current: paginationAndSortingData.pageNumber,
          total: pixelData?.total_row_count ?? 0,
          showSizeChanger: false,
          pageSize: paginationAndSortingData.rowsPerPage,
        }}
        className={`antTableElement pixelListingTable audienceListTable ${
          isLoading ? ' hideNoDataLabel' : ''
        }`}
        sortDirections={['ascend', 'descend', 'ascend']}
      />
    </Fragment>
  );
};

export default PixelListTable;
