import { Table, TableProps, type TableColumnsType } from 'antd';
import { FC } from 'react';
import { IAccountListingParam } from '../../../../models/interfaces';
import { NavLink } from 'react-router-dom';
import dayjs from 'dayjs';
import { SortOrder } from 'antd/es/table/interface';

type OnChange = NonNullable<TableProps<DataType>['onChange']>;

interface DataType {
  key: number;
  title: string;
  status: string;
  audience_type: string;
  created_on: string;
}

const AccountListingTable: FC<{
  accounts: any;
  isLoading: boolean;
  paginationAndSortingData: IAccountListingParam;
  setPaginationAndSortingData: React.Dispatch<
    React.SetStateAction<IAccountListingParam>
  >;
}> = ({
  accounts,
  isLoading,
  paginationAndSortingData,
  setPaginationAndSortingData,
}) => {
  const handleChange: OnChange = (pagination, filter, sorter: any) => {
    const { sortOrder, sortColumn, pageNumber, rowsPerPage } =
      paginationAndSortingData;

    setPaginationAndSortingData({
      ...paginationAndSortingData,
      sortOrder:
        sorter?.order === undefined ? sortOrder : getSortOrder(sorter.order),
      sortColumn: sorter?.columnKey ?? sortColumn,
      pageNumber: pagination?.current ?? pageNumber,
      rowsPerPage: pagination?.pageSize ?? rowsPerPage,
    });
  };

  const getSortOrder = (order: SortOrder) => {
    if (order === 'ascend') return 'ASC';
    if (order === 'descend') return 'DESC';
    return null;
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: 'Name',
      dataIndex: 'account_name',
      key: 'account_name',
      render: (text, record:any) => (
        <>
          <NavLink to={'/add-account/' + record.account_id} title={text}>
            {text}
          </NavLink>
        </>
      ),
      width: 'auto',
      sorter: false,
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 'auto',
      sorter: false,
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Date',
      dataIndex: 'created_on',
      key: 'created_on',
      render: (text) => dayjs(text).format('M/D/YYYY'),
      width: '150px',
      sorter: false,
      defaultSortOrder: 'descend',
      ellipsis: true,
      showSorterTooltip: false,
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={accounts?.rows ?? []}
        onChange={handleChange}
        className={`antTableElement audienceListTable${
          isLoading ? ' hideNoDataLabel' : ''
        }`}
        pagination={{
          current: paginationAndSortingData.pageNumber,
          total: accounts?.total_row_counts ?? 0,
          showSizeChanger: false,
          pageSize: paginationAndSortingData.rowsPerPage,
        }}
        loading={isLoading}
        sortDirections={['ascend', 'descend', 'ascend']}
      />
    </div>
  );
};

export default AccountListingTable;
