import { FC } from 'react';
import { breadcrumbSeparator } from '../../../assets/images';
import { IAudienceSetUpState } from '../../../models/interfaces';
import { useAudienceSetUp } from '../../../stores/audienceSets.store';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

interface BreadcrumbProps {
  items: any;
  activeIndex: number;
}

const Breadcrumb: FC<BreadcrumbProps> = ({ items, activeIndex }) => {
  const { audId, id } = useParams<{ audId: string; id: string }>();
  const location = useLocation();
  const { setAudiencePageNumber, audiencePageNumber } = useAudienceSetUp(
      (state: IAudienceSetUpState) => state
    ),
    navigate = useNavigate();
  
  const handleNavigate = (item: any) => {
    if (audiencePageNumber === 3 && (item.key === 2 || item.key === 1)) {
      navigate(`/update-audience/${audId}`);
      setAudiencePageNumber(item.key);
    } else if (
      location.pathname.includes('/update-audience') &&
      item.key === 3
    ) {
      navigate(`/audience-activation/${id}`);
      setAudiencePageNumber(item.key);
    } else if (
      location.pathname.includes('/update-audience') &&
      (item.key === 2 || item.key === 1)
    ) {
      setAudiencePageNumber(item.key);
    }
  };

  return (
    <div className="breadCrumbContainer">
      {items.map((item: any) => (
        <span key={item.key}>
          <span
            style={{
              color:
                item.key === activeIndex
                  ? 'var(--bl-header)'
                  : 'var(--bl-grey-2)',
            }}
            className="breadCrumbLink"
          >
            {item.key !== 1 && (
              <span className="breadCrumbSeparator">
                <img src={breadcrumbSeparator} alt="arrow" />
              </span>
            )}
            <p onClick={() => handleNavigate(item)}>{item.title}</p>
          </span>
        </span>
      ))}
    </div>
  );
};

export default Breadcrumb;
