import { Col, Row, Table, TableColumnsType } from 'antd';
import React, { FC } from 'react';
import {
  IUserInformationState,
  Iadvertiser,
  Iagency,
  Ibrand,
} from '../../../../models/interfaces';
import { useUserInformationStore } from '../../../../stores';
import { RbacSettings } from '../../../../models/enums/rbacSetting';

const Organisation: FC<{
  agencies: Iagency[];
  brands: Ibrand[];
  advertisers: Iadvertiser[];
  onOpen: () => void;
  isLoading: boolean;
  setType: React.Dispatch<React.SetStateAction<string | null>>;
  setField: React.Dispatch<React.SetStateAction<string>>;
  setId: React.Dispatch<React.SetStateAction<number>>;
  destroyMessage: () => void;
}> = ({
  agencies,
  brands,
  advertisers,
  onOpen,
  setType,
  isLoading,
  setField,
  setId,
  destroyMessage,
}) => {
  const { privilegeSetDDElements } = useUserInformationStore(
    (state: IUserInformationState) => state
  );

  const onEdit = (type: string, value: any) => {
    setField(value.label);
    setId(value.id);
    setType(type);
    onOpen();
  };

  const agencyColumn: TableColumnsType<Iagency> = [
    {
      title: 'Name',
      dataIndex: 'label',
      key: 'label',
      render: (text, record) => (
        <div className="textHyperLink" onClick={() => onEdit('agency', record)}>
          {text}
        </div>
      ),
      width: '250px',
      sorter: false,
      ellipsis: true,
      showSorterTooltip: false,
    },
  ];

  const brandColumn: TableColumnsType<Ibrand> = [
    {
      title: 'Name',
      dataIndex: 'label',
      key: 'label',
      render: (text, record) => (
        <div className="textHyperLink" onClick={() => onEdit('brand', record)}>
          {text}
        </div>
      ),
      width: '250px',
      sorter: false,
      ellipsis: true,
      showSorterTooltip: false,
    },
  ];

  const advertiserColumn: TableColumnsType<Iadvertiser> = [
    {
      title: 'Name',
      dataIndex: 'label',
      key: 'label',
      render: (text, record) => (
        <div
          className="textHyperLink"
          onClick={() => onEdit('advertiser', record)}
        >
          {text}
        </div>
      ),
      width: '250px',
      sorter: false,
      ellipsis: true,
      showSorterTooltip: false,
    },
  ];

  const handleOpen = (key: string) => {
    destroyMessage();
    setType(key);
    onOpen();
  };

  return (
    <Row gutter={24} className="agencyBrandAdvertiser">
      <Col span={8}>
        <div className="aboveTableAction">
          <p>Agencies</p>
          {privilegeSetDDElements.includes(RbacSettings.ACCOUNT_SETTINGS_EDIT)}
          <button className="primaryBtn" onClick={() => handleOpen('agency')}>
            New Agency
          </button>
        </div>
        <Table
          columns={agencyColumn}
          dataSource={agencies}
          pagination={false}
          loading={isLoading}
          sortDirections={['ascend', 'descend', 'ascend']}
          // scroll={{ x: 'max-content', y: 450 }}
        />
      </Col>
      <Col span={8}>
        <div className="aboveTableAction">
          <p>Advertisers</p>
          <button
            className="primaryBtn"
            onClick={() => handleOpen('advertiser')}
          >
            New Advertiser
          </button>
        </div>
        <Table
          columns={advertiserColumn}
          dataSource={advertisers}
          pagination={false}
          loading={isLoading}
          sortDirections={['ascend', 'descend', 'ascend']}
          // scroll={{ x: 'max-content', y: 450 }}
        />
      </Col>
      <Col span={8}>
        <div className="aboveTableAction">
          <p>Brands</p>
          <button className="primaryBtn" onClick={() => handleOpen('brand')}>
            New Brand
          </button>
        </div>
        <Table
          columns={brandColumn}
          dataSource={brands}
          pagination={false}
          loading={isLoading}
          sortDirections={['ascend', 'descend', 'ascend']}
          // scroll={{ x: 'max-content', y: 450 }}
        />
      </Col>
    </Row>
  );
};

export default Organisation;
