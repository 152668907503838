import { Dispatch, FC, SetStateAction } from 'react';
import Modal from 'antd/es/modal/Modal';
import { useMasterSSPElementsStore } from '../../../../stores/masterData.store';
import {
  IEachDropdownElementsType,
  IMasterSspDDState,
} from '../../../../models/interfaces';
import { Row, Select } from 'antd';
import { CommonElementLabel, InputField } from '../../../sharedComponent';
import { FlattenOptionData } from 'rc-select/lib/interface';
import { BaseOptionType } from 'antd/es/select';

function convertData(
  data: IEachDropdownElementsType[],
  selectedAudienceType: number
) {
  const groupedData: { [key: string]: any } = {};

  const filteredData: IEachDropdownElementsType[] = data.filter((item) => {
    const { partner } = item;
    if (selectedAudienceType === 1) {
      return partner === 'Transunion';
    } else if (selectedAudienceType === 2) {
      return partner === 'Semcasting' || partner === 'Throtle';
    }
    return false;
  });

  filteredData.forEach((item: any) => {
    const { partner, label, value } = item;

    if (!groupedData[partner]) {
      groupedData[partner] = {
        label: partner,
        title: partner,
        options: [],
      };
    }

    groupedData[partner].options.push({
      label: partner + ': ' + label,
      value: value,
    });
  });

  return Object.values(groupedData);
}

const AudienceDestinationModal: FC<{
  partner: string;
  modalToOpen: boolean;
  estimated_Size: string;
  activate: () => void;
  setPartner: Dispatch<SetStateAction<string>>;
  setModalToOpen: Dispatch<SetStateAction<boolean>>;
  lastEstimate: null | string;
  setEstimated_Size: Dispatch<SetStateAction<string>>;
  selectedAudienceType: number | null;
}> = ({
  modalToOpen,
  setModalToOpen,
  setEstimated_Size,
  estimated_Size,
  partner,
  setPartner,
  activate,
  lastEstimate,
  selectedAudienceType,
}) => {
  const cancelModal = () => {
    setPartner('');
    setModalToOpen(false);
  };

  const { sspDDElements } = useMasterSSPElementsStore(
    (state: IMasterSspDDState) => state
  );

  const isOkButtonDisabled = !partner;

  function getDaysFromDate(dateString: string): number {
    const inputDate = new Date(dateString);
    if (isNaN(inputDate.getTime())) {
      throw new Error('Invalid date string provided');
    }
    const currentDate = new Date();
    const differenceInMilliseconds = Math.abs(
      currentDate.getTime() - inputDate.getTime()
    );
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const differenceInDays = Math.floor(
      differenceInMilliseconds / millisecondsPerDay
    );
    return differenceInDays;
  }

  return (
    <Modal
      centered
      open={modalToOpen}
      onCancel={cancelModal}
      onOk={activate}
      okButtonProps={{
        disabled: isOkButtonDisabled,
      }}
      okText="Activate"
      className="commonModal"
      width={'550px'}
    >
      <div className="audienceDestinationModal modalGenericContent">
        <div className="modalGenericTextContent">
          {lastEstimate && getDaysFromDate(lastEstimate) > 45 && (
            <p className="audienceAge">
              The audience is {getDaysFromDate(lastEstimate)} days old
            </p>
          )}
          <h3>Audience Activation</h3>
          <Row>
            <CommonElementLabel label="Delivery Partner: " isMandatory />
            <Select
              value={parseInt(partner) || undefined}
              onChange={(data: number) => {
                setPartner(data.toString());
              }}
              style={{ width: 'calc(100% - 163px)' }}
              placeholder="Select Partner"
              options={convertData(sspDDElements, selectedAudienceType ?? 1)}
              optionRender={(option) => optionRender(option)}
            />
          </Row>
          {selectedAudienceType !== 2 && (
            <>
              <Row>
                <CommonElementLabel label="Audience Size Override: " />
                <div className="audienceSizeInput">
                  <InputField
                    label=""
                    defaultValue={estimated_Size}
                    onChange={setEstimated_Size}
                    fieldType="number"
                    inputPlaceholder="Optional"
                  />
                  <p className="helpTextAudienceSize">
                    Specify a custom audience size. If left blank, the
                    recommended size will be used.
                  </p>
                </div>
              </Row>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

const optionRender = (option: FlattenOptionData<BaseOptionType>) => {
  return <>{String(option?.label)?.split(': ')[1]}</>;
};

export default AudienceDestinationModal;
