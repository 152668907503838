import { Route } from 'react-router-dom';
import { UnAuthGuard } from '../guards';
import { LoginPage, } from '../components/pages';
import { VerifyUser } from '../components/pages/VerifyUser';

const UnAuthRoutes = [
  <Route
    key="Login"
    path="/login"
    element={<UnAuthGuard component={<LoginPage />} />}
  ></Route>,
  <Route
    key="Verify"
    path="/verify-user"
    element={<UnAuthGuard component={<VerifyUser />} />}
  ></Route>,
];

export default UnAuthRoutes;
