import { Dropdown, Menu, MenuProps, Table, TableColumnsType } from 'antd';
import React, { FC, Fragment, useState } from 'react';
import { moreIconVertical } from '../../../../assets/images';
import { ModalComponent } from '../../../sharedComponent';
import { RoutesEnum } from '../../../../models/enums/apiRoutes';
import { usePostApiInterceptor } from '../../../../hooks';

const items: MenuProps['items'] = [
  {
    key: '1',
    label: 'Deactivate',
  },
];

const items2: MenuProps['items'] = [
  {
    key: '1',
    label: 'Activate',
  },
];

interface DataType {
  account_id: number;
  user_id: number;
  name: string;
  email: string;
  role_name: string;
  is_active: boolean;
}

const UserTable: FC<{
  data: any;
  isLoading: boolean;
  setUser: React.Dispatch<React.SetStateAction<any>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleActive: () => void;
  destroy: () => void;
  updateErrorMessage: (key: string, message: string) => void;
}> = ({ isLoading, data, setUser, setOpen, handleActive, destroy, updateErrorMessage }) => {
  const [openDelete, setOpenDelete] = useState<boolean>(false),
    [deleteInfo, setDeleteInfo] = useState<any>(null),
    [isMounted, setIsMounted] = useState<boolean>(false),
    [body, setBody] = useState<{ uid: number; is_active: boolean } | null>(
      null
    );

  usePostApiInterceptor(
    isMounted,
    body,
    RoutesEnum.DEACTIVATE_USER,
    (data: any, error: any) => {
      setIsMounted(false);
      if (error || !data) {
        updateErrorMessage('error', 'Error occurred while saving User.');
        return;
      }
      updateErrorMessage('success', 'User is saved');
      handleActive();
    }
  );

  const handleAction = (e: any, record: any) => {
    destroy();
    setDeleteInfo({
      id: record.user_id,
      title: record.name,
      is_active: record.is_active ? false : true,
    });
    setOpenDelete(true);
  };

  const deactivateUser = () => {
    setBody({
      uid: deleteInfo?.id,
      is_active: deleteInfo.is_active,
    });
    setIsMounted(true);
  };

  const onEdit = (record: any) => {
    if (record.role_name !== 'Super Administrator') {
      setUser(record);
      setOpen(true);
    }
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '350px',
      render: (text, record) => (
        <div
          className={`textHyperLink ${
            record.role_name !== 'Super Administrator' ? '' : 'disabledElement'
          }`}
          onClick={() => onEdit(record)}
        >
          {text}
        </div>
      ),
      sorter: false,
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '250px',
      render: (text, record) => (
        <div
          className={
            record.role_name !== 'Super Administrator' ? '' : 'disabledElement'
          }
        >
          {text}
        </div>
      ),
      sorter: false,
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Status',
      dataIndex: 'is_active',
      key: 'is_active',
      width: '200px',
      render: (text, record) => (
        <div
          className={
            record.role_name !== 'Super Administrator' ? '' : 'disabledElement'
          }
        >
          {record.is_active ? 'Active' : 'Disabled'}
        </div>
      ),
    },
    {
      title: 'Role',
      dataIndex: 'role_name',
      key: 'role',
      width: '200px',
      render: (text, record) => (
        <div
          className={
            record.role_name !== 'Super Administrator' ? '' : 'disabledElement'
          }
        >
          {text}
        </div>
      ),
      sorter: false,
      ellipsis: true,
      showSorterTooltip: false,
    },

    {
      title: '',
      dataIndex: '',
      key: 'x',
      width: '50px',
      sorter: false,
      ellipsis: true,
      render: (_, record) => {
        return (
          <Dropdown
            trigger={['click']}
            overlay={
              <Menu
                items={
                  record.role_name === 'Super Administrator'
                    ? []
                    : record.is_active
                    ? items
                    : items2
                }
                onClick={(e) => handleAction(e, record)}
              ></Menu>
            }
            placement="bottomRight"
            overlayClassName="actionMenu"
          >
            {record.role_name !== 'Super Administrator' ? (
              <img
                src={moreIconVertical}
                className="actionMenuIcon"
                alt="more"
              />
            ) : (
              <></>
            )}
          </Dropdown>
        );
      },
    },
  ];

  return (
    <Fragment>
      <ModalComponent
        modalHeader={`${
          deleteInfo?.is_active ? 'Activate ' : 'Disabled '
        } User`}
        modalToOpen={openDelete}
        setModalToOpen={setOpenDelete}
        deleteSetParams={{
          name: `${deleteInfo?.is_active ? 'Activate ' : 'Disabled '} (${
            deleteInfo?.title
          })`,
          okText: `${deleteInfo?.is_active ? 'Activate ' : 'Disabled '}`,
          handelOk: deactivateUser,
        }}
        extra={true}
        type={deleteInfo?.is_active}
      />
      <Table
        columns={columns}
        dataSource={data}
        className={`antTableElement audienceListTable${
          isLoading ? ' hideNoDataLabel' : ''
        }`}
        pagination={false}
        loading={isLoading}
        sortDirections={['ascend', 'descend', 'ascend']}
        scroll={{ x: 'max-content' }}
      />
    </Fragment>
  );
};

export default UserTable;
