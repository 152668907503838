import { Dispatch, FC, SetStateAction, useEffect, useRef } from 'react';
import { InputRef, Checkbox, Divider, Row, Col } from 'antd';
import {
  ICheckIfDataModifiedState,
  IEachDropdownElementsType,
  IFeatureSetDataState,
  ILoaderState,
} from '../../../../models/interfaces';
import {
  useCheckIfDataModifiedStore,
  useFeatureSetDataHandlingStore,
  useLoaderStore,
} from '../../../../stores';
import {
  CommonElementLabel,
  InputField,
  Single,
} from '../../../../components/sharedComponent';

const CheckboxGroup = Checkbox.Group;

const DefaultFeatureSet: FC<{
  defaultTitle: string;
  setTitle: (value: string) => void;
  advertiser: number | null;
  setAdvertiser: (value: string) => void;
  audienceType: number | null;
  defaultAgency: number | null;
  defaultBrand: number | null;
  setAudienceType: Dispatch<SetStateAction<number | null>>;
  setAgency: (value: string) => void;
  setBrand: (value: string) => void;
  selectedAdditionalOpts: (number | string)[];
  setSelectedAdditionalOpts: Dispatch<SetStateAction<(number | string)[]>>;
  agencyDDElements: IEachDropdownElementsType[];
  advertiserDDElements: IEachDropdownElementsType[];
  brandDDElements: IEachDropdownElementsType[];
  audienceTypeDDElements: IEachDropdownElementsType[];
  additionalOptionDDElements: IEachDropdownElementsType[];
  focusOnTitle?: 0 | 1 | 2;
  disabled?: boolean;
}> = ({
  defaultTitle,
  defaultAgency,
  defaultBrand,
  advertiser,
  setAdvertiser,
  setAgency,
  setBrand,
  setTitle,
  audienceType,
  setAudienceType,
  selectedAdditionalOpts,
  setSelectedAdditionalOpts,
  agencyDDElements,
  brandDDElements,
  advertiserDDElements,
  audienceTypeDDElements,
  additionalOptionDDElements,
  focusOnTitle,
  disabled,
}) => {
  const titleRef = useRef<InputRef>(null);
  const agencyRef = useRef<InputRef>(null);

  const { showPageLoaderState } = useLoaderStore(
      (state: ILoaderState) => state
    ),
    { setIfDataModified } = useCheckIfDataModifiedStore(
      (state: ICheckIfDataModifiedState) => state
    ),
    { updatedDataSet, setUpdatedDataSet } = useFeatureSetDataHandlingStore(
      (state: IFeatureSetDataState) => state
    );

  useEffect(() => {
    if (!showPageLoaderState && titleRef.current) {
      setTimeout(() => {
        agencyRef.current?.focus({
          cursor: 'end',
        });
      }, 300);
    }
  }, [focusOnTitle, showPageLoaderState]);

  function processFeatureSets(data: any) {
    const { feature_sets } = data;
    const filteredFeatureSets = (feature_sets || [])
      .filter((fs:any) =>
        !fs.targeting.some((target:any) =>
          [20, 6, 7].includes(target.targeting_id)
        )
      )
      .map((fs:any, index:number) => {
        if (fs.is_global) {
          fs.targeting = fs.targeting.filter((target:any) => target.targeting_id !== 19);
        }
        return fs;
      });
    data.feature_sets = filteredFeatureSets;
    setUpdatedDataSet(data)
    return data;
  }

  return (
    <div className="defaultFeatureSetSection">
      <Row gutter={24}>
        <Col span={12}>
          <InputField
            label="Title"
            isMandatory
            inputPlaceholder="Title"
            onChange={(data: any) => {
              setIfDataModified(true);
              setTitle(data);
            }}
            defaultValue={defaultTitle}
            props={{ ref: titleRef }}
            inputWrapperClasses={['mb-20']}
          />

          <div className="mb-20">
            <CommonElementLabel label="Audience Type" isMandatory={true} />
            <Single
              options={audienceTypeDDElements}
              allowClear={false}
              placeholder="Select Audience Type"
              onChange={(data: any) => {
                if(Object.keys(updatedDataSet).length > 0){
                  processFeatureSets(updatedDataSet)
                }
                setIfDataModified(true);
                setAudienceType(data);
              }}
              componentSpecificClasses={['checkboxListDropdownElem']}
              props={{ value: audienceType }}
              isDisabled={disabled ?? false}
            />
          </div>
          <CheckboxGroup
            options={additionalOptionDDElements}
            onChange={setSelectedAdditionalOpts}
            value={selectedAdditionalOpts}
            className="checkboxListWrap"
            disabled={disabled ?? false}
          />
        </Col>
        <Col span={12}>
          <div className="mb-20">
            <CommonElementLabel label="Agency" isMandatory={true} />
            <Single
              options={agencyDDElements}
              placeholder="Select Agency"
              onChange={(data: any) => {
                setIfDataModified(true);
                setAgency(data);
              }}
              componentSpecificClasses={['checkboxListDropdownElem']}
              allowClear={false}
              props={{ value: defaultAgency, ref: agencyRef }}
            />
          </div>
          <div className="mb-20">
            <CommonElementLabel label="Advertiser" isMandatory={true} />
            <Single
              options={advertiserDDElements}
              placeholder="Select Advertiser"
              onChange={(data: any) => {
                setIfDataModified(true);
                setAdvertiser(data);
              }}
              componentSpecificClasses={['checkboxListDropdownElem']}
              allowClear={false}
              props={{ value: advertiser }}
            />
          </div>
          <div className="mb-20">
            <CommonElementLabel label="Brand" isMandatory={true} />
            <Single
              options={brandDDElements}
              placeholder="Select Brand"
              onChange={(data: any) => {
                setBrand(data);
                setIfDataModified(true);
              }}
              componentSpecificClasses={['checkboxListDropdownElem']}
              allowClear={false}
              props={{ value: defaultBrand }}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DefaultFeatureSet;
