import { FC, Fragment, useEffect, useState } from 'react';
import { ListingTable } from './';
import { PageHeader } from '../../../sharedComponent';
import { usePostApiInterceptor, useRedirection } from '../../../../hooks';
import { RoutesEnum } from '../../../../models/enums/apiRoutes';
import {
  IAudienceListingParam,
  ILoaderState,
} from '../../../../models/interfaces';
import { useLoaderStore } from '../../../../stores';
import { Button, ConfigProvider } from 'antd';
import GlobalFilterModal from '../../../sharedComponent/GlobalFilterModal/GlobalFilterModal';
import { FilterMessage } from '../../../sharedComponent/FilterMessage';
import { filter } from '../../../../assets/images';

type AudienceBodyType = {
  audience_type: string | null;
  end_date: string | null;
  start_date: string | null;
  limit: number;
  off_set: number;
  order_column: string | null;
  order_direction: string | null;
  title: string | null;
  brand_id: string | null;
  status_id: string | null;
  agency_id: string | null;
  advertiser_id: string | null;
  timeframe: string | null;
  filter_option: number | null;
};

const convertFiltersToNumberArray = (filters: any): IAudienceListingParam => {
  const result: Partial<IAudienceListingParam> = {
    sortOrder: baseListingParam.sortOrder,
    sortColumn: baseListingParam.sortColumn,
    pageNumber: baseListingParam.pageNumber,
    rowsPerPage: baseListingParam.rowsPerPage,
  };

  // Handle global filters
  if (filters.global) {
    if (filters.global.agency)
      result.agency = convertToNumberArray(filters.global.agency);
    if (filters.global.advertiser)
      result.advertiser = convertToNumberArray(filters.global.advertiser);
    if (filters.global.brand)
      result.brand = convertToNumberArray(filters.global.brand);
    if (filters.global.dateRange) result.dateRange = filters.global.dateRange;
    if (filters.global.timeframe) result.timeframe = filters.global.timeframe;
  }

  // Handle activation filters
  if (filters.audience) {
    if (filters.audience.title) result.title = filters.audience.title;
    if (filters.audience.filter_option)
      result.filter_option = filters.audience.filter_option;
    if (filters.audience.audienceType)
      result.audienceType = filters.audience.audienceType;
  }

  // Handle audience filters
  if (filters.audience && filters.audience.status) {
    result.status = filters.audience.status.toString();
  }
  // console.log(result)
  return result as IAudienceListingParam;
};

const convertToNumberArray = (value: string | null): number[] | null => {
  if (!value) return null;
  return value
    .split(',')
    .map(Number)
    .filter((n) => !isNaN(n));
};

const baseListingParam = {
  dateRange: null,
  audienceType: null,
  status: null,
  agency: null,
  brand: null,
  advertiser: null,
  title: '',
  filter_option: 1,
  timeframe: null,
  sortOrder: 'DESC',
  sortColumn: 'created_on',
  pageNumber: 1,
  rowsPerPage: 15,
};

const AudienceListingWrap: FC = () => {
  const filters = JSON.parse(localStorage.getItem('Filters') || '{}');

  const [audienceListingParam, setAudienceListingParam] =
    useState<IAudienceListingParam>(
      Object.keys(filters).length > 0
        ? convertFiltersToNumberArray(filters)
        : baseListingParam
    );

  const [body, setBody] = useState<AudienceBodyType | null>(null),
    [isMounted, setIsMounted] = useState<boolean>(false);

  const { data, isLoading } = usePostApiInterceptor(
    isMounted,
    body,
    RoutesEnum.AUDIENCE_LISTING_DATA,
    () => {
      setIsMounted(false);
    }
  ),
    { redirectAudienceSetup } = useRedirection();

  const { loaderState } = useLoaderStore((state: ILoaderState) => state);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, [body]);

  useEffect(() => {
    setBody({
      agency_id: audienceListingParam.agency?.length
        ? audienceListingParam.agency.toString()
        : null,
      advertiser_id: audienceListingParam.advertiser?.length
        ? audienceListingParam.advertiser.toString()
        : null,
      brand_id: audienceListingParam.brand?.length
        ? audienceListingParam.brand.toString()
        : null,
      status_id: audienceListingParam.status?.length
        ? audienceListingParam.status.toString()
        : null,
      audience_type: audienceListingParam.audienceType?.length
        ? audienceListingParam.audienceType.toString()
        : null,
      end_date: audienceListingParam.dateRange?.length
        ? audienceListingParam.dateRange[1]
        : null,
      start_date: audienceListingParam.dateRange?.length
        ? audienceListingParam.dateRange[0]
        : null,
      title: audienceListingParam.title,
      filter_option: audienceListingParam.filter_option,
      timeframe: audienceListingParam.timeframe,
      limit: audienceListingParam.rowsPerPage,
      off_set:
        (audienceListingParam.pageNumber - 1) *
        audienceListingParam.rowsPerPage,
      order_column: audienceListingParam.sortColumn,
      order_direction: audienceListingParam.sortOrder,
    });
  }, [audienceListingParam]);

  const applyFilters = (data: any) => {
    let filterData = {
      agency_id: data.agency?.length ? data.agency.toString() : null,
      advertiser_id: data.advertiser?.length
        ? data.advertiser.toString()
        : null,
      brand_id: data.brand?.length ? data.brand.toString() : null,
      status_id: data.status?.length ? data.status.toString() : null,
      audience_type: data.audienceType?.length
        ? data.audienceType.toString()
        : null,
      end_date: data.dateRange?.length ? data.dateRange[1] : null,
      start_date: data.dateRange?.length ? data.dateRange[0] : null,
      title: data.title,
      filter_option: data.filter_option,
      limit: audienceListingParam.rowsPerPage,
      timeframe: data.timeframe,
      off_set:
        (audienceListingParam.pageNumber - 1) *
        audienceListingParam.rowsPerPage,
      order_column: audienceListingParam.sortColumn,
      order_direction: audienceListingParam.sortOrder,
    };
    let filterStorage = {
      ...JSON.parse(localStorage.getItem('Filters') || '{}'),
      global: {
        agency: filterData.agency_id,
        advertiser: filterData.advertiser_id,
        brand: filterData.brand_id,
        dateRange:
          filterData.start_date && filterData.end_date
            ? [filterData.start_date, filterData.end_date]
            : null,
        timeframe: filterData.timeframe,
      },
      audience: {
        title: filterData.title,
        filter_option: filterData.filter_option,
        status: data.status,
        audienceType: data.audienceType,
      },
    };
    setAudienceListingParam({
      audienceType: data.audienceType?.length ? data.audienceType : null,
      status: data.status?.length ? data.status.toString() : null,
      agency: data.agency?.length ? data.agency : null,
      brand: data.brand?.length ? data.brand : null,
      advertiser: data.advertiser?.length ? data.advertiser : null,
      title: data.title,
      filter_option: data.filter_option,
      rowsPerPage: baseListingParam.rowsPerPage,
      timeframe: data.timeframe ?? null,
      pageNumber: baseListingParam.pageNumber,
      sortColumn: baseListingParam.sortColumn,
      sortOrder: baseListingParam.sortOrder,
      dateRange: data.dateRange?.length
        ? [data.dateRange[0], data.dateRange[1]]
        : null,
    });
    setBody(filterData);
    localStorage.setItem('Filters', JSON.stringify(filterStorage));
    setIsFilterModalOpen(false);
  };

  const handleCancel = () => {
    setIsFilterModalOpen(false);
  };

  return (
    <div className="audienceCommonWrap">
      <PageHeader title="Audiences" />
      <div className="audienceListingPageContent">
        {!loaderState && (
          <Fragment>
            <div className="filterWrap">
              <div className="filterBtnAndText">
                <ConfigProvider wave={{ disabled: true }}>
                  <Button onClick={() => setIsFilterModalOpen(true)}>
                    <img src={filter} alt="" />
                  </Button>
                </ConfigProvider>
                {(audienceListingParam.agency ||
                  audienceListingParam.advertiser ||
                  audienceListingParam.brand ||
                  audienceListingParam.audienceType ||
                  audienceListingParam.timeframe ||
                  audienceListingParam.dateRange ||
                  audienceListingParam.title ||
                  audienceListingParam.status ||
                  audienceListingParam.audienceType) && (
                    <FilterMessage type="audience" data={audienceListingParam} />
                  )}
              </div>

              <GlobalFilterModal
                isOpen={isFilterModalOpen}
                filterData={audienceListingParam as IAudienceListingParam}
                type="audience"
                handleOk={applyFilters}
                handleCancel={handleCancel}
              />

              <button
                className="primaryBtn addAudienceBtn"
                onClick={() => redirectAudienceSetup()}
              >
                New Audience
              </button>
            </div>
            <ListingTable
              audData={data}
              isLoading={isLoading}
              paginationAndSortingData={audienceListingParam}
              setPaginationAndSortingData={setAudienceListingParam}
            />
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default AudienceListingWrap;
