import React, { FC } from 'react';
import {
  useMasterAudienceTypeElementsStore,
  useMasterTimeFrameElementsStore,
} from '../../../stores';
import {
  IAudienceSetUpState,
  IMasterActionDDState,
  IMasterAdvertiserDDState,
  IMasterAgencyDDState,
  IMasterAudienceTypeDDState,
  IMasterBrandDDState,
  IMasterFilterDDState,
  IMasterReportsDDState,
  IMasterSspDDState,
  IMasterStatusDDState,
  IMasterTimeFrameDDState,
} from '../../../models/interfaces';
import {
  useMasterActionElementsStore,
  useMasterAdvertiserElmentStore,
  useMasterAgencyElmentStore,
  useMasterBrandElmentStore,
  useMasterFilterElmentStore,
  useMasterReportsElementsStore,
  useMasterSSPElementsStore,
  useMasterStatusElementsStore,
} from '../../../stores/masterData.store';
import { itemTypeElement } from '../../../demoData/baseData';
import { CloseOutlined } from '@ant-design/icons';
import { useAudienceSetUp } from '../../../stores/audienceSets.store';


const FilterMessage: FC<{
  type: 'audience' | 'activation' | 'research' | 'pixel' | 'audit' | '';
  data: any;
}> = ({ type, data }) => {
  const { timeFrameDDElements } = useMasterTimeFrameElementsStore(
      (state: IMasterTimeFrameDDState) => state
    ),
    { audienceTypeDDElements } = useMasterAudienceTypeElementsStore(
      (state: IMasterAudienceTypeDDState) => state
    ),
    { agencyDDElements } = useMasterAgencyElmentStore(
      (state: IMasterAgencyDDState) => state
    ),
    { brandDDElements } = useMasterBrandElmentStore(
      (state: IMasterBrandDDState) => state
    ),
    { filterDDElements } = useMasterFilterElmentStore(
      (state: IMasterFilterDDState) => state
    ),
    { statusDDElements } = useMasterStatusElementsStore(
      (state: IMasterStatusDDState) => state
    ),
    { sspDDElements } = useMasterSSPElementsStore(
      (state: IMasterSspDDState) => state
    ),
    { reportsDDElements } = useMasterReportsElementsStore(
      (state: IMasterReportsDDState) => state
    ),
    { actionDDElements } = useMasterActionElementsStore(
      (state: IMasterActionDDState) => state
    ),
    { advertiserDDElements } = useMasterAdvertiserElmentStore(
      (state: IMasterAdvertiserDDState) => state
    ),
    { setEmptyFilter } = useAudienceSetUp(
      (state: IAudienceSetUpState) =>  state
    );

  const filters = [
    {
      key: 'timeframe',
      label: 'TimeFrame',
      getValue: () =>
        timeFrameDDElements.find((tf) => tf.value === data.timeframe)?.label,
    },
    {
      key: 'dateRange',
      label: 'Created Date',
      getValue: () => `${data.dateRange[0]} - ${data.dateRange[1]}`,
    },
    {
      key: 'agency',
      label: 'Agency',
      getValue: () =>
        agencyDDElements
          .filter((a) => data.agency.includes(a.value))
          .map((a) => a.label)
          .join(', '),
    },
    {
      key: 'advertiser',
      label: 'Advertiser',
      getValue: () =>
        advertiserDDElements
          .filter((a) => data.advertiser && data.advertiser.includes(a.value))
          .map((a) => a.label)
          .join(', '),
    },
    {
      key: 'brand',
      label: 'Brand',
      getValue: () =>
        brandDDElements
          .filter((b) => data.brand && data.brand.includes(b.value))
          .map((b) => b.label)
          .join(', '),
    },
    {
      key: 'title',
      label: 'Title',
      getValue: () =>
        `${
          filterDDElements.find((f) => data.filter_option === f.value)?.label
        } " ${data.title} "`,
    },
    {
      key: 'username',
      label: 'Username',
      getValue: () =>
        `${
          filterDDElements.find((f) => data.filter_option === f.value)?.label
        } " ${data.username} "`,
      showIf: () => type === 'audit',
    },
    {
      key: 'status',
      label: 'Status',
      getValue: () =>
        statusDDElements
          .filter(
            (s) => data.status && data.status.includes(s.value.toString())
          )
          .map((s) => s.label)
          .join(', '),
      showIf: () => type === 'audience',
    },
    {
      key: 'audienceType',
      label: 'Type',
      getValue: () =>
        audienceTypeDDElements
          .filter(
            (t) => data.audienceType && data.audienceType.includes(t.value)
          )
          .map((t) => t.label)
          .join(', '),
      showIf: () => type === 'audience',
    },
    {
      key: 'partner',
      label: 'Partner',
      getValue: () =>
        sspDDElements
          .filter(
            (p) => data.partner && data.partner.includes(p.value.toString())
          )
          .map((p) => p.label)
          .join(', '),
      showIf: () => type === 'activation',
    },
    {
      key: 'action_name',
      label: 'Action',
      getValue: () =>
        actionDDElements
          .filter((p) => data.action_name && data.action_name.includes(p.value))
          .map((p) => p.label)
          .join(', '),
      showIf: () => type === 'audit',
    },
    {
      key: 'type',
      label: 'Type',
      getValue: () =>
        itemTypeElement
          .filter((p) => data.type && data.type.includes(p.value))
          .map((p) => p.label)
          .join(', '),
      showIf: () => type === 'audit',
    },
    {
      key: 'report_id',
      label: 'Report Types',
      getValue: () =>
        reportsDDElements
          .filter((r) => data.report_id && data.report_id.includes(r.value))
          .map((r) => r.label)
          .join(', '),
      showIf: () => type === 'research',
    },
  ];

  const activeFilters = filters.filter(
    (filter) => data[filter.key] && (!filter.showIf || filter.showIf())
  );

  const appliedFiltersCount = activeFilters.filter((filter) => {
    const shouldShow = filter.showIf ? filter.showIf() : true;

    const value = filter.getValue();
    return shouldShow && value;
  }).length;

  return (
    <div className="d-flex filteredByText">
      <div className="filteredItems">
        {appliedFiltersCount} applied{' '}
        <button onClick={()=> setEmptyFilter(true)}>
          <CloseOutlined />
        </button>
      </div>
    </div>
  );
};

export default FilterMessage;
