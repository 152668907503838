import Cookies from 'js-cookie';

export type UserType = {
  user_id: number | null;
  email: string | null;
  timestamp: number | null;
  user_role_id : number | null;
  user_token : string | null;
  profile_pic : string | null;
  first_name : string | null;
  last_name : string | null;
  user_account_id : number | null;
  privilege_set : string[] | null;
}

const useCookies = () => {
  const createCookie = (user: UserType) => {
    Cookies.set(
      'branch-lab',
      btoa(JSON.stringify({ ...user, timestamp: Date.now() })),
      {
        expires: Number(process.env.REACT_APP_SESSION_ACTIVE_DAY ?? 7),
      }
    );
  };

  const deleteCookie = () => {
    const token = Cookies.get('branch-lab');
    token && Cookies.remove('branch-lab');
  };

  return {
    createCookie,
    deleteCookie,
  };
};

export default useCookies;
