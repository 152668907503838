/* eslint-disable react-hooks/exhaustive-deps */
import { FC, Fragment, useEffect, useState } from 'react';
import {
  IAudienceSaveMethods,
  IAudienceState,
  ICheckIfDataModifiedState,
  IDependentTargetingSelectedState,
  IEachDropdownElementsType,
  IEnableSaveState,
  IFeatureSetDataCheckState,
  IFeatureSetDataState,
  IFeatureSetElement,
  IFeatureSetParamDatum,
  IGlobalFeatureSetBaseData,
  IMasterDmaDDState,
  IMasterGenderDDState,
  IMasterLangugageDDState,
  IMasterRaceAndEthnicityDDState,
  IMasterStateDDState,
  IMasterTargetingDDState,
  IMasterTimeFrameDDState,
} from '../../../../models/interfaces';
import { Select } from 'antd';
import {
  useCheckDependentTargetingSelectedStore,
  useCheckIfDataModifiedStore,
  useCheckSavingEnabilityStore,
  useFeatureSetDataCheckStore,
  useFeatureSetDataHandlingStore,
  useMasterGenderElementsStore,
  useMasterRaceAndEthnicityElementsStore,
  useMasterStateElementsStore,
  useMasterTargetingElementsStore,
  useMasterTimeFrameElementsStore,
} from '../../../../stores';
import { featureSetList } from './featureSetList';
import dayjs from 'dayjs';
import {
  useMasterDmaElementsStore,
  useMasterLanguageElementStore,
} from '../../../../stores/masterData.store';
import {
  useAudienceSaveMethods,
  useAudienceSets,
} from '../../../../stores/audienceSets.store';

const baseValues: IGlobalFeatureSetBaseData = {
  timeFrame: null,
  dateRange: null,
  patientGender: null,
  selectedAge: [],
  selectedRaceAndEthnicity: [],
  selectedGeographyDmas: [],
  selectedGeographyStates: [],
  language: [],
};

const GlobalFeatureSet: FC<{
  selectedAudienceType: null | number;
  featureSetNumber: number;
  featureSetOrderNumber: number;
  disabled?: boolean;
}> = ({
  selectedAudienceType,
  disabled,
  featureSetNumber,
  featureSetOrderNumber,
}) => {
  const { targetingDDElements } = useMasterTargetingElementsStore(
      (state: IMasterTargetingDDState) => state
    ),
    { timeFrameDDElements } = useMasterTimeFrameElementsStore(
      (state: IMasterTimeFrameDDState) => state
    ),
    { genderDDElements } = useMasterGenderElementsStore(
      (state: IMasterGenderDDState) => state
    ),
    { raceAndEthnicityDDElements } = useMasterRaceAndEthnicityElementsStore(
      (state: IMasterRaceAndEthnicityDDState) => state
    ),
    { stateDDElements } = useMasterStateElementsStore(
      (state: IMasterStateDDState) => state
    ),
    { languageDDElements } = useMasterLanguageElementStore(
      (state: IMasterLangugageDDState) => state
    ),
    { ifUpdated } = useFeatureSetDataCheckStore(
      (state: IFeatureSetDataCheckState) => state
    ),
    {
      updateIisFeatureSetsFilled,
      isEnableSaveForEachSet,
      updateIsEnableSaveForEachSet,
    } = useCheckSavingEnabilityStore((state: IEnableSaveState) => state),
    { dmaDDElements, updateDmaDDElements } = useMasterDmaElementsStore(
      (state: IMasterDmaDDState) => state
    ),
    { updateSavingDataSet, updatedDataSet } = useFeatureSetDataHandlingStore(
      (state: IFeatureSetDataState) => state
    ),
    { updateIsDependentTargetingSelectedForEachSet } =
      useCheckDependentTargetingSelectedStore(
        (state: IDependentTargetingSelectedState) => state
      ),
    { isSaveInitiated, setIsSaveInitiated } = useAudienceSaveMethods(
      (state: IAudienceSaveMethods) => state
    ),
    { setIfDataModified } = useCheckIfDataModifiedStore(
      (state: ICheckIfDataModifiedState) => state
    ),
    {
      updateGlobalFeature,
      isBacked,
      globalFeatureTargetting,
      setDeletedIds,
      deletedIds,
      audienceSummary,
      setFeatureSetDataForMessagePrev,
      featureSetDataForMessage,
      setFeatureSetDataForMessage,
    } = useAudienceSets((state: IAudienceState) => state);

  const [targetableOptions, setTargetableOptions] = useState<any>([]),
    [addedTargeting, setAddedTargeting] = useState<number[]>([]),
    [featureSetBody, setFeatureSetBody] = useState<IFeatureSetElement[]>([]),
    [featureSetValues, setFeatureSetValues] = useState(baseValues);

  const getHelper = () => [
    {
      key: 'timeFrame',
      savingId: 1,
      updatingId: 1,
      msgParams: {
        md: timeFrameDDElements,
        tol: 1,
      },
    },
    {
      key: 'dateRange',
      savingId: 17,
      updatingId: 1,
      msgParams: {
        tol: 0,
      },
    },
    {
      key: 'patientGender',
      savingId: 2,
      updatingId: 2,
      msgParams: {
        md: genderDDElements,
        tol: 1,
      },
    },
    {
      key: 'selectedAge',
      savingId: 3,
      updatingId: 3,
      msgParams: {
        tol: 0,
      },
    },
    {
      key: 'selectedRaceAndEthnicity',
      savingId: 4,
      updatingId: 4,
      msgParams: {
        md: raceAndEthnicityDDElements,
        tol: 2,
      },
    },
    {
      key: 'selectedGeographyStates',
      savingId: 5,
      updatingId: 5,
      msgParams: {
        md: stateDDElements,
        tol: 2,
      },
    },
    {
      key: 'selectedGeographyDmas',
      savingId: 15,
      updatingId: 15,
      msgParams: {
        md: dmaDDElements[`${featureSetNumber}`],
        tol: 2,
      },
    },
    {
      key: 'language',
      savingId: 19,
      updatingId: 19,
      msgParams: {
        md: languageDDElements[`${featureSetNumber}`],
        tol: 2,
      },
    },
  ];

  useEffect(() => {
    if (addedTargeting.includes(1)) {
      setFeatureSetDataForMessage({
        ...featureSetDataForMessage,
        timeFrame: formatDataToCreateMessage({
          data: featureSetValues.timeFrame,
          md: timeFrameDDElements,
          tol: 1,
        }),
        dateRange: formatDataToCreateMessage({
          data: featureSetValues.dateRange,
          tol: 0,
        }),
      });
    }
  }, [featureSetValues?.timeFrame]);

  useEffect(() => {
    if (addedTargeting.includes(2) && featureSetValues.patientGender) {
      setFeatureSetDataForMessage({
        ...featureSetDataForMessage,
        patientGender: formatDataToCreateMessage({
          data: featureSetValues.patientGender,
          md: genderDDElements,
          tol: 1,
        }),
      });
    }
  }, [featureSetValues?.patientGender]);

  useEffect(() => {
    if (addedTargeting.includes(3)) {
      setFeatureSetDataForMessage({
        ...featureSetDataForMessage,
        selectedAge: formatDataToCreateMessage({
          data: featureSetValues.selectedAge,
          tol: 0,
        }),
      });
    }
  }, [featureSetValues?.selectedAge]);

  useEffect(() => {
    if (
      addedTargeting.includes(4) &&
      featureSetValues.selectedRaceAndEthnicity
    ) {
      setFeatureSetDataForMessage({
        ...featureSetDataForMessage,
        selectedRaceAndEthnicity: formatDataToCreateMessage({
          data: featureSetValues.selectedRaceAndEthnicity,
          md: raceAndEthnicityDDElements,
          tol: 2,
        }),
      });
    }
  }, [featureSetValues?.selectedRaceAndEthnicity]);

  useEffect(() => {
    if (
      addedTargeting.includes(5) &&
      featureSetValues.selectedGeographyStates
    ) {
      setFeatureSetDataForMessage({
        ...featureSetDataForMessage,
        selectedGeographyStates: formatDataToCreateMessage({
          data: featureSetValues.selectedGeographyStates,
          md: stateDDElements,
          tol: 2,
        }),
      });
    }
  }, [featureSetValues?.selectedGeographyStates]);

  useEffect(() => {
    if (addedTargeting.includes(15) && featureSetValues.selectedGeographyDmas) {
      setFeatureSetDataForMessage({
        ...featureSetDataForMessage,
        selectedGeographyDmas: formatDataToCreateMessage({
          data: featureSetValues.selectedGeographyDmas,
          md: dmaDDElements,
          tol: 5,
        }),
      });
    }
  }, [featureSetValues?.selectedGeographyDmas]);

  useEffect(() => {
    if (addedTargeting.includes(19) && featureSetValues.language) {
      setFeatureSetDataForMessage({
        ...featureSetDataForMessage,
        language: formatDataToCreateMessage({
          data: featureSetValues.language,
          md: languageDDElements,
          tol: 2,
        }),
      });
    }
  }, [featureSetValues?.language]);

  useEffect(() => {
    updateDmaDDElements(featureSetNumber, [], stateDDElements);
  }, []);

  useEffect(() => {
    if (globalFeatureTargetting) {
      setAddedTargeting(globalFeatureTargetting[featureSetNumber].targeting ?? []);
      setFeatureSetValues(
        globalFeatureTargetting[featureSetNumber]?.featureSetValues
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isBacked) {
      updateGlobalFeature(
        featureSetNumber,
        addedTargeting,
        featureSetValues,
        0
      );
    }
  }, [isBacked]);

  useEffect(() => {
    if (ifUpdated && addedTargeting && featureSetValues) {
      setIfDataModified(true);
    }
  }, [addedTargeting]);

  useEffect(() => {
    if (targetableOptions.length === 0) {
      setTargetableOptions(targetingDDElements);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFeatureSetUpdates = (key: string, data: any) => {
    if (data) {
      if (Array.isArray(data)) {
        setFeatureSetValues((prev: any) => ({
          ...prev,
          [key]: [...data],
        }));
      } else {
        setFeatureSetValues((prev: any) => ({
          ...prev,
          [key]: data,
        }));
      }
    }
  };

  const handleSetDelete = (setIds: any[]) => {
    const newTargetingElemArray = addedTargeting.filter(
      (el) => !(setIds[0] === (el as number))
    );
    if (addedTargeting.find((el) => setIds[0] === (el as number))) {
      setDeletedIds(setIds[0]);
    }
    handleFeatureSetUpdates(setIds[1], null);
    setAddedTargeting(newTargetingElemArray);
    setFeatureSetValues((prev: any) => ({
      ...prev,
      [setIds[1]]: null,
    }));
    const oldTarget = targetingDDElements.filter(
      (tar: any) => tar.value === setIds[0]
    );
    setTargetableOptions([...oldTarget, ...targetableOptions]);
    let tempFeatureMsg: any = { ...featureSetDataForMessage };
    delete tempFeatureMsg[setIds[1]];
    setFeatureSetDataForMessage(tempFeatureMsg);
  };

  const getParams = () => {
    const parmas: IFeatureSetParamDatum[] = [
      {
        value: 1,
        values: [featureSetValues.timeFrame, featureSetValues.dateRange],
        names: ['timeFrame', 'dateRange'],
        handleFunctions: [],
        base: [baseValues.timeFrame, baseValues.dateRange],
        param: {
          setId: 1,
          featureSetNumber,
          label: 'Timeframe',
          isMandatory: false,
          options: timeFrameDDElements,
          placeholder: 'Select Timeframe',
          isSearchable: false,
          timeFrame: featureSetValues.timeFrame,
          dateRange: featureSetValues.dateRange,
          setTimeFrame: (data: number) => {
            handleFeatureSetUpdates('timeFrame', data);
          },
          setDateRange: (data: string[]) => {
            handleFeatureSetUpdates('dateRange', data);
          },
          handleSetDelete,
          minCustomDate: dayjs('2022-01-01'),
          isDisabled: disabled,
        },
      },
      {
        value: 2,
        values: [featureSetValues.patientGender],
        names: ['patientGender'],
        handleFunctions: [],
        base: [baseValues.patientGender],
        param: {
          setId: 2,
          featureSetNumber,
          label: 'Patient Gender',
          isMandatory: false,
          options: genderDDElements,
          placeholder: 'Select Patient Gender',
          isSearchable: false,
          isDisabled: disabled,
          onChange: (key: string, data: any) =>
            handleFeatureSetUpdates('patientGender', data.value ?? data),
          otherProps: { defaultValue: featureSetValues.patientGender },
          handleSetDelete,
        },
      },
      {
        value: 3,
        values: [featureSetValues.selectedAge],
        handleFunctions: [],
        names: ['selectedAge'],
        base: [baseValues.selectedAge],
        param: {
          setId: 3,
          featureSetNumber,
          label: 'Patient Age',
          isMandatory: false,
          defaultValue: featureSetValues.selectedAge?.length
            ? featureSetValues.selectedAge
            : baseValues.selectedAge,
          onChange: (data: any) => handleFeatureSetUpdates('selectedAge', data),
          handleSetDelete,
          isDisabled: disabled,
        },
      },
      {
        value: 4,
        values: [featureSetValues.selectedRaceAndEthnicity],
        names: ['selectedRaceAndEthnicity'],
        handleFunctions: [],
        base: [baseValues.selectedRaceAndEthnicity],
        param: {
          setId: 4,
          featureSetNumber,
          label: 'Ethnicity',
          isMandatory: false,
          options: raceAndEthnicityDDElements,
          placeholder: 'Select Ethnicity',
          isSearchable: true,
          onChange: (data: any) =>
            handleFeatureSetUpdates('selectedRaceAndEthnicity', data),
          handleSetDelete,
          otherProperties: {
            defaultValue: featureSetValues.selectedRaceAndEthnicity,
          },
          isDisabled: disabled,
        },
      },
      {
        value: 5,
        values: [featureSetValues.selectedGeographyStates],
        names: ['selectedGeographyStates'],
        base: [baseValues.selectedGeographyStates],
        handleFunctions: [],
        param: {
          setId: 5,
          featureSetNumber,
          label: 'State',
          isMandatory: false,
          options: stateDDElements,
          placeholder: 'Select State',
          isSearchable: true,
          onChange: (data: any) =>
            handleFeatureSetUpdates('selectedGeographyStates', data),
          handleSetDelete,
          otherProperties: {
            defaultValue: featureSetValues.selectedGeographyStates,
          },
          isDisabled: disabled,
        },
      },
      {
        value: 15,
        values: [featureSetValues.selectedGeographyDmas],
        names: ['selectedGeographyDmas'],
        handleFunctions: [],
        base: [baseValues.selectedGeographyDmas],
        param: {
          setId: 15,
          label: 'DMA',
          isMandatory: false,
          isSearchable: true,
          onChange: (data: any) =>
            handleFeatureSetUpdates('selectedGeographyDmas', data),
          handleSetDelete,
          value: featureSetValues.selectedGeographyDmas,
          featureSetNumber,
          isDisabled: disabled,
        },
      },
      {
        value: 19,
        values: [featureSetValues.language],
        names: ['language'],
        base: [baseValues.language],
        handleFunctions: [],
        param: {
          setId: 19,
          featureSetNumber,
          label: 'Lanuguage',
          isMandatory: false,
          options: languageDDElements,
          placeholder: 'Select Language',
          isSearchable: true,
          onChange: (data: any) => handleFeatureSetUpdates('language', data),
          handleSetDelete,
          otherProperties: {
            defaultValue: featureSetValues.language,
          },
          isDisabled: disabled,
        },
      },
    ];
    return parmas;
  };

  useEffect(() => {
    if (deletedIds?.length) {
      setDeletedIds(null);
      const params = getParams(),
        selectedParam: IFeatureSetParamDatum[] = params.filter((el: any) =>
          deletedIds?.includes(el.value)
        ),
        tempFeatureSetDataForMessage = { ...featureSetDataForMessage },
        dependentTargets = [6, 7, 8];

      selectedParam?.length &&
        selectedParam.forEach((eachParam: IFeatureSetParamDatum) => {
          if (dependentTargets.includes(eachParam.value)) {
            updateIsDependentTargetingSelectedForEachSet(
              featureSetNumber,
              false
            );
          }

          eachParam.handleFunctions.forEach((el: any, index: number) => {
            el(eachParam.base[index]);
            if (
              Object.keys(featureSetValues).includes(eachParam.names[index])
            ) {
              delete tempFeatureSetDataForMessage[eachParam.names[index]];
            }
          });
        });
      setFeatureSetDataForMessage({ ...tempFeatureSetDataForMessage });
    }
    if (addedTargeting?.length) {
      if (addedTargeting.includes(3) && !featureSetValues.selectedAge?.length) {
        handleFeatureSetUpdates('selectedAge', baseValues.selectedAge);
      }

      const params = getParams(),
        updatedElem: IFeatureSetElement[] = [],
        dependentTargets = [6, 7, 8];

      updateIsDependentTargetingSelectedForEachSet(
        featureSetNumber,
        Boolean(addedTargeting.find((el) => dependentTargets.includes(el)))
      );

      addedTargeting.forEach((el: number | string, index: number) => {
        updatedElem.push({
          setId: el as number,
          orderId: index,
          element: featureSetList
            .find((elem) => elem.value === (el as number))
            ?.component(
              params.find((elem) => elem.value === (el as number))?.param
            ),
        });
      });
      setFeatureSetBody(updatedElem);
    } else {
      setFeatureSetBody([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedTargeting]);

  useEffect(() => {
    if (addedTargeting?.length) {
      handleAudienceType();

      const updatedElem: IFeatureSetElement[] = [],
        params = getParams();

      addedTargeting.forEach((el: number | string, index: number) => {
        updatedElem.push({
          setId: el as number,
          orderId: index,
          element: featureSetList
            .find((elem) => elem.value === (el as number))
            ?.component(
              params.find((elem: any) => elem.value === (el as number))?.param
            ),
        });
      });

      setFeatureSetBody(updatedElem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedTargeting]);

  const handleAudienceType = () => {
    const tempUsableData = targetingDDElements.map(
      (el: IEachDropdownElementsType) => {
        if (el.enableId === selectedAudienceType) {
          return {
            ...el,
            disabled: false,
          };
        }

        if (el.enableId) {
          return {
            ...el,
            disabled: true,
          };
        }

        return {
          ...el,
        };
      }
    );
    const sortedById = tempUsableData.sort(
        (a: any, b: any) => a.order_id - b.order_id
      ),
      sortedEnabledElems = sortedById.filter(
        (el: any) => !el.disabled && el.is_global
      );
    const newElement = sortedEnabledElems.filter(
      (target: any) => !addedTargeting.includes(target.value)
    );
    setTargetableOptions(newElement);
  };

  const performValidations = () => {
    validationForNonArray(1, featureSetValues.timeFrame);
    validationDateRange();
    validationForNonArray(2, featureSetValues.patientGender);
    validationForArray(4, featureSetValues.selectedRaceAndEthnicity);
    validationForArray(5, featureSetValues.selectedGeographyStates);
    validationForArray(15, featureSetValues.selectedGeographyDmas);
    validationForArray(19, featureSetValues.language);
  };

  const validationForNonArray = (tId: number, data: any) => {
    if (addedTargeting.includes(tId)) {
      updateIisFeatureSetsFilled(featureSetNumber, tId, !data ? 1 : 2);
      if (!data) {
        setIsSaveInitiated(false);
      }
      return;
    }
    updateIisFeatureSetsFilled(featureSetNumber, tId, 0);
  };

  const validationForArray = (tId: number, data: any) => {
    if (addedTargeting.includes(tId)) {
      updateIisFeatureSetsFilled(featureSetNumber, tId, !data?.length ? 1 : 2);
      if (!data.length) {
        setIsSaveInitiated(false);
      }
      return;
    }
    updateIisFeatureSetsFilled(featureSetNumber, tId, 0);
  };

  const validationDateRange = () => {
    if (
      addedTargeting.includes(1) &&
      featureSetValues.timeFrame === 3 &&
      !featureSetValues.dateRange &&
      isSaveInitiated
    ) {
      updateIisFeatureSetsFilled(
        featureSetNumber,
        17,
        !featureSetValues.dateRange ? 1 : 2
      );
      if (!featureSetValues.dateRange) {
        setIsSaveInitiated(false);
      }
      return;
    }
    updateIisFeatureSetsFilled(featureSetNumber, 17, 0);
  };

  useEffect(() => {
    updateIsEnableSaveForEachSet(featureSetNumber, checkSaveEnabled());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedTargeting, featureSetValues]);

  useEffect(() => {
    performValidations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkSaveEnabled = () => {
    switch (true) {
      case !addedTargeting?.length:
        return 0;
      case addedTargeting.includes(1) &&
        (!featureSetValues.timeFrame ||
          (featureSetValues.timeFrame === 3 && !featureSetValues.dateRange)):
        return 1;
      case addedTargeting.includes(2) && !featureSetValues.patientGender:
      case addedTargeting.includes(4) &&
        !featureSetValues.selectedRaceAndEthnicity?.length:
      case addedTargeting.includes(5) &&
        !featureSetValues.selectedGeographyStates?.length:
      case addedTargeting.includes(15) &&
        !featureSetValues.selectedGeographyDmas?.length:
      case addedTargeting.includes(19) && !featureSetValues.language?.length:
        return 1;
      default:
        return 2;
    }
  };

  useEffect(() => {
    handleAudienceType();
  }, [selectedAudienceType]);

  const handleTargetableOptions = (value: number) => {
    if (value === 3) {
      let tempFeatureValue = { ...featureSetValues };
      tempFeatureValue['selectedAge'] = [18, 100];
      setFeatureSetValues(tempFeatureValue);
    }
    setAddedTargeting((prev) => [...prev, value]);
    let tempOptions = targetableOptions.filter(
      (opt: any) => opt.order_id !== value
    );
    setTargetableOptions(tempOptions);
  };

  useEffect(() => {
    const newDmaList = dmaDDElements[`${featureSetNumber}`]
      ? [...dmaDDElements[`${featureSetNumber}`]]
      : [];

    if (featureSetValues.selectedGeographyDmas?.length) {
      const newSelectedDma = newDmaList
        .filter((el) =>
          featureSetValues.selectedGeographyDmas.includes(el.value)
        )
        ?.map((el) => el.value);
      handleFeatureSetUpdates('selectedGeographyDmas', newSelectedDma);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dmaDDElements]);

  const getFormattedData = (message?: string) => {
    const tempData: any = {
        ...featureSetValues,
      },
      allUpdatingFeatureSetIds = updatedDataSet?.feature_sets?.map(
        (el: any) => el.feature_set_id
      );
    let allUpdatingTargetingIds: any = {};
    updatedDataSet?.feature_sets?.forEach((el: any) => {
      if (el.feature_set_id === featureSetNumber) {
        el.targeting.forEach(
          (elem: any) =>
            (allUpdatingTargetingIds = {
              ...allUpdatingTargetingIds,
              [elem.targeting_id]: elem.targeting_set_id,
            })
        );
      }
    });
    let newFeatureSetData: any = {
        feature_set_id: allUpdatingFeatureSetIds?.includes(featureSetNumber)
          ? featureSetNumber
          : 0,
        feature_set_summary: audienceSummary ?? '',
        feature_set_rank: featureSetOrderNumber,
        condition_id: 0,
        is_global: true,
      },
      targeting = [],
      duals: any = {
        dateRange: 17,
        selectedInsuranceProviders: 16,
      };

    for (const key in featureSetValues) {
      if (
        !(
          !tempData[key] ||
          (Array.isArray(tempData[key]) && tempData[key].length < 1) ||
          (key === 'timeFrame' &&
            featureSetValues.timeFrame === 3 &&
            !featureSetValues.dateRange)
        )
      ) {
        const params = getParams(),
          targetingId = params.find((el: IFeatureSetParamDatum) =>
            el.names.includes(key as any)
          )?.value,
          usableTargetingId = Object.keys(duals).includes(key)
            ? duals[key]
            : targetingId,
          usableTargetingSetId = Object.keys(allUpdatingTargetingIds).includes(
            `${usableTargetingId}`
          )
            ? allUpdatingTargetingIds[`${usableTargetingId}`]
            : 0;

        targeting.push({
          targeting_set_id: usableTargetingSetId,
          targeting_id: usableTargetingId,
          targeting_value: tempData[key],
          targeting_set_rank: addedTargeting.indexOf(targetingId as number) + 1,
        });
      }
    }

    if (isSaveInitiated && Object.keys(newFeatureSetData)?.length) {
      updateSavingDataSet(featureSetNumber, {
        ...newFeatureSetData,
        targeting,
      });
    }
  };

  useEffect(() => {
    if (isSaveInitiated) {
      const isEnableValues = Object.values(isEnableSaveForEachSet);
      performValidations();
      if (isEnableValues.includes(1)) {
        setIsSaveInitiated(false);
        return;
      }
      if (addedTargeting?.length) {
        getFormattedData();
      } else {
        updateSavingDataSet(featureSetNumber, {});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaveInitiated]);

  const getMessageDataWhileUpdate = (id: number, data: any) => {
    switch (id) {
      case 7: {
        const tempData = data.map((el: any) => el.displayName);
        return tempData;
      }
      case 9:
      case 10: {
        const tempData = data.map((el: any) => el.label);
        return tempData;
      }
      default:
        return data;
    }
  };

  useEffect(() => {
    if (ifUpdated && updatedDataSet?.feature_sets?.length && !globalFeatureTargetting) {
      const featureSet = updatedDataSet.feature_sets.find(
        (el: any) => el.feature_set_id === featureSetNumber
      );

      if (featureSet) {
        const params = getParams(),
          duals: any = { 17: 1, 16: 12 },
          targetingSet = featureSet.targeting.sort(
            (a: any, b: any) => a.targeting_id - b.targeting_id
          );

        let newTargetingSet: any = [];
        let tempFeatureSetDataForMessage = {};

        targetingSet.forEach((el: any) => {
          const keys = Object.keys(duals);

          if (keys.includes(el.targeting_id.toString())) {
            const index: number = newTargetingSet.findIndex(
              (elem: any) => elem.targeting_id === duals[`${el.targeting_id}`]
            );

            newTargetingSet[index] = {
              ...newTargetingSet[index],
              targeting_value: [
                ...newTargetingSet[index].targeting_value,
                JSON.parse(el.targeting_value),
              ],
              uniqueIds: [...newTargetingSet[index].uniqueIds, el.targeting_id],
            };
          } else {
            newTargetingSet.push({
              ...el,
              targeting_value:
                el.targeting_id !== 14
                  ? [JSON.parse(el.targeting_value)]
                  : el.targeting_value,
              uniqueIds: [el.targeting_id],
            });
          }
        });

        const usableTargetingSet = newTargetingSet.sort(
          (a: any, b: any) => a.targeting_set_rank - b.targeting_set_rank
        );

        usableTargetingSet.forEach((el: any) => {
          const param = params.find(
            (param: IFeatureSetParamDatum) => param.value === el.targeting_id
          );
          if (param) {
            const helper = getHelper(),
              temp: any = helper.find((h: any) =>
                el.uniqueIds.includes(h.savingId)
              )?.msgParams ?? { tol: 0 };

            const onChange = param?.param?.onChange;
            if (onChange !== undefined) {
              if (el.uniqueIds[0] === 2) {
                tempFeatureSetDataForMessage = {
                  ...tempFeatureSetDataForMessage,
                  [param.names[0]]: formatDataToCreateMessage({
                    data: getMessageDataWhileUpdate(
                      el.targeting_id,
                      el.targeting_value[0]
                    ),
                    ...temp,
                  }),
                };
                onChange(param.names[0], el.targeting_value[0]);
              } else {
                tempFeatureSetDataForMessage = {
                  ...tempFeatureSetDataForMessage,
                  [param.names[0]]: formatDataToCreateMessage({
                    data: getMessageDataWhileUpdate(
                      el.targeting_id,
                      el.targeting_value[0]
                    ),
                    ...temp,
                  }),
                };
                onChange(el.targeting_value[0]);
              }
            } else {
              if (el.uniqueIds[0] === 1) {
                tempFeatureSetDataForMessage = {
                  ...tempFeatureSetDataForMessage,
                  [param.names[0]]: formatDataToCreateMessage({
                    data: getMessageDataWhileUpdate(
                      el.targeting_id,
                      el.targeting_value[0]
                    ),
                    ...temp,
                  }),
                };
                tempFeatureSetDataForMessage = {
                  ...tempFeatureSetDataForMessage,
                  [param.names[1]]: formatDataToCreateMessage({
                    data: getMessageDataWhileUpdate(
                      el.targeting_id,
                      el.targeting_value[1]
                    ),
                    ...temp,
                  }),
                };
                param.param.setTimeFrame(el.targeting_value[0]);
                param.param.setDateRange(el.targeting_value[1]);
              }
            }
          }
        });
        setFeatureSetDataForMessagePrev(tempFeatureSetDataForMessage);
        setFeatureSetDataForMessage(tempFeatureSetDataForMessage);
        setAddedTargeting(usableTargetingSet.map((el: any) => el.targeting_id) ?? []);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="featureSetWrapElement globalFeatureSetWrapElement">
      <div className="featureSetHeadingWrap">
        <div>Global Variables</div>
      </div>
      <div>
        {addedTargeting.length > 0 && (
          <div>
            {featureSetBody.map((el: IFeatureSetElement) => (
              <Fragment key={`${el.orderId}_${el.setId}`}>
                {el.element}
              </Fragment>
            ))}
          </div>
        )}
        {!disabled && targetableOptions.length > 0 && (
          <Select
            className="customAntSelect"
            options={targetableOptions}
            placeholder="Select Targetable Feature"
            onChange={(data: number) => handleTargetableOptions(data)}
            value={null}
          />
        )}
      </div>
    </div>
  );
};

const formatDataToCreateMessage = (params: {
  data: any;
  md?: any;
  tol: 0 | 1 | 2 | 3 | 4 | 5;
  parentId?: any;
  ck?: string;
}) => {
  const { data, md, tol, parentId, ck } = params;

  switch (tol) {
    // Single select
    case 1: {
      return (
        md.find((el: IEachDropdownElementsType) => el.value === data)?.label ??
        ''
      );
    }
    // Multi Select
    case 2: {
      return (
        md &&
        md
          .filter((el: IEachDropdownElementsType) => data.includes(el.value))
          ?.map((el: IEachDropdownElementsType) => el.label)
      );
    }
    // Dual selector
    case 3: {
      try {
        const typeOfSelectedParent = Array.isArray(parentId);
        if (
          ((typeOfSelectedParent && parentId.length) ||
            (!typeOfSelectedParent && parentId)) &&
          ck
        ) {
          const childMasterDataNested = md
              .filter((el: any) =>
                !typeOfSelectedParent
                  ? el.value === parentId
                  : parentId.includes(el.value)
              )
              .map((el: any) => el[ck]),
            childMasterData = childMasterDataNested.concat(
              ...childMasterDataNested
            );

          return childMasterData
            .filter((el: IEachDropdownElementsType) => data.includes(el.value))
            ?.map((el: IEachDropdownElementsType) => el.label);
        }
        return null;
      } catch (error) {
        return null;
      }
    }
    case 4: {
      if (data?.length) {
        return 'All';
      }
      return null;
    }
    case 5: {
      const labelMap: any = Object.values(md)
        .flat()
        .reduce((acc: any, item: any) => {
          acc[item.value] = item.label;
          return acc;
        }, {});
      return data.map((item: any) => labelMap[item] || null);
    }
    default: {
      return data;
    }
  }
};

export default GlobalFeatureSet;
