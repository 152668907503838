/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  ModalComponent,
  PageHeader,
} from '../../../../components/sharedComponent';
import DefaultFeatureSet from './DefaultFeatureSet';
import {
  useCheckIfDataModifiedStore,
  useCheckSavingEnabilityStore,
  useFeatureSetDataCheckStore,
  useFeatureSetDataHandlingStore,
  useLoaderStore,
  useMasterAdditionalOptionElementsStore,
  useMasterAudienceTypeElementsStore,
} from '../../../../stores';
import {
  IAudienceSaveMethods,
  IAudienceSetUpState,
  IAudienceState,
  ICheckIfDataModifiedState,
  IEnableSaveState,
  IFeatureSetDataCheckState,
  IFeatureSetDataState,
  ILoaderState,
  IMasterAdditionalOptionDDState,
  IMasterAdvertiserDDState,
  IMasterAgencyDDState,
  IMasterAudienceTypeDDState,
  IMasterBrandDDState,
  IMasterTargetingDDState,
} from '../../../../models/interfaces';
import { Button, Dropdown, MenuProps } from 'antd';
import TargetableFeatureSetup from './TargetableFeatureSetup';
import {
  useAudienceSaveMethods,
  useAudienceSetUp,
  useAudienceSets,
} from '../../../../stores/audienceSets.store';
import { CheckOutlined } from '@ant-design/icons';
import { useRedirection, useNotificationMessage } from '../../../../hooks';
import { useParams } from 'react-router-dom';
import AudienceInsightsWrap from './AudienceInsights/AudienceInsightsWrap';
import { barChart, chevDown } from '../../../../assets/images';
import {
  useMasterAdvertiserElmentStore,
  useMasterAgencyElmentStore,
  useMasterBrandElmentStore,
  useMasterTargetingElementsStore,
} from '../../../../stores/masterData.store';

type SavingOptionType = {
  key: '1' | '2' | '3' | '4';
  label:
    | 'Save and Exit'
    | 'Save and Activate'
    | 'Save and New'
    | 'Save and Duplicate';
  messagecontent: string;
};
export const savingOptions: SavingOptionType[] = [
  {
    key: '1',
    label: 'Save and Exit',
    messagecontent: 'Audience is saved',
  },
  {
    key: '2',
    label: 'Save and New',
    messagecontent: 'Audience is saved',
  },
];

const AudienceSetupFieldsWrap = () => {
  const { id } = useParams();
  const [selectedAudienceType, setSelectedAudienceType] = useState<
      number | null
    >(null),
    [targetingList, setTargetingList] = useState<number[] | null>(null),
    [modalToOpenForCancel, setModalToOpenForCancel] = useState<boolean>(false),
    [selectedAdditionalOpts, setSelectedAdditionalOpts] = useState<
      (number | string)[]
    >([]),
    [focusOnTitle, setFocusOnTitle] = useState<0 | 1 | 2>(0),
    [messageObj, setMessageObj] = useState<any>({ isShowing: false });

  const { audienceTypeDDElements } = useMasterAudienceTypeElementsStore(
      (state: IMasterAudienceTypeDDState) => state
    ),
    { agencyDDElements } = useMasterAgencyElmentStore(
      (state: IMasterAgencyDDState) => state
    ),
    { brandDDElements } = useMasterBrandElmentStore(
      (state: IMasterBrandDDState) => state
    ),
    { advertiserDDElements } = useMasterAdvertiserElmentStore(
      (state: IMasterAdvertiserDDState) => state
    ),
    { additionalOptionDDElements } = useMasterAdditionalOptionElementsStore(
      (state: IMasterAdditionalOptionDDState) => state
    ),
    { ifDataModified } = useCheckIfDataModifiedStore(
      (state: ICheckIfDataModifiedState) => state
    ),
    { showBlankLoaderState, loaderState } = useLoaderStore(
      (state: ILoaderState) => state
    ),
    {
      advertiser,
      setAdvertiser,
      audiencePageNumber,
      setAudiencePageNumber,
      audienceTitle,
      audienceBrand,
      audienceAgency,
      setAudienceAgency,
      setAudiencebrand,
      setAudienceTitle,
    } = useAudienceSetUp((state: IAudienceSetUpState) => state),
    { setIsSaveInitiated } = useAudienceSaveMethods(
      (state: IAudienceSaveMethods) => state
    ),
    {
      isClone,
      setIsClone,
      isDataSaved,
      selectedSavingOpt,
      setSelectedSavingOpt,
      setGlobalFeatureData,
      setGlobalFeatureTargetting,
      setGlobalFeatureCount,
      setAudienceSummary,
      isBacked,
      setIsBacked,
      setIsCollapsed,
      isCollapsed,
      setFeatureSetDataForMessagePrev,
      setFeatureSetDataForMessage,
      setRemoveUpdatedFeatureSet,
    } = useAudienceSets((state: IAudienceState) => state),
    { updatedDataSet, setUpdatedDataSet } = useFeatureSetDataHandlingStore(
      (state: IFeatureSetDataState) => state
    ),
    { ifUpdated } = useFeatureSetDataCheckStore(
      (state: IFeatureSetDataCheckState) => state
    ),
    { setIisFeatureSetsFilled } = useCheckSavingEnabilityStore(
      (state: IEnableSaveState) => state
    ),
    {
      targetingDDElements,
      targetingDDElementsForFs,
      setTargetingDDElementsForFs,
      updateTargetingDDElementsForFsDtc,
      updateTargetingDDElementsForFsNonDtc,
    } = useMasterTargetingElementsStore(
      (state: IMasterTargetingDDState) => state
    );
  const { redirectAudienceListing } = useRedirection();

  const { context, destroyMessage } = useNotificationMessage(messageObj);

  useEffect(() => {
    return () => {
      setAudienceTitle('');
      setSelectedAudienceType(null);
      // setAudiencePageNumber(1);
      setIisFeatureSetsFilled({});
      setAudienceAgency(null);
      setAudiencebrand(null);
      setAdvertiser(null);
      setGlobalFeatureTargetting(null);
      setGlobalFeatureCount(0);
      setGlobalFeatureData(null);
    };
  }, []);

  useEffect(() => {
    setAudienceSummary('');
    setAudiencePageNumber(1);
  }, []);

  // Store
  useEffect(() => {
    if (targetingDDElements?.length && !targetingDDElementsForFs?.length) {
      const tempDdElem = [...targetingDDElements],
        usableDdElem = tempDdElem.filter((el: any) => !el.is_global);

      usableDdElem.sort((a: any, b: any) => a.order_id - b.order_id);

      setTargetingDDElementsForFs(usableDdElem);

      if (targetingList) {
        updateTargetingDDElementsForFsDtc(targetingList);
      }

      if (selectedAudienceType) {
        updateTargetingDDElementsForFsNonDtc(selectedAudienceType);
      }
    }

    return () => {
      if (targetingDDElementsForFs?.length) {
        setTargetingDDElementsForFs([]);
      }
    };
  }, [targetingDDElements]);

  useEffect(() => {
    if (selectedAudienceType && targetingDDElementsForFs?.length) {
      updateTargetingDDElementsForFsNonDtc(selectedAudienceType);
    }
  }, [selectedAudienceType]);

  const handleCancel = () => {
    if (ifDataModified) {
      setModalToOpenForCancel(true);
    } else {
      setAudienceTitle('');
      setAudienceAgency('');
      setAudiencebrand('');
      redirectAudienceListing();
      setSelectedAudienceType(null);
      setGlobalFeatureTargetting(null);
      setGlobalFeatureCount(0);
      setGlobalFeatureData(null);
    }
  };

  const onSavingMenuClick: MenuProps['onClick'] = (e) => {
    destroyMessage('save');
    setMessageObj({ isShowing: false });
    setSelectedSavingOpt(e.key);
    setIsSaveInitiated(true);
  };

  const getSaveLabel = () => {
    if (isDataSaved) {
      return (
        <>
          <CheckOutlined /> Saved
        </>
      );
    }
    if (showBlankLoaderState) {
      return <>Saving</>;
    }
    return <>Continue</>;
  };

  const handleBack = () => {
    setIsBacked(true);
  };

  useEffect(() => {
    if (ifUpdated) {
      const {
        title,
        audience_type_id,
        additional_option_id,
        advertiser_id: advertiser,
        agency_id: audience_agency,
        brand_id: audience_brand,
        feature_sets,
      } = updatedDataSet;
      setAudienceSummary(
        feature_sets.length ? feature_sets[0].feature_set_summary : ''
      );
      setAdvertiser(advertiser);
      setAudienceAgency(audience_agency);
      setAudiencebrand(audience_brand);
      setAudienceTitle(title);
      getTargetingList(feature_sets);
      setSelectedAudienceType(audience_type_id);
      setSelectedAdditionalOpts(additional_option_id);
    }
  }, [ifUpdated]);

  const getTargetingList = (fs: any) => {
    const tl: number[] = [];

    fs.forEach((el: any) => {
      if (!el.is_global) {
        tl.push(...el.targeting.map((elem: any) => elem.targeting_id));
      }
    });

    if (targetingDDElementsForFs?.length) {
      updateTargetingDDElementsForFsDtc(tl);
    }

    setTargetingList(tl);
  };

  const handleModalCancel = () => {
    setAudienceTitle('');
    setAudienceAgency('');
    setAudiencebrand('');
    setAdvertiser('');
    redirectAudienceListing();
    setSelectedAudienceType(null);
    setGlobalFeatureTargetting(null);
    setGlobalFeatureCount(0);
    setGlobalFeatureData(null);
    setAudiencePageNumber(1);
  };

  const handleContinue = () => {
    setAudiencePageNumber(audiencePageNumber + 1);
  };

  const handleBackConfirm = () => {
    setIsBacked(false);
    setAudiencePageNumber(audiencePageNumber - 1);
    setGlobalFeatureTargetting(null);
    setGlobalFeatureCount(0);
    setGlobalFeatureData(null);
    if(updatedDataSet){
      delete updatedDataSet.feature_sets
      setRemoveUpdatedFeatureSet(true)
      setUpdatedDataSet(updatedDataSet);
      setFeatureSetDataForMessagePrev({})
      setFeatureSetDataForMessage({})
    }
  };

  const cancelBack = () => {
    setAudiencePageNumber(audiencePageNumber - 1);
    setIsBacked(false);
  };

  return (
    <>
      <div className="audienceCommonWrap">
        {context}
        <PageHeader
          title={
            id && !isClone ? (
              <>
                Update: <span className="lessOpacityText">{audienceTitle}</span>
              </>
            ) : (
              'Create Audience'
            )
          }
        />
        {!loaderState && (
          <>
            {audiencePageNumber === 1 && (
              <div>
                <DefaultFeatureSet
                  defaultTitle={audienceTitle}
                  setTitle={setAudienceTitle}
                  audienceType={selectedAudienceType}
                  setAudienceType={(value: any) =>
                    setSelectedAudienceType(value)
                  }
                  advertiser={advertiser}
                  setAdvertiser={setAdvertiser}
                  defaultAgency={audienceAgency}
                  setAgency={setAudienceAgency}
                  defaultBrand={audienceBrand}
                  agencyDDElements={agencyDDElements}
                  brandDDElements={brandDDElements}
                  advertiserDDElements={advertiserDDElements}
                  setBrand={setAudiencebrand}
                  selectedAdditionalOpts={selectedAdditionalOpts}
                  setSelectedAdditionalOpts={setSelectedAdditionalOpts}
                  audienceTypeDDElements={audienceTypeDDElements}
                  additionalOptionDDElements={additionalOptionDDElements}
                  focusOnTitle={focusOnTitle}
                />
              </div>
            )}
            {audiencePageNumber === 2 && (
              <div className="activationRightPanel">
                <div className="targetableFeatureAndAudienceInsight">
                  <TargetableFeatureSetup
                    setIsClone={setIsClone}
                    setMessageObj={setMessageObj}
                    setFocusOnTitle={setFocusOnTitle}
                    selectedAudienceType={selectedAudienceType}
                    selectedAdditionalOpts={selectedAdditionalOpts}
                    setSelectedAudienceType={setSelectedAudienceType}
                    setModalToOpenForCancel={setModalToOpenForCancel}
                    setSelectedAdditionalOpts={setSelectedAdditionalOpts}
                  />
                </div>
                <AudienceInsightsWrap />
              </div>
            )}
          </>
        )}
      </div>
      <div className="newFeatureSetBtn">
        {audiencePageNumber !== 1 && (
          <button
            className="responsiveCTAForRightPanel"
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            <img src={barChart} alt="" />
          </button>
        )}

        <div className="editAudienceNewFeatureSetBtn">
          <ModalComponent
            modalHeader={
              'Cancel ' + (id && !isClone ? 'Update' : 'Create') + ' Audience'
            }
            modalToOpen={modalToOpenForCancel}
            setModalToOpen={setModalToOpenForCancel}
            deleteSetParams={{
              name:
                'Cancel ' +
                (id && !isClone ? 'Update' : 'Create') +
                ' Audience',
              handelOk: handleModalCancel,
              message:
                'You’re about to lose any unsaved changes. Do you want to continue?',
              okText: 'Yes',
              cancelText: 'No',
            }}
          />
          <ModalComponent
            modalHeader={
              'Discard ' + (id && !isClone ? 'Update' : 'Create') + ' Audience'
            }
            modalToOpen={isBacked}
            setModalToOpen={setIsBacked}
            deleteSetParams={{
              name:
                'Cancel ' +
                (id && !isClone ? 'Update' : 'Create') +
                ' Audience',
              handelOk: handleBackConfirm,
              message:
                "You're about to discard this changes. If you proceed, any information you've entered will not be saved. Do you wish to continue?",
              okText: 'Yes',
              cancelText: 'No',
              handleCancel: cancelBack,
            }}
          />
          <Button
            type="text"
            size="large"
            onClick={handleCancel}
            className="noBGBtn"
          >
            Cancel
          </Button>
          {audiencePageNumber === 2 && (
            <Button
              size="large"
              onClick={handleBack}
              className="secondaryBtn backBtn"
            >
              Back
            </Button>
          )}
          {audiencePageNumber === 1 && (
            <Button
              type="primary"
              size="large"
              className="dropdownSaveButton primaryBtn"
              onClick={handleContinue}
              disabled={
                !(
                  selectedAudienceType &&
                  audienceTitle &&
                  audienceAgency &&
                  audienceBrand &&
                  advertiser
                )
              }
            >
              Continue
            </Button>
          )}
          {audiencePageNumber === 2 && (
            <Dropdown.Button
              trigger={['click']}
              type="primary"
              size="large"
              className="dropdownSaveButton"
              icon={<img src={chevDown} alt="arrow" />}
              loading={!isDataSaved && showBlankLoaderState}
              onClick={() => {
                setIsSaveInitiated(true);
                setSelectedSavingOpt('');
              }}
              disabled={
                !(
                  selectedAudienceType &&
                  audienceTitle &&
                  audienceAgency &&
                  audienceBrand &&
                  advertiser
                )
              }
              menu={{
                selectedKeys: [selectedSavingOpt],
                items: savingOptions,
                onClick: onSavingMenuClick,
              }}
            >
              {getSaveLabel()}
            </Dropdown.Button>
          )}
        </div>
      </div>
    </>
  );
};

export default AudienceSetupFieldsWrap;
