import { FC, Fragment } from 'react';
import { SelectProps, Tag } from 'antd';
import { CommonElementLabel, Multi } from '..';
import { IEachDropdownElementsType } from '../../../models/interfaces';

type TagRender = SelectProps['tagRender'];

const StatusDropdownMulti: FC<{
  label: string;
  isMandatory: boolean;
  options: IEachDropdownElementsType[];
  placeholder: string;
  componentSpecificClasses: string[];
  selectedStatus: (number | string)[];
  setSelectedStatus: React.Dispatch<React.SetStateAction<(number | string)[]>>;
}> = ({
  label,
  isMandatory,
  options,
  placeholder,
  componentSpecificClasses,
  selectedStatus,
  setSelectedStatus,
}) => {
  const getTagParams = (value: number) => {
    switch (value) {
      case 1:
        return {
          bgColor: '#FFF598',
          color: '#645A00'
        };
      case 2:
        return {
          bgColor: '#F0F9FF',
          color: '#026aa2',
        };
      case 3:
        return {
          bgColor: '#D7EED6',
          color: '#2D912B',
        };
      case 4:
        return {
          bgColor: '#FBD5D5',
          color:'#CC2525'
        };
    }
    return {
      bgColor: '#FBD5D5',
      color:'#CC2525'
    };
  };

  const tagRender: TagRender = (props) => {
    const { label, value, closable, onClose } = props,
      option = options.find((el) => el.value === value),
      tagParams = getTagParams((option?.value as number) ?? 1);

    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={tagParams.bgColor}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginInlineEnd: 4}}
        className={option?.className}
      >
        {label}
      </Tag>
    );
  };

  return (
    <Fragment>
      <CommonElementLabel label={label} isMandatory={isMandatory} />
      <Multi
        options={options}
        placeholder={placeholder}
        componentSpecificClasses={componentSpecificClasses}
        onChange={setSelectedStatus}
        props={{ tagRender }}
        value={selectedStatus || []}
      />
    </Fragment>
  );
};

export default StatusDropdownMulti;
