import React from 'react';
import { LeftPanel } from '../LeftNavPanel';
import { PixelListingWrap } from './PixelListingComponent';

const PixelListingPage = () => {
  document.title = 'Conversion Pixels - BranchLab';

  return (
    <>
      <LeftPanel defaultSelectedKeys={['5']} />
      <PixelListingWrap />
    </>
  );
};

export default PixelListingPage;
