import { Button } from 'antd';
import React, { FC, useState } from 'react';
import { usePostApiInterceptor, useRedirection } from '../../../../hooks';
import { RoutesEnum } from '../../../../models/enums/apiRoutes';
import { useLoaderStore, useUserInformationStore } from '../../../../stores';
import {
  ILoaderState,
  IUserInformationState,
} from '../../../../models/interfaces';
import { useFeatureSets } from '../../../../stores/newFeatureSets.store';
import { useParams } from 'react-router-dom';

interface IResearchBody {
  research_id: number;
  title: string;
  report_ids: string;
  research_filter_set: Array<{
    filter_id: number;
    targeting_id: number;
    targeting_value: string;
    operator_id: number | null;
  }>;
}

const SaveComponent: FC<{ title: any }> = ({ title }) => {
  const [body, setBody] = useState<IResearchBody| null>(null),
    [isMounted, setIsMounted] = useState<boolean>(false);
  const { setShowBlankLoaderState } = useLoaderStore(
    (state: ILoaderState) => state
  );
  const {
    featureSetMasterArray,
    setFeatureSetMasterArray,
    selectedReports,
    setSelectedReports,
  } = useFeatureSets((state: any) => state);
  const { userInformation } = useUserInformationStore(
    (state: IUserInformationState) => state
  );

  const { redirectResearch } = useRedirection();
  const { id } = useParams();

  usePostApiInterceptor(
    isMounted,
    body,
    RoutesEnum.SAVE_RESEARCH,
    (data: any, error: any) => {
      setBody(null);
      setIsMounted(false);
      if (data && !error) {
        setShowBlankLoaderState(false);
        redirectResearch();
        setFeatureSetMasterArray([]);
        setSelectedReports([]);
      }
    }
  );
  const initiatedSave = () => {
    let tempMasterArray: any = [];
    featureSetMasterArray.map((e: any, index: number) => {
      if (e.value === '') {
        featureSetMasterArray[index].isError = true;
        setFeatureSetMasterArray(featureSetMasterArray);
      } else {
        tempMasterArray.push({
          filter_id: e.filter_id,
          targeting_id: e.setId,
          targeting_value: e.value,
          operator_id:
            featureSetMasterArray.length - 1 === index ? null : e.operatorValue,
        });
      }
    });
    const savePayload= {
      research_id: id ? parseInt(id) : 0,
      title: title,
      report_ids: selectedReports.join(','),
      research_filter_set: tempMasterArray,
    };

    if (tempMasterArray.length === featureSetMasterArray.length) {
      setShowBlankLoaderState(true);
      setBody(savePayload);
      setIsMounted(true);
    }
  };
  return (
    <div className="newFeatureSetBtn">
      <Button
        size="large"
        onClick={() => redirectResearch()}
        className="noBGBtn"
      >
        Cancel
      </Button>
      <Button
        size="large"
        className="primaryBtn"
        onClick={initiatedSave}
        disabled={
          !title ||
          selectedReports?.length === 0 ||
          selectedReports === undefined ||
          featureSetMasterArray.length === 0 ||
          featureSetMasterArray === undefined
        }
      >
        Save
      </Button>
    </div>
  );
};

export default SaveComponent;
